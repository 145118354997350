import { useField } from 'react-final-form';
import { DateView, MobileDatePicker, MobileDatePickerProps, MobileDatePickerSlotsComponentsProps } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

type BirthDateFieldProps = MobileDatePickerProps<Dayjs> & {
  name: string;
  required?: boolean;
};

const getYearNYearsAgo = (n: number): Dayjs => {
  const currentDate = new Date();
  return dayjs(new Date(Date.UTC(
    currentDate.getFullYear() - n,
    currentDate.getMonth(),
    currentDate.getDate(),
    0, 0, 0, 0
  )));
};
const minDate = getYearNYearsAgo(100);
const maxDate = getYearNYearsAgo(18);

const format = (input?: Date | null): Dayjs | null => {
  if (!input) {
    return null;
  }
  return dayjs(input)
};
const parse = (input: Dayjs | null): Date | undefined => {
  if (!input) {
    return;
  } 
  return input.toDate();
};

const style = {
  '& label + .MuiInputBase-formControl .MuiInputBase-input::-webkit-input-placeholder': {
    opacity: '0.5 !important',
  },
};
const views: DateView[] = ['year', 'month', 'day'];

function BirthDateField({
  name,
  label,
  required,
  ...props
}: BirthDateFieldProps): JSX.Element {
  const { t } = useTranslation();
  const { input } = useField<Date | null | undefined, HTMLElement, Dayjs | null>(name, {
    format,
    parse,
    allowNull: true
  });

  const slotProps: MobileDatePickerSlotsComponentsProps<Dayjs> = {
    textField: { variant: "filled" },
    toolbar: {
      toolbarFormat: t('birthdate_format', 'YYYY-MM-DD'),
    },
  };

  const handleChange = useCallback((newDate: Dayjs | null) => {
    if (newDate) {
      // Normalize the date to UTC
      const normalizedDate = newDate.utc(true).startOf('day');
      // Use normalizedDate for your state, form, etc.
      input.onChange(normalizedDate);
    }
  }, []);
  
  return (
    <MobileDatePicker
      reduceAnimations
      label={label + (required ? ' *' : '')}
      minDate={minDate}
      maxDate={maxDate}
      views={views}
      openTo="year"
      slotProps={slotProps}
      format={t('birthdate_format', 'YYYY-MM-DD')}
      localeText={{
        toolbarTitle: t('pick_date', 'Välj datum'),
        cancelButtonLabel: t('cancel', 'Avbryt'),
        okButtonLabel: t('save', 'Spara'),
      }}
      sx={style}
      {...input}
      onChange={handleChange}
      {...props}
    />
  )
}

export default BirthDateField;
