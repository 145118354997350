import { InputAdornment } from '@mui/material';
import { UseFieldConfig, useField } from 'react-final-form';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { MuiTelInput, MuiTelInputProps } from 'mui-tel-input';

type PhoneFieldProps = MuiTelInputProps & {
  name: String;
  config?: UseFieldConfig<string, string>;
};

function PhoneField({
  name,
  config,
  ...props
}: PhoneFieldProps): JSX.Element {
  const { input, meta } = useField<string>(name, config);
  return (
    <MuiTelInput
      {...input}
      margin="normal"
      fullWidth
      variant="filled"
      autoComplete="phone"
      defaultCountry="SE"
      continents={['EU']}
      disableFormatting
      InputLabelProps={{ shrink: true }}
      helperText={meta.touched && typeof meta.error === 'string' && meta.error}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {meta.valid && (<CheckCircleIcon color="success" />)}
            {meta.touched && meta.error && <ErrorIcon color="warning" />}
          </InputAdornment>
        )
      }}
      {...props}
    />
  );
}

export default PhoneField;
