import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Block from '../../components/Block';
import Section from '../../components/Section';
import { fadeIn } from "./helpers";
import MarkdownText from '../../components/MarkdownText';

const NetworkProcess = ({ user, duration = 3 }) => {
  const { t } = useTranslation();
  const process = [{
    icon: '/one.svg',
    title: t('network_process_1.title', 'Vi kontaktar dig'),
    description: t('network_process_1.description', 'Matchmakers kontaktar dig för en djupare video-intervju om du matchar en klient.'),
  }, {
    icon: '/two.svg',
    title: t('network_process_2.title', 'Ni passar för en dejt'),
    description: t('network_process_2.description', 'Om våra matchmakers tror ni passar varandra riktigt bra, så introduceras du och vi sätter ihop en dejt.'),
  }, {
    icon: '/three.svg',
    title: t('network_process_3.title', 'Feedback efter dejten'),
    description: t('network_process_3.description', 'Vi samlar feedback från eran dejt för att förstå hur det gick för er, och vad som kunde gått bättre.'),
  }];
  
  return (
    <Section
      small
      sx={{
        textAlign: 'center',
        em: { color: 'white', fontWeight: 'bold' },
        background: 'none !important',
        opacity: 0,
        animation: `${fadeIn} 500ms forwards linear`,
        animationDelay: `${duration + 1}s`,
      }}
    >
      <Typography component="h2" variant="h2">
        {t('network_process.title', 'Vad händer om jag är en match?')}
      </Typography>
      <Typography variant="body2" textAlign="center" sx={{ mb: 4 }}>
        {t('network_process.description', 'Du är med i nätverket nu och vi har det som krävs för att matcha dig. Om du matchar med en av våra klienter så kommer vi att kontakta dig för en introduktion.')}
      </Typography>
      <Block
        sx={{
          p: 4,
          m: 0,
          backgroundColor:  (t) =>
            (t.palette.mode === 'light' ? t.palette.background.paper : t.palette.secondary.main) + '88',
        }}
      >
        <MarkdownText>
          {(user?.clients ?? -1) > 0 ?
            t('network_process.clients', { count: user.clients })
            : t('network_process.info', 'Du behöver inte göra något mer nu, du kan luta dig tillbaka så hör vi av oss när du blir aktuell för en klient.')}
        </MarkdownText>
      </Block>
      <Box sx={{ m: '0px auto', maxWidth: 480, mb: 8, }}>
        {process.map((item) => (
          <Box key={item.icon} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 6 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Box
                component="img"
                src={item.icon}
                sx={{
                  width: 48, height: 48, minWidth: 48, mb: 2,
                  borderRadius: 26,
                  backgroundAttachment: 'fixed',
                  backgroundImage:  'repeating-radial-gradient(circle at 0 0, #230e11 0, transparent 100px), repeating-linear-gradient(#4a362b88, #4a362b44)',
                }}
              />
              <Typography component="h3" variant="h3" sx={{ mb: 2, ml: 2 }}>{item.title}</Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant="body2" textAlign="center">{item.description}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Section>
  );
}

export default NetworkProcess;
