import Box from '@mui/material/Box';
import { keyframes } from '@mui/material';
import Image from '../../components/Image';

const colors = {
  needs: '#FDC451',
  values: '#A86EE1',
  beliefs: '#FFA07A',
  life_stage: '#77B651',
  personality: '#259FCF',
  interests: '#FFA700',
  attraction: '#EE5951',
  sex: '#CC1133',// EE5951
};

const dimensions = [
  'needs',
  'values',
  'beliefs',
  'life_stage',
  'personality',
  'interests',
  'attraction',
  'sex',
];

const WheelAnimation = ({ x = 90, y = 95, duration, user }) => {
  const items = [...dimensions].reverse();
  return (
    <Box sx={{ position: 'relative', m: '0px auto' }}>
      <Box sx={{
        width: { xs: '200px', sm: '240px' },
        height: '0',
        paddingBottom: { xs: '200px', sm: '240px' },
        borderRadius: { xs: '100px', sm: '120px' },
        position: 'relative',
        background: '#38191E',
        transformOrigin: { xs: '100px 100px', sm: '120px 120px' },
        animation: `${keyframes`
          0% {
            transform: rotateZ(-360000deg);
          }
          ${x + (y - x) / 2}% {
            transform: rotateZ(0deg);
          }
        `} ${duration + 1}s cubic-bezier(0.595, 0.945, 0.650, 0.985) forwards`,
      }}>
        <Box sx={{
          top: 4,
          left: 4,
          width: { xs: '192px', sm: '232px' },
          height: { xs: '192px', sm: '232px' },
          borderRadius: { xs: '96px', sm: '116px' },
          position: 'absolute',
          background: '#220e1044',
          backdropFilter: 'blur(40px)',
          zIndex: 12,
        }} />
        <Box sx={{
          top: 4,
          left: 4,
          width: { xs: '192px', sm: '232px' },
          height: { xs: '192px', sm: '232px' },
          borderRadius: { xs: '96px', sm: '116px' },
          position: 'absolute',
          background: '#220e1044',
          backdropFilter: 'blur(24px)',
          zIndex: 12,
        }} />
        {items.map((item, i) => {
          const len = items.length;
          const z = 1 / len;
          const p1 = i / len;
          const p2 = (i + 1) / len;
          const start = items.slice(0, i).reduce((a, l) => a + z, 0);
          const angle = start * 360;
          const score = z * 100;
          const color = colors[item];
          const a = `--g${i}a`;
          const r = `--g${i}b`;
          const series = Array.from(new Array(len), (_, j) => {
            return Array.from(new Array(len), (_, k) => {
              return k === j ? 80 : (20 / (len - 1));
            });
          });
          const animationString = series.map((_, j) => `
          ${j * (x / series.length)}% {
            ${a}: ${(1 / len) * 100};
            ${r}: ${(i / len) * 360};
          }
          ${j * (x / series.length) + 1}% {
            ${a}: ${series[j][i]};
            ${r}: ${series[j].slice(0, i).reduce((a, x) => a + x, 0) * 3.6};
          }
          ${(j + 1) * (x / series.length) - 1}% {
            ${a}: ${series[j][i]};
            ${r}: ${series[j].slice(0, i).reduce((a, x) => a + x, 0) * 3.6};
          }`).join('') + `
          ${x}% {
            ${a}: ${(1 / len) * 100};
            ${r}: ${(i / len) * 360};
          }`;
          const frames = keyframes`
            ${animationString}
            ${x + 1}% {
              ${a}: 0;
              ${r}: 0;
            }
            ${y}% {
              ${a}: 0;
              ${r}: 0;
            }
            ${y + p1 * (100 - y)}% {
              ${a}: 0;
              ${r}: 0;
            }
            ${y + (p1 + (p2 - p1)/8) * (100 - y)}% {
              ${a}: ${score};
              ${r}: ${angle};
            }
          `;

          return (
            <Box key={item} sx={{
              [a]: score,
              [r]: angle,
              width: { xs: '200px', sm: '240px' },
              height: { xs: '200px', sm: '240px' },
              borderRadius: { xs: '100px', sm: '120px' },
              position: 'absolute',
              transformOrigin: { xs: '100px 100px', sm: '120px 120px' },
              background: `conic-gradient(${color} calc(var(${a}) * 1%), #0000 0)`,
              mask: `radial-gradient(farthest-side, #0000 calc(99% - ${r}px), #000 calc(100% - ${r}px))`,
              transform: `rotateZ(calc(var(${r}) * 1deg))`,
              zIndex: len - i + 1,
              animation: `${frames} ${duration}s cubic-bezier(0.595, 0.945, 0.650, 0.985) forwards`,
              animationDelay: '1s',
            }} />
          );
        })}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: 4,
          left: 4,
          width: { xs: '192px', sm: '232px' },
          height: { xs: '192px', sm: '232px' },
          borderRadius: { xs: '96px', sm: '116px' },
          background: '#000',
          zIndex: 10,
          flex: 0,
          flexBasis: 184,
          opacity: 0,
          animation: `${keyframes`
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          `} 500ms linear forwards`,
          animationDelay: `${((x + (y - x) / 2) / 100) * duration + 0}s`,
        }}
      />
      <Box
        component={Image}
        src={user?.picture?.square}
        sx={{
          position: 'absolute',
          top: 8,
          left: 8,
          width: { xs: '184px', sm: '224px' },
          height: { xs: '184px', sm: '224px' },
          borderRadius: { xs: '92px', sm: '112px' },
          zIndex: 12,
          flex: 0,
          flexBasis: 184,
          opacity: 0,
          animation: `${keyframes`
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          `} 500ms linear forwards`,
          animationDelay: `${((x + (y - x) / 2) / 100) * duration + 0}s`,
        }}
      />
    </Box>
  );
};

export default WheelAnimation;
