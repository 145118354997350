import gql from 'graphql-tag';

const StartPictureUploadMutation = gql`
  mutation StartPictureUploadMutation(
    $type: String!
    $index: Int
    $width: Int
    $height: Int
    $focalpoint: InputPictureFocalpoint
    $userPictureThemeId: ID
  ) {
    userPicture {
      startUpload(
        type: $type
        index: $index
        width: $width
        height: $height
        focalpoint: $focalpoint
        userPictureThemeId: $userPictureThemeId
      ) {
        _id
        url
      }
    }
  }
`;

export default StartPictureUploadMutation;
