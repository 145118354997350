import MarkdownText from '../../components/MarkdownText';
import Section from '../../components/Section';
import { fadeIn } from './helpers';

const SectionMessage = ({ duration = 3, description }) => {
  return (
    <Section
      small
      sx={{
        border: 'solid 3px #38191E',
        textAlign: 'center',
        em: { color: 'white', fontWeight: 'bold' },
        opacity: 0,
        animation: `${fadeIn} 500ms forwards linear`,
        animationDelay: `${duration + 1}s`,
        mt: 4,
        mx: 2,
        borderRadius: 4,
        px: { xs: 2, sm: 2 },
        py: { xs: 3, sm: '40px !important' },
        background: '#2d1115',
        span: {
          background: '#9C4452',
          py: 0.25,
          px: 1,
          borderRadius: 8,
          whiteSpace: 'nowrap',
          fontSize: { xs: 15, sm: 18 },
          lineHeight: 2,
        }
      }}
    >
      <MarkdownText>
        {description}
      </MarkdownText>
    </Section>
  );
}

export default SectionMessage;
