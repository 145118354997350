import { Box, BoxProps } from "@mui/material";

const stepIndicator = {
  px: 2,
  display: 'flex',
  flexDirection: 'row',
  gap: 1,
  position: 'sticky',
  top: 'calc(16px + 2.15rem * 1.235)',
  width: '100%',
  zIndex: 100,
};

const StepIndicator = ({ sx, ...props }: BoxProps) => (
  <Box sx={stepIndicator} {...props} />
);

export default StepIndicator;
