import { gql } from "@apollo/client";

const UserPreferenceFieldsQuery = gql`
  query UserPreferenceFieldsQuery {
    userPreferenceFields {
      sex
      sex_orientation
      relationship
      first_date
      income
      education_level
      pets
      alcohol
      tobacco
      dealbreakers
      future_life
      have_kids
      lives_with_kids
      want_kids
      marriage
      childhood
      relation_need
      relation_feeling
      personality
      energy
      sex_frequency
      sex_language
    }
  }
`;

export default UserPreferenceFieldsQuery;
