import { useTranslation } from 'react-i18next';
import Body from '../components/Body';
import { Helmet } from 'react-helmet-async';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import MarkdownText from '../components/MarkdownText';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Block from '../components/Block';
import { useCallback } from 'react';
import Image from '../components/Image';
import { useAuth } from '../data/auth/token';
import CameraField from '../components/fields/CameraField';
import { Form } from 'react-final-form';
import startVideoUpload from '../helpers/startVideoUpload';
import { FormApi } from 'final-form';

export default function Verification() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const matchId = searchParams.get('match');
  const auth = useAuth();
  const pageTitle = t('verification.page_title', 'Verifiera ditt konto');
  const title = t('verification.title', 'Verifiera ditt konto');
  const description = t('verification.description', 'Säkerhet för alla som är en del av matchmaking-nätverket är viktigt och därför behöver du som ett sista steg att verifierar dig i en kort video. ');

  // const handleExample = useCallback(() => {

  // }, [matchId]);

  const onSubmit = useCallback((values: any, form: FormApi) => new Promise(async (resolve) => {
    const { video } = values;
    const uploadId = await startVideoUpload(video.file, (_, res) => {
      if (res) {
        // navigate to home screen
        if (matchId) {
          navigate('/?match=' + matchId);
        } else {
          navigate('/');
        }
        resolve(null);
      } else {
        // Revert to old value in cache if upload failed.
        resolve({ video: 'Failed uploading' });
      }
    });
    form.change('video', { _id: uploadId, ...video });
  }), [matchId]);

  const handleSkip = useCallback(() => {
    if (matchId) {
      navigate('/?match=' + matchId);
    } else {
      navigate('/');
    }
  }, [auth]);

  const validate = useCallback((values: { video?: { file?: File, url: string } }) => {
    const errors: { [key: string]: string } = {};
    if (!values?.video?.file) {
      errors.video = t('required_video', 'Video is required');
    }
    return errors;
  }, []);

  return (
    <Body>
      <Helmet>
        <title>Relate | {pageTitle}</title>
      </Helmet>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({
          submitting,
          hasValidationErrors,
          hasSubmitErrors,
          submitSucceeded,
          dirty,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ p: 2, pt: 4, textAlign: 'center', em: { color: 'white', fontWeight: 'bold' } }}>
              <Typography component="h1" variant="h1" sx={{ mb: 2 }}>{title}</Typography>
              <MarkdownText>{description}</MarkdownText>
            </Box>
            <Block>
              <Typography component="label" variant="h6" sx={{ fontWeight: 'bold' }}>
                {t('verification_block.title', 'Berätta lite om hur du helst spenderar din semester ☀️')}
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                {t('verification_block.description', 'Spela in en kort video av dig själv. Videon är endast i verifieringssyfte och kommer inte att delas med några potentiella matcher.')}
              </Typography>
              <Box>
                <CameraField
                  name="video"
                  disabled={submitting}
                />
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  type="submit"
                  sx={{ mb: 1, height: 'calc(14px * 1.75 + 16px)' }}
                  disabled={submitting || hasValidationErrors || ((hasSubmitErrors || submitSucceeded) && !dirty)}
                >
                  {submitting ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : t('verification.action', 'Verifiera ditt konto')}
                </Button>
                {/* {!submitting && (
                  <Button
                    onClick={handleExample}
                    fullWidth
                    size="large"
                    variant="text"
                  >
                    {t('verification.example', 'Se ett exempel')}
                  </Button>
                )} */}
              </Box>
            </Block>
            {!submitting && (
              <Button
                onClick={handleSkip}
                fullWidth
                size="large"
                variant="text"
                sx={{ mt: 1 }}
              >
                {t('complete.skip', 'Verifiera senare')}
              </Button>
            )}
          </form>
        )}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', px: 2, py: 4, mb: 2, mt: 4, flexDirection: 'column', borderTop: 'solid 1px #38191E' }}>
        <Image src="/logo.png" sx={{ width: 84 }} />
        <Typography variant="caption">
          {t('slogan', 'Gör slut med apparna för gott')}
        </Typography>
      </Box>
    </Body>
  );
}
