import Box from '@mui/material/Box';
import { SxProps } from '@mui/material';

function Block({ sx, rounded, ...props }: { sx: SxProps } & any) {
  return (
    <Box
      className="block"
      sx={{
        mx: 2,
        my: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.background.paper : t.palette.secondary.main,
        p: 2,
        borderRadius: 4,

        '& + .block': {
          mt: 2,
        },
        ...sx,
      }}
      {...props}
    />
  );
}

export default Block;
