import { useCallback, useState, useMemo, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupedVirtuoso, VirtuosoHandle } from 'react-virtuoso'
import { Helmet } from 'react-helmet-async';
import { flatten } from 'flat';
import { Form, FormSpy } from 'react-final-form';
import { InputAdornment } from '@mui/material';
import Body from '../components/Body';
import OptionsField from '../components/fields/OptionsField';
import TextField from '../components/fields/TextField';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useLocalStorage from '../helpers/useLocalStorage';
import client from '../data/client';
import UpdateUserApplicationMutation from '../data/mutations/UpdateUserApplicationMutation';
import GroupHeading from '../components/GroupHeading';
import GroupItem from '../components/GroupItem';
import FormHeader from '../components/FormHeader';
import SubmitButton from '../components/SubmitButton';
import SliderField from '../components/fields/SliderField';
import BirthDateField from '../components/fields/BirthDateField';
import { LocationSearchBox } from '../components/LocationSearchBox';
import PictureField from '../components/fields/PictureField';
import { useAuth } from '../data/auth/token';
import validateEmail from '../helpers/validateEmail';
import StepIndicator from '../components/StepIndicator';
import ProgressIndicator from '../components/ProgressIndicator';
import { track } from '../helpers/mixpanel';
import { createForm } from 'final-form';

type Location = {
  latitude: number;
  longitude: number;
};
type LocationOption = {
  id: string;
  name: string,
  latitude?: number,
  longitude?: number,
  onClick?: () => Promise<Location | null>
};
type OptionType = { [key: string]: string[] | any[] } & {
  locations: LocationOption[];
};
export type GroupItemOptionType = {
  label: string,
  value: string | LocationOption,
}
export type GroupItemType = {
  name?: string,
  component: (_:any) => JSX.Element,
  multiple?: boolean,
  label?: string,
  description?: string,
  placeholder?: string,
  options?: GroupItemOptionType[]
}
export type GroupType = {
  title: string,
  items: GroupItemType[],
}

const compareLocationOption = (a: LocationOption, b: LocationOption) => a?.id === b?.id;

class LocationService {
  private static readonly CACHE_KEY = 'userLocationCache';

  public static getUserLocation(): Promise<Location> {
    const cachedData = localStorage.getItem(this.CACHE_KEY);
    const currentTime = Date.now();

    if (cachedData) {
      const { location, timestamp } = JSON.parse(cachedData);
      if (currentTime - timestamp < 300000) {
        return Promise.resolve(location);
      }
    }

    return new Promise((resolve, reject) => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const location = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            };
            localStorage.setItem(this.CACHE_KEY, JSON.stringify({
              location,
              timestamp: currentTime
            }));
            resolve(location);
          },
          error => {
            reject(new Error(`Error retrieving location: ${error.message}`));
          }
        );
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  }
}

const useLocation = (): [Location | undefined, (location: Location) => void] => {
  const [location, setLocation] = useState<Location | undefined>();
  useEffect(() => {
    // Only request the location if the user hasn't denied permission before
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
        if (permissionStatus.state === 'granted') {
          LocationService.getUserLocation().then(loc => {
            setLocation(loc)
          }).catch(() => null);
        }
      });
    }
  }, []);

  return [location, setLocation];
};

const stripTypename = ({ __typename, ...fields }: any = {}) => fields;
const onlyInputLocationFields = ({ id, name, latitude, longitude }: any = {}) => ({ id, name, latitude, longitude });

export default function Application() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const virtuoso = useRef<VirtuosoHandle>(null);
  const [atBottom, setAtBottom] = useState(false);
  const [location, setLocation] = useLocation();
  const { user } = useAuth();
  let title = t('application.title', 'Din ansökan');
  const prefill = searchParams.get('prefill') === 'true';
  const clear = searchParams.get('prefill') === 'false';
  const service = searchParams.get('service');
  const services = new Set([...service ? [service] : [], ...user?.service || []].filter(Boolean));
  const defaultValues = prefill ? {
    prefill: true,
    referrer: service,
    ...(user?.service || service) && { service: Array.from(services.values()) },
    ...user?.firstName && { firstName: user.firstName },
    ...user?.lastName && { lastName: user.lastName },
    ...user?.email && { email: user.email },
    ...user?.birthdate && { birthdate: user.birthdate },
    ...user?.height && { height: user.height },
    ...user?.sex && { sex: user.sex },
    ...user?.profile && { photos: user.profile },
    ...user?.locations && { locations: user.locations.map(stripTypename) },
    ...user?.settings?.preferences?.locations && { settings: { preferences: {
      locations: user.settings.preferences.locations.map(stripTypename),
    } } },
    ...user?.sexuality && { sexuality: user.sexuality },
    ...user?.linkedin && { linkedin: user.linkedin },
    ...user?.instagram && { instagram: user.instagram },
    ...user?.about && { about: user.about },
    ...user?.future_partner && { future_partner: user.future_partner },
  } : {
    ...service && {
      referrer: service,
      service: [service],
    },
  };

  useEffect(() => {
    if (prefill || clear || service) {
      if (!window.location.search) return;
      searchParams.delete('prefill');
      searchParams.delete('service');
      history.replaceState(null, '', `${window.location.origin}${window.location.pathname}`);
    }
  }, []);

  const localStorageKey = 'application';
  const [initialValues, setInitialValues] = useLocalStorage(localStorageKey, defaultValues, prefill);

  if (initialValues.prefill) {
    title = t('application.edit_title', 'Din profil');
  }

  const options: OptionType = {
    service: ['matchmaker', 'coaching', 'member'],
    locations: [{
      id: 'current',
      name: t('current_location'),
      ...location,
      onClick: async () => {
        try {
          const [coordinates] = await Promise.all([
            LocationService.getUserLocation(),
            new Promise(res => setTimeout(res, 500)), // make it take at least 500ms
          ]);
          setLocation(coordinates);
          return {
            name: t('current_location'),
            ...coordinates,
          };
        } catch (err) {
          console.log(err);
          return null;
        }
      },
    }, {
      id: 'sthlm',
      name: t('sthlm', 'Stockholm'),
      latitude: 59.3261158,
      longitude: 17.8994821,
    }, {
      id: 'gbg',
      name: t('gbg', 'Göteborg'),
      latitude: 57.7008846,
      longitude: 11.7286931,
    }, {
      id: 'malmo',
      name: t('malmo', 'Malmö'),
      latitude: 55.5701409,
      longitude: 12.9335735,
    }],
    sex: ['female', 'male', 'other'],
    sexuality: ['straight', 'bisexual', 'gay', 'lesbian', 'queer', 'asexual'],
  };

  const onSubmit = useCallback(async ({ prefill, ...fields }: { [key: string]: any }) => {
    track('submit_application');
    try {
      const res = await client.mutate({
        mutation: UpdateUserApplicationMutation,
        variables: {
          fields: {
            ...fields,
            ...fields?.photos && {
              photos: (fields.photos || []).map(({ _id }: { _id: string }) => _id),
            },
            ...fields?.locations?.length && {
              locations: fields.locations.map(onlyInputLocationFields),
            },
            ...fields?.settings?.preferences?.locations?.length && {
              settings: {
                preferences: {
                  locations: fields.settings.preferences.locations.map(onlyInputLocationFields),
                }
              }
            },
          },
        },
      });

      if (res?.data?.updateUserApplication?.user) {
        // Clear saved form data.
        localStorage.removeItem(localStorageKey);

        navigate('/profile');
        track('submit_application_success');
      } else {
        track('submit_application_failed');
      }
    } catch (err) {
      track('submit_application_error');
    }
  }, []);

  const validate = useCallback((values: { [key: string]: any }) => {
    const errors: { [key: string]: string } = {};
    Object.keys(values).forEach(field => {
      const value = values[field];
      switch (field) {
        case 'firstName':
        case 'lastName': {
          // Minimum length validation
          if (value.length < 2) {
            errors[field] = t('invalid_name', 'Ogiltigt namn');
          }
          break;
        }
        case 'email': {
          // Minimum length validation
          if (value.length < 2 || !validateEmail(value)) {
            errors[field] = t('invalid_email', 'Ogiltig epost');
          }
          break;
        }
        default:
          break;
      }
    });
    if (values?.photos?.length < 1) {
      errors.photos = t('photos.required', 'Välj minst 1 bild');
    }
    if (!values?.firstName?.length) {
      errors.firstName = t('firstName.required', 'Förnamn krävs');
    }
    if (!values?.lastName?.length) {
      errors.lastName = t('lastName.required', 'Efternamn krävs');
    }
    if (!values?.email?.length) {
      errors.email = t('email.required', 'Epost krävs');
    }
    if (!values?.birthdate) {
      errors.birthdate = t('birthdate.required', 'Ålder krävs');
    }
    return errors;
  }, []);

  const form = useMemo(() => createForm({
    onSubmit,
    validate,
  }), []);

  useEffect(() => {
    if (prefill || service) {
      const nextInitialValues = { ...defaultValues, ...initialValues };
      nextInitialValues.referrer = service;
      nextInitialValues.service = Array.from(new Set([...defaultValues?.service || [], ...initialValues?.service || [], service].filter(Boolean)));
      setInitialValues(nextInitialValues);
      // Set initial values so that dirty is true.
      const values = flatten(nextInitialValues, { safe: true }) as { [key: string]: any };
      form.batch(() => {
        Object.keys(values).forEach(name => {
          form.change(name, values[name]);
        });
      });
    }
    if (clear) {
      const nextInitialValues = defaultValues;
      setInitialValues(nextInitialValues);
      // Set initial values so that dirty is true.
      const values = flatten(nextInitialValues, { safe: true }) as { [key: string]: any };
      form.batch(() => {
        Object.keys(values).forEach(name => {
          form.change(name, values[name]);
        });
      });
    }
  }, [prefill, service]);

  const fieldGroups = useMemo<GroupType[]>(() => [{
    title,
    items: [
      {
        component: FormHeader,
        description: t('application.description', 'Förenkla ditt sökande efter kärleken med Relate: våra matchmakers matchar dig bara med rätt personer, så du kan säga farväl till meningslöst svepande och missade matchningar.'),
        videoSrc: {
          mp4: t('application.video_mp4_src', '/application_sv.mp4'),
          webm: t('application.video_webm_src', '/application_sv.webm'),
          caption: t('application.video_caption_src', '/application_sv.vtt'),
        },
      },
      {
        component: OptionsField, 
        multiple: true,
        name: 'service',
        label: t(`service.label`, 'Vad är du intresserad av?'),
        options: (options.service || []).map(key => ({
          value: key,
          label: t(`service.${key}`),
        })),
      },
      {
        component: TextField,
        name: 'firstName',
        required: true,
        label: t(`first_name.label`, 'Förnamn'),
        placeholder: t(`first_name.placeholder`, 'Ditt förnamn'),
        sx: { mt: 0 },
        autoComplete: 'off',
        autoCorrect: 'off',
        spellCheck: 'false',
      },
      {
        component: TextField,
        name: 'lastName',
        required: true,
        label: t(`last_name.label`, 'Efternamn'),
        placeholder: t(`last_name.placeholder`, 'Ditt efternamn'),
        sx: { mt: 0 },
        autoComplete: 'off',
        autoCorrect: 'off',
        spellCheck: 'false',
      },
      {
        component: TextField,
        name: 'email',
        required: true,
        label: t(`email.label`, 'Email'),
        placeholder: t(`email.placeholder`, 'Din email'),
        sx: { mt: 0 },
        type: 'email',
        autoComplete: 'off',
        autoCorrect: 'off',
        autoCapitalize: 'none',
        spellCheck: 'false',
      },
      {
        component: BirthDateField,
        name: 'birthdate',
        required: true,
        label: t(`age.label`, 'Ålder'),
      },
      {
        component: OptionsField,
        multiple: true,
        other: true,
        name: 'locations',
        hiddenField: 'city', // capture autofill location
        label: t(`location.label`, 'Var bor du?'),
        description: t(`location.description`, 'Välj den plats där du bor till största delen av din tid.'),
        compare: compareLocationOption,
        SearchBox: LocationSearchBox,
        options: (options.locations || []).map(location => ({
          value: location,
          label: location.name,
        })),
      },
      {
        component: OptionsField,
        multiple: true,
        other: true,
        name: 'settings.preferences.locations',
        label: t(`preferred_location.label`, 'Var kan du tänka dig träffa en match?'),
        description: t(`preferred_location.description`, 'Du kan välja flera platser där du kan tänka dig bli matchad.'),
        compare: compareLocationOption,
        SearchBox: LocationSearchBox,
        options: ([{
          id: 'everyone',
          name: t('location.everyone', 'Var som helst'),
        }, ...options.locations]).map(location => ({
          value: location,
          label: location.name,
        })),
      },
      {
        component: SliderField,
        name: 'height',
        label: t(`height.label`, 'Din längd'),
        min: 1.30,
        max: 2.20,
        step: 0.01,
        valueLabelFormat: (value: number) => `${Math.round(value * 100)}cm`,
      },
      {
        component: OptionsField, 
        other: true,
        name: 'sex',
        label: t(`gender.label`, 'Din könsidentitet'),
        options: (options.sex || []).map(key => ({
          value: key,
          label: t(`gender.${key}`),
        })),
      },
      {
        component: OptionsField, 
        multiple: true,
        other: true,
        name: 'sexuality',
        label: t(`sexuality.label`, 'Din sexualitet'),
        description: t(`sexuality.description`, 'Du kan välja flera alternativ.'),
        options: (options.sexuality || []).map(key => ({
          value: key,
          label: t(`sexuality.${key}`),
        })),
      },
      {
        component: TextField,
        name: 'linkedin',
        label: t(`linkedin.label`, 'LinkedIn'),
        placeholder: t(`linkedin.placeholder`, 'Länk till din profil på LinkedIn'),
        sx: { mt: 0 },
        type: 'url',
        autoComplete: 'off',
        autoCorrect: 'off',
        autoCapitalize: 'none',
        spellCheck: 'false',
        config: {
          format: (a: string) => decodeURIComponent(a || '')
            .replace(/^(?:https?:\/\/)?(?:www\.|[a-z]{2}\.)?linkedin\.com\/in\/([^/?]+)(?:.*)?$/i, '$1'),
          parse: (a: string) => decodeURIComponent(a || '')
            .replace(/^(?:https?:\/\/)?(?:www\.|[a-z]{2}\.)?linkedin\.com\/in\/([^/?]+)(?:.*)?$/i, '$1'),
        },
        InputProps: { startAdornment: <InputAdornment position="start">@</InputAdornment> },
      },
      {
        component: TextField,
        name: 'instagram',
        label: t(`instagram.label`, 'Instagram'),
        placeholder: t(`instagram.placeholder`, 'Länk till din profil Instagram'),
        sx: { mt: 0 },
        type: 'url',
        autoComplete: 'off',
        autoCorrect: 'off',
        autoCapitalize: 'none',
        spellCheck: 'false',
        config: {
          format: (a: string) => decodeURIComponent(a || '')
            .replace(/^(?:https?:\/\/)?(?:www\.)?instagram\.com\/([^/?]+)\/?(?:\?.*)?$/i, '$1'),
          parse: (a: string) => decodeURIComponent(a || '')
            .replace(/^(?:https?:\/\/)?(?:www\.)?instagram\.com\/([^/?]+)\/?(?:\?.*)?$/i, '$1'),
        },
        InputProps: { startAdornment: <InputAdornment position="start">@</InputAdornment> },
      },
      {
        component: PictureField,
        name: 'photos',
        description: t(`photos.description`, 'Detta syns som allt annat i din profil endast för våra matchmakers'),
        label: t(`photos.label`, 'Bilder på dig'),
      },
      {
        component: TextField,
        multiline: true,
        name: 'about',
        label: t(`about.label`, 'Om dig'),
        placeholder: t(`about.placeholder`, 'Hur skulle din bästa vän beskriva dig?'),
        sx: { mt: 0 },
      },
      {
        component: TextField,
        multiline: true,
        name: 'future_partner',
        label: t(`future_partner.label`, 'Vad är det första som kommer upp när du tänker på din framtida partner?'),
        placeholder: t(`future_partner.placeholder`, 'Beskriv din framtida partner med 2-3 meningar'),
        sx: { mt: 0 },
      }
    ]
  }], [t, options]);

  const groupCounts = useMemo(() => { 
    return fieldGroups.map(fieldGroup => fieldGroup.items.length);
  }, [fieldGroups]);
  const fields = useMemo(() => {
    return fieldGroups.flatMap(fieldGroup => fieldGroup.items);
  }, [fieldGroups]);

  const renderGroupContent = useCallback((index: number) => (
    <GroupHeading title={fieldGroups[index].title} />
  ), [fieldGroups]);
  const renderItemContent = useCallback((index: number) => {
    const { component: Component, ...props } = fields?.[index];
    return (
      <GroupItem name={props.name}>
        <Component {...props} />
      </GroupItem>
    );
  }, [fields]);
  const atBottomStateChange = useCallback((state: boolean) => {
    setAtBottom(state);
  }, [setAtBottom]);

  return (
    <Body>
      <Helmet>
        <title>Relate | {title}</title>
      </Helmet>
      <Form form={form} subscription={{}}>
        {({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <StepIndicator>
              <ProgressIndicator fields={fields} />
            </StepIndicator>
            <GroupedVirtuoso
              ref={virtuoso}
              className="virtuoso"
              groupCounts={groupCounts}
              groupContent={renderGroupContent}
              itemContent={renderItemContent}
              useWindowScroll
              atBottomStateChange={atBottomStateChange}
              increaseViewportBy={{
                top: 10000,
                bottom: 10000,
              }}
            />
            <FormSpy
              fields={fields}
              virtuoso={virtuoso}
              atBottom={atBottom}
              handleSubmit={handleSubmit}
              component={SubmitButton}
            />
            <FormSpy
              subscription={{ initialValues: true }}
              onChange={() => {
                if (!clear) {
                  // Set initial values so that dirty is true.
                  const values = flatten(initialValues, { safe: true }) as { [key: string]: any };
                  form.batch(() => {
                    Object.keys(values).forEach(name => {
                      form.change(name, values[name]);
                    });
                  });
                }
              }}
            />
            <FormSpy
              subscription={{ values: true }}
              onChange={({ values }) => {
                setInitialValues(values);
              }}
            />
          </form>
        )}
      </Form>
    </Body>
  );
}
