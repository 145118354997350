import { useTranslation } from 'react-i18next';
import Body from '../components/Body';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Typography } from '@mui/material';
import MarkdownText from '../components/MarkdownText';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import Video from '../components/Video';
import { useCallback, useEffect, useRef, useState } from 'react';
import Image from '../components/Image';
import LoginDialog from '../forms/LoginDialog';
import dayjs from 'dayjs';
import useLocalStorage from '../helpers/useLocalStorage';
import { useAuth } from '../data/auth/token';
import Section from '../components/Section';

const question = {
  borderBottom: 'solid 1px #fff2',
  my: 1,
};

const answer = {
  transition: 'all 300ms ease 0s',
  overflow: 'hidden',
  boxSizing: 'border-box',
};

const button = {
  px: 0,
  py: 2,
  fontStyle: 'normal',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  borderRadius: 0,
  textAlign: 'left',

  '.MuiButton-endIcon': {
    flex: 1,
    justifyContent: 'flex-end',
    opacity: 0.5,
  },
};

type QuestionProps = {
  item: {
    title: string;
    description: string;
  };
};

const Question = ({ item }: QuestionProps) => {
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>();

  const handleClick = useCallback(() => {
    if (ref.current?.offsetHeight) {
      setHeight(ref.current.offsetHeight);
    }
    setOpen(state => !state);
  }, []);

  return (
    <Box sx={question}>
      <Typography
        variant="body1"
        sx={button}
        fullWidth
        component={Button}
        onClick={handleClick}
        endIcon={(
          <KeyboardArrowDownIcon sx={{
            transition: 'transform 300ms ease 0s',
            transform: open? 'rotate(180deg)': '',
          }} />
        )}
      >{item.title}</Typography>
      <Box sx={[answer, { maxHeight: open ? height : 0 }]}>
        <Box ref={ref} sx={{ pb: 2 }}>
          <MarkdownText>{item.description}</MarkdownText>
        </Box>
      </Box>
    </Box>
  )
}

function formatTime(date) {
  const cetTime = new Date(date.getTime());
  const hours = padZero(cetTime.getHours());
  const minutes = padZero(cetTime.getMinutes());
  return `${hours}:${minutes}`;
}

function padZero(num) {
  return num < 10 ? `0${num}` : `${num}`;
}

function formatDate(input) {
  const weekdays = ['söndag', 'måndag', 'tisdag', 'onsdag', 'torsdag', 'fredag', 'lördag'];
  const months = ['januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti', 'september', 'oktober', 'november', 'december'];

  const date = new Date(input);
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  // Convert UTC time to CET
  const cetDate = new Date(date.getTime());

  if (cetDate.toDateString() === today.toDateString()) {
      return `idag kl ${formatTime(cetDate)}`;
  } else if (cetDate.toDateString() === tomorrow.toDateString()) {
      return `imorgon kl ${formatTime(cetDate)}`;
  } else {
      return `${weekdays[cetDate.getDay()]} den ${cetDate.getDate()} ${months[cetDate.getMonth()]} kl ${formatTime(cetDate)}`;
  }
}

const agents = {
  elin: {
    name: 'Elin Ribjer',
    picture: '/elin.avif',
  },
  christina: {
    name: 'Christina Olnils',
    picture: '/christina.png',
  },
  lemarc: {
    name: 'Lemarc Thomas',
    picture: '/lemarc.png',
  },
  anna: {
    name: 'Anna Marin',
    picture: '/anna.png',
  },
};

export default function Invitation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const auth = useAuth();
  const [showLogin, setShowLogin] = useState(false);
  const expires = searchParams.get('expires') || dayjs().add(3, 'day').endOf('day').toISOString();
  const [expirationDate, setExpirationDate] = useLocalStorage<string>('expirationDate', expires, true);
  // make sure the stored expiration matches the search params value.
  useEffect(() => {
    if (expires !== expirationDate) {
      setExpirationDate(expires);
    }
  }, [expires]);

  const name = searchParams.get('name');
  const matchmaker = searchParams.get('matchmaker') || 'christina';
  const agent = agents[matchmaker as keyof typeof agents] || agents.elin;
  
  const pageTitle = t('invitation.page_title', 'Din inbjudan till Relate Matchmaking');
  const title = name ? t('invitation.title', '{{name}}, kan min klient vara din stora kärlek? ♥️', {
    name,
  }) : t('invitation.plain_title', 'Kan min klient vara din stora kärlek? ♥️');
  const description = t('invitation.description', 'Jag skulle gärna lära känna dig mer genom att du svarar på frågorna i formuläret nedan. Jag behöver ditt svar senast *{{date}}* för att hinna gå igenom allt.\n\nOm ni är en bra match så hör jag av mig för att se om ni vill bli introducerade till varandra.', {
    date: formatDate(expirationDate),
  });

  const benefits = [
    {
      icon: '/hand.svg',
      description: t('membership_benefit_1.description', 'Chans att matchas och introduceras till rätt personer för just dig'),
    },
    {
      icon: '/heart.svg',
      description: t('membership_benefit_2.description', 'Vid en introduktion planerar vi dejten åt er så ni kan fokusera på att lära känna varandra'),
    },
    {
      icon: '/person.svg',
      description: t('membership_benefit_3.description', 'En diskret profil som endast våra matchmakers har tillgång till'),
    },
    {
      icon: '/flash.svg',
      description: t('membership_benefit_4.description', 'Slipp allt svajpande och chattande, gå direkt på bra dejter istället'),
    },
  ];

  const process = [
    {
      icon: '/one.svg',
      title: t('invitation_membership_process_1.title', 'Svara på våra frågor'),
      description: t('invitation_membership_process_1.description', 'Svara på frågor om vem du är och vem du längtar efter. Det är bästa sättet för våra matchmakers att veta vem som är rätt person för just dig.'),
    },
    {
      icon: '/two.svg',
      title: t('invitation_membership_process_2.title', 'Du är med i urvalet'),
      description: t('invitation_membership_process_2.description', 'När du har skapat din profil och verifierat dig så är du redo att vara med i urvalet. Våra matchmakers går igenom alla i urvalet och väljer ut dom bästa för nästa steg.'),
    },
    {
      icon: '/three.svg',
      title: t('invitation_membership_process_3.title', 'Bli introducerad'),
      description: t('invitation_membership_process_3.description', 'När du blir kontaktad så betyder det att du är utvald för att ni är en riktigt bra match och våra matchmakers vill introducera dig.'),
    },
  ];

  const questions = [
    {
      title: t('membership_question_1.title'),
      description: t('membership_question_1.description'),
    },
    {
      title: t('invite_question_2.title', 'Varför kontaktar ni mig?'),
      description: t('invite_question_2.description', 'En av våra matchmakers tror att den har hittat en bra match till dig! När du svarar på profilformuläret får matchmakern all information som behövs för att utforska vidare er matchningspotential. Om ni sedan introduceras för att gå på en dejt så fixar vi allt åt er, så ni bara kan fokusera på att utforska varandras sällskap.'),
    },
    {
      title: t('invite_question_3.title', 'Kostar detta mig något?'),
      description: t('invite_question_3.description', 'Nej. Det är helt kostnadsfritt att svara på frågorna. När du skickat in dina svar så får du en kostnadsfri medlemsprofil i vårt nätverk med chans att bli matchad med fler av våra klienter. Som nätverksmedlem har du dock inga garantier om introduktioner till matchningar.\n\nFör garanterade introduktioner med utvalda personer som matchar just dig så kan du skaffa en egen personlig matchmaker. Du får ett program med bland annat garanterade matchningar, coaching och stöd från din matchmaker för att kunna ha mer kärlek i din framtid. Att vara klient i matchmakingprogrammet kostar.'),
    },
    {
      title: t('invite_question_4.title', 'Hur hanterar ni min information?'),
      description: t('invite_question_4.description', 'När du skickar in dina svar på formuläret till oss så blir du medlem i Relates matchmaking-nätverk. Vi tar dataskydd, diskression och sekretess på största allvar. Vi använder din data endast för matchningsändamål enligt våra användarvillkor och integritetspolicy och säljer aldrig till tredjeparter.'),
    },
    {
      title: t('invite_question_5.title', 'Kan jag skaffa en egen personlig matchmaker?'),
      description: t('invite_question_5.description', 'Ja! Som klient får du ta del av ett matchmaking-program som hjälper dig att hitta relationen som du drömmer om. Du får bland annat garanterade introduktioner, coaching och stöd från din matchmaker för att hitta rätt i ditt kärleksliv. Att vara klient i matchmaking-programmet kostar och [du kan läsa mer om det här.](/matchmaker)'),
    },
    {
      title: t('membership_question_6.title', 'Hur kan jag kontakta Relate?'),
      description: t('membership_question_6.description', 'Du är välkommen att skriva dina frågor och tankar till Relate på [hello@relate-matchmaking.com](mailto:hello@relate-matchmaking.com).'),
    }
  ];

  const handleClick = useCallback(() => {
    if (!auth?.user) {
      // Open login modal.
      setShowLogin(true);
    } else {
      // Start profile form if logged in.
      const isApplied = Boolean(auth?.user?.appliedAt);
      const isProfileComplete = Boolean(auth?.user?.profileUpdatedAt);
      const isPreferenceComplete = Boolean(auth?.user?.preferenceUpdatedAt);
      // Skip profile selection if none exist already.
      if (!isApplied) {
        navigate('/application');
        return;
      }
      if (!isProfileComplete) {
        navigate('/profile');
        return;
      }
      if (!isPreferenceComplete) {
        navigate('/preferences');
        return;
      }
      navigate('/');
    }
  }, [auth]);

  return (
    <Body>
      <Helmet>
        <title>Relate | {pageTitle}</title>
      </Helmet>
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <Image src="/logo.png" sx={{ width: 65 }} />
      </Box>
      <Box>
        <Box>
          <Section
            sx={{
              textAlign: 'center',
              em: { color: 'white', fontWeight: 'bold' },
            }}
          >
            <Box sx={{ mb: 5 }}>
              <Typography component="h1" variant="h1" sx={{ mb: 4 }}>{title}</Typography>
              <MarkdownText>{description}</MarkdownText>
            </Box>
            <Button
              onClick={handleClick}
              fullWidth
              size="large"
              variant="contained"
              sx={{
                mb: 1,
                backgroundAttachment: 'fixed',
                backgroundImage:  'repeating-radial-gradient(circle at -100px -400px, #76451F66 0, transparent 800px), repeating-linear-gradient(#be8a6144, #be8a6122)',
              }}
            >
              {t('invitation.action', 'Svara på frågorna idag')}
            </Button>
            <Typography variant="caption" component="div" textAlign="center" sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
              <WatchLaterIcon sx={{ fontSize: 17, mr: '4px' }} />
              {t('invitation.help', 'Tar ca 20 minuter')}
            </Typography>
            <Box sx={{
              p: 2,
              py: 3,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <Image
                src={agent?.picture}
                sx={{ width: 48, height: 48, borderRadius: '50%', flex: 0, mr: 1.5 }}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant="h5" sx={{ mb: 0 }}>{agent?.name}</Typography>
                <Typography variant="caption">
                  {t('matchmaker_at_relate', 'Matchmaker på Relate')}
                </Typography>
              </Box>
            </Box>
          </Section>
        </Box>
        <Box sx={{ borderTop: 'solid 1px #38191E' }}></Box>
        <Section
          sx={{
            textAlign: 'center',
            em: { color: 'white', fontWeight: 'bold' },
          }}
        >
          <Box sx={{ mb: 4 }}>
            <Typography variant="body1">
              {t('invitation_membership.description', 'Svarar du på frågorna får du även möjlighet att bli medlem i vårt matchmaking-nätverk, där du har chans att bli matchad och introducerad för fler av våra klienter.')}
            </Typography>
          </Box>
          <Video
            title={t('matchmaker.video_title', 'Elin berättar om vår matchmaking')}
            src={{
              webm: '/matchmaker.webm',
              mp4: '/matchmaker.mp4',
              caption: '/matchmaker.vtt',
            }}
          />
        </Section>
        <Section highlight sx={{ flexDirection: 'row' }}>
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography component="h2" variant="h2" textAlign="center">
              {t('membership.benefits_title', 'Som medlem får du')}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              {benefits.map((item) => (
                <Box key={item.icon} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', my: 2 }}>
                  <Box
                    component="img"
                    src={item.icon}
                    sx={{
                      width: 52, height: 52, minWidth: 52, mr: 2,
                      borderRadius: 26,
                      backgroundAttachment: 'fixed',
                      backgroundImage:  'repeating-radial-gradient(circle at 0 0, #230e11 0, transparent 200px), repeating-linear-gradient(#4a362b88, #4a362b44)',
                    }}
                  />
                  <Typography variant="body2" sx={{ flex: 1 }}>{item.description}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              width: '40%',
              background: 'url(/membership.png)',
              backgroundPosition: 'left center',
              backgroundSize: 'auto 100%',
              ml: 6,
              mr: -6,
              mt: -6,
              mb: -6,
              borderTopRightRadius: '32px',
              borderBottomRightRadius: '32px',
              display: 'none',
              '@media screen and (min-width: 720px)': { display: 'flex' },
            }}
          />
        </Section>
        <Section>
          <Typography component="h2" variant="h2" textAlign="center">
            {t('invitation_membership.process_title', 'Så går det till')}
          </Typography>
          <Typography variant="body2" textAlign="center">
            {t('invitation_membership.process_description', 'Detta är hur det går till för dig som inbjuden till nätverket:')}
          </Typography>
          <Box>
            {process.map((item) => (
              <Box key={item.icon} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 6 }}>
                <Box
                  component="img"
                  src={item.icon}
                  sx={{
                    width: 52, height: 52, minWidth: 52, mb: 2,
                    borderRadius: 26,
                    backgroundAttachment: 'fixed',
                    backgroundImage:  'repeating-radial-gradient(circle at 0 0, #230e11 0, transparent 100px), repeating-linear-gradient(#4a362b88, #4a362b44)',
                  }}
                />
                <Box sx={{ display: 'flex', gap: 2, flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography component="h3" variant="h3">{item.title}</Typography>
                  <Typography variant="body2" textAlign="center">{item.description}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Section>
        <Section highlight sx={{ alignItems: 'center', flexDirection: 'row' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body2" textAlign="center" fontStyle="italic" sx={{ mb: 1 }}>
              “{t('membership.quote', 'Jag blev riktigt glad när matchmakern valde mig för att träffa en av deras klienter. Det kostade inget, och helt ärligt, det var en riktigt bra upplevelse. Att få träffa någon på det här sättet? Helt oväntat men definitivt värt det.')}”
            </Typography>
            <Typography component="h5" variant="h5" textAlign="center">
              {t('membership.quote_author', '– {{name}}, {{age}} år', { name: 'Erik', age: 37 })}
            </Typography>
          </Box>
          <Box 
            component="img" 
            src="/erik.png" 
            sx={{
              width: 192, height: 192, ml: 4,
              borderRadius: 96,
              display: 'none',
              '@media screen and (min-width: 720px)': { display: 'flex' },
            }}
          />
        </Section>
        <Section>
          <Box sx={{ pb: 4, borderBottom: 'solid 1px #fff2' }}>
            <Box sx={{ mb: 3, textAlign: 'center' }}>
              <Typography component="h2" variant="h2" textAlign="center">
                {t('invitation.selling_title', 'Svara på frågorna idag')}
              </Typography>
              <MarkdownText>
                {t('invitation.selling_description', 'Vi söker kontinuerligt efter matchningar för våra klienter, svara på frågorna och gå med i vårt nätverk för att få möjligheten att bli introducerad.\n\nDet kostar inget och du slipper dejtingappar - du behöver bara svara på vår matchmakers frågor.')}
              </MarkdownText>
            </Box>
            <Button
              onClick={handleClick}
              fullWidth
              size="large"
              variant="contained"
              sx={{
                mb: 1,
                backgroundAttachment: 'fixed',
                backgroundImage:  'repeating-radial-gradient(circle at 10px 0, #76451F66 0, transparent 600px), repeating-linear-gradient(#be8a6144, #be8a6122)',
              }}
            >
              {t('invitation.action')}
            </Button>
            <Typography variant="caption" component="div" textAlign="center" sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
              {t('membership.help', 'Medlemskap i vårt nätverk är kostnadsfritt.')}
            </Typography>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Box sx={{ pb: 3 }}>
              <Typography component="h2" variant="h2" textAlign="center">
                {t('invitation_membership.matchmaking_title', 'Vill du också ha en egen personlig matchmaker?')}
              </Typography>
              <Typography variant="body2" textAlign="center">
                {t('membership.matchmaking_description', 'Med din egna personlig matchmaker går du igenom en noggrann matchmakingprocess där du bland annat får garanterade introduktioner med utvalda matchningar samt tips, stöd och verktyg av din matchmaker.')}
              </Typography>
            </Box>
            <Button
              component={Link}
              to={'/matchmaker' + (name ? `?name=${name}`: '')}
              fullWidth
              size="large"
              variant="outlined"
              sx={{
                mb: 1,
                backgroundAttachment: 'fixed',
                backgroundImage:  'repeating-radial-gradient(circle at 0 0, #230e11 0, transparent 600px), repeating-linear-gradient(#4a362b88, #4a362b44)',
              }}
            >
              {t('membership.matchmaking_action', 'Läs mer om vårt matchmakingprogam')}
            </Button>
          </Box>
        </Section>
        <Section highlight>
          <Typography component="h2" variant="h2">
            {t('invitation.questions_title', 'Undrar du något?')}
          </Typography>
          <Box>
            {questions.map((item) => (
              <Question key={item.title} item={item} />
            ))}
          </Box>
        </Section>
        <Section sx={{ alignItems: 'center' }}>
          <Image src="/logo.png" sx={{ width: 84 }} />
          <Typography variant="caption">
            {t('slogan')}
          </Typography>
        </Section>
        <Section />
        {showLogin && (
          <LoginDialog
            open
            handleClose={() => setShowLogin(false)}
            onLoggedIn={(user) => {
              const isApplied = Boolean(user?.appliedAt);
              const isProfileComplete = Boolean(user?.profileUpdatedAt);
              const isPreferenceComplete = Boolean(user?.preferenceUpdatedAt);
              // Skip profile selection if none exist already.
              if (!isApplied) {
                navigate('/application');
                return;
              }
              if (!isProfileComplete) {
                navigate('/profile');
                return;
              }
              if (!isPreferenceComplete) {
                navigate('/preferences');
                return;
              }
              navigate('/');
            }}
          />
        )}
      </Box>
    </Body>
  );
}
