import { ReactiveVar, useReactiveVar } from "@apollo/client";
import { Button } from '@mui/material';


type RecordButtonProps = {
  capturing: boolean;
  recording: ReactiveVar<number>,
  disabled?: boolean
  onClick: () => void,
};

const button = {
  width: 64,
  height: 64,
  borderRadius: 32,
  p: 0,
};

function RecordButton({ capturing, recording, disabled, onClick }: RecordButtonProps) {
  const progress = useReactiveVar(recording);

  const p = Math.max(0, Math.min(1, progress));
  const r = 32-5;
  const c = 2 * r * Math.PI;
  return (
    <>
      <Button disabled={disabled} sx={[button, {
        ...disabled && { filter: 'grayscale()', opacity: 0.5 },
      }]} onClick={onClick}>
        <svg
          width={64}
          height={64}
          viewBox="0 0 64 64"
          fill="none"
        >
          <rect
            x={capturing ? 64 / 2 - 8 : 10}
            y={capturing ? 64 / 2 - 8 : 10}
            rx={capturing ? 3 : 32}
            width={capturing ? 16 : 64-20}
            height={capturing ? 16 : 64-20}
            fill="#C8253F"
            style={{
              transition: 'all 80ms ease 0s',
            }}
          />
          <circle
            cx={32}
            cy={32}
            r={r}
            stroke="#fff"
            strokeWidth={10}
            strokeDashoffset={c / 4}
            strokeDasharray={`${p * c} ${(1 - p) * c}`}
            fill="none"
            style={{
              transition: `stroke-dasharray ${p < 1 ? '16ms linear' : '320ms ease'} 0s`,
            }}
          />
        </svg>
      </Button>
    </>
  );
}

export default RecordButton;
