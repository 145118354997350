import { gql } from "@apollo/client";

const UserPreferenceFragment = gql`
  fragment UserPreferenceFragment on User {
    settings {
      preferences {
        sex
        sex_orientation
        age {
          enabled
          min
          max
        }
        height {
          enabled
          min
          max
        }
        relationship
        first_date
        time_with_partner
        education_level
        income
        childhood
        pets
        alcohol
        tobacco
        have_kids
        lives_with_kids
        want_kids
        marriage
        dealbreakers
        future_life
        relation_feeling
        relation_need
        personality
        energy
        sex_frequency
        sex_language
        extra
      }
    }
  }
`;

export default UserPreferenceFragment;
