import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import Image from './Image';

type ProfileOptionProps = {
  label: string;
  labelType?: string,
  selected?: boolean;
  onClick?: () => void;
  description?: string;
  picture?: { uri: string };
}

const getLabelTypeColor = (labelType?: string) => {
  switch (labelType) {
    case 'success':
      return '#00CC2D';
    case 'error':
      return '#CC2D00';
    case 'warning':
    default:
      return '#FFB015';
  }
}

const ProfileOption = ({ label, labelType, selected, description, picture, onClick, }: ProfileOptionProps) => {
  return (
    <Button
      variant="contained"
      disableElevation
      color="primary"
      onClick={onClick}
      disableFocusRipple={!onClick}
      disableRipple={!onClick}
      disableTouchRipple={!onClick}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        p: 1.125,
        borderRadius: 3,
        ...!onClick && {
          cursor: 'inherit !important',
          userSelect: 'text !important',
        },
        ...selected ? {
          boxShadow: 'inset 0 0 0 2px #89696D !important',
          backgroundColor: '#5C383E',
          '&:hover': {
            backgroundColor: '#5C383E',
          }
        } : {
          backgroundColor: '#4A292E',
          '&:hover': {
            backgroundColor: '#4A292E',
          }
        },
      }}
    >
      <Box
        component={Image}
        src={picture?.uri}
        sx={{
          height: 48,
          width: 48,
          borderRadius: 24,
          flex: 0,
          flexBasis: 48,
          '&:after': {
            content: '"+"',
            display: 'block',
            fontSize: '24px',
            fontWeight: 700,
            color: '#89696D',
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            border: 'dashed 2px #89696D',
          }
        }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'flex-start', px: 1, textAlign: 'left' }}>
        <Typography variant="subtitle2" component="h3">{label}</Typography>
        {description && <Typography variant="caption">
          {labelType && (
            <CircleIcon sx={{ fontSize: 10, fill: getLabelTypeColor(labelType), mr: 0.5 }} />
          )}
          {description}
        </Typography>}
      </Box>
    </Button>
  );
}

export default ProfileOption;
