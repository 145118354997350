import { useMemo } from 'react';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import 'dayjs/locale/sv';

dayjs.extend(utc);
dayjs.extend(advancedFormat);
dayjs.extend(updateLocale);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);

dayjs.updateLocale('sv', {
  ordinal: (n: number) => `${n}:${[
    1,2,21,22,31
  ].includes(n) ? '[a]' : '[e]'}`, // ordinal Function (number) => return number + output
});

function Layout({ children }: { children: JSX.Element }) {
  const { i18n } = useTranslation();
  const prefersDarkMode = true; // useMediaQuery('(prefers-color-scheme: dark)');
  const theme = useMemo(() => createTheme({
    palette: {
      mode: prefersDarkMode ? 'dark' : 'light',
      background: {
        default: '#EFE9E2',
        paper: prefersDarkMode ? '#38191E' : '#FBF7F2',
      },
      primary: {
        contrastText: "#fff",
        dark: "#76451F",
        light: "#42a5f5",
        main: "#8E603C",
      },
      secondary: {
        contrastText: "#fff",
        dark: "#220E11",
        light: "#42a5f5",
        main: "#38191E",
      },
    },
    typography: {
      fontFamily: "'DM Sans', sans-serif",
      body1: { fontSize: '1.375rem' },
      body2: { fontSize: '1.2rem', color: prefersDarkMode ? '#fcc' : '#434343' },
      caption: { fontSize: '1.075rem', lineHeight: 1.5, fontStyle: 'italic', color: prefersDarkMode ? '#A57E83' : '#434343' },
      h1: { fontSize: '2.75rem', lineHeight: 1.25, fontFamily: "'DM Serif Display', sans-serif", marginBottom: '1rem' },
      h2: { fontSize: '2.25rem', lineHeight: 1.25, fontFamily: "'DM Serif Display', sans-serif", marginBottom: '1rem' },
      h3: { fontSize: '1.75rem', lineHeight: 1.25, fontFamily: "'DM Serif Display', sans-serif", marginBottom: '0.5rem' },
      h4: { fontSize: '1.50rem', lineHeight: 1.25, fontFamily: "'DM Serif Display', sans-serif", marginBottom: '0.5rem' },
      h5: { fontSize: '1.25rem', lineHeight: 1.25, fontFamily: "'DM Serif Display', sans-serif", marginBottom: 0 },
      h6: { fontSize: '1.25rem', lineHeight: 1.25, fontFamily: "'DM Sans', sans-serif", marginBottom: '1rem' },
      button: { fontWeight: 700, textTransform: 'none' },
      subtitle1: { fontFamily: "'DM Sans', sans-serif", fontSize: '1.2rem', lineHeight: 1.25, fontWeight: 700 },
      subtitle2: { fontFamily: "'DM Sans', sans-serif", fontSize: '1.075rem', lineHeight: 1.25, fontWeight: 700 },
    },
    components: {
      MuiLink: {
        styleOverrides: {
          root: {
            cursor: 'pointer',
            color: prefersDarkMode ? 'white' : 'black',
            textDecorationColor: prefersDarkMode ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            borderRadius: 24,
            fontSize: '14px',

            '&.MuiButton-text': {
              background: 'none',
              // textDecoration: 'underline',
              color: prefersDarkMode ? 'white' : 'black',
            },

            '&.MuiButton-outlined': {
              color: prefersDarkMode ? 'white' : '#222',
              border: 0,
              boxShadow: prefersDarkMode ? 'inset 0 0 0 1px white' : 'inset 0 0 0 1px #434343',
            },

            '&.MuiButton-sizeSmall': {
              padding: '5px 12px',
              fontSize: '1rem',
              minWidth: 34,
            },

            '&.MuiButton-sizeLarge': {
              padding: '8px 16px',
              fontSize: '1rem',
            },
          },
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            width: 'auto !important',
            marginRight: 8,
            '&.Mui-checked': {
              color: prefersDarkMode ? 'white' : 'black',
            },
            'svg': {
              boxShadow: prefersDarkMode ? 'inset 0 0 0 3px #FBF7F244' : 'inset 0 0 0 3px #8E603C88',
              background: prefersDarkMode ? '#422529' : '#FBF7F2',
              width: 28,
              height: 28,
              borderRadius: 5,
              fill: 'none',
            },
            '&.Mui-checked svg': {
              fill: prefersDarkMode ? '#FBF7F2' : '#8E603C',
              boxShadow: prefersDarkMode ? 'inset 0 0 0 4px white' : 'inset 0 0 0 4px #8E603C',
            },
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& label': {
              color: prefersDarkMode ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 1)',
              whiteSpace: 'normal',
              position: 'relative',
            },
            '& label.Mui-focused': {
              color: prefersDarkMode ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 1)',
            },
            'input': {
              borderRadius: 8,
              fontSize: '1.125rem',
            },
            'textarea': {
              fontSize: '1.125rem',
            },
            '& ::-webkit-input-placeholder': {
              fontSize: '1.125rem',
            },
            'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active': {
              WebkitBoxShadow: prefersDarkMode ? '0 0 0 100px #8E603C inset' : '0 0 0 100px #DFD4C6 inset', // '0 0 0 60px #fafafa inset !important',
              backgroundClip: 'content-box !important',
              WebkitTextFillColor: prefersDarkMode ? 'rgba(255, 255, 255, 0.75)' : 'rgba(0, 0, 0, 0.75)',
            },
            'input.MuiInputBase-inputAdornedStart': {
              marginLeft: '-35px',
              paddingLeft: '35px',
            },
            'input.MuiInputBase-inputAdornedEnd': {
              marginRight: '-31px',
              paddingRight: '31px',
            },
            '.MuiPhoneNumber-flagButton': {
              height: '32px',
              width: '32px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 0,
            },
            '.MuiPhoneNumber-flagButton svg': {
              minWidth: '16px',
              minHeight: '16px',
              width: '20px',
              height: '13px',
              padding: 0,
            },
            'input::-webkit-outer-spin-button,input::-webkit-inner-spin-button': {
              appearance: 'none',
              margin: 0,
            },
            'input[type=number]': {
              appearance: 'textfield',
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            '&:before, &:after': {
              borderBottom: 'none',
            },
            '&.Mui-disabled': {
              opacity: 0.25,
            },
            '&.Mui-disabled input': {
              WebkitTextFillColor: prefersDarkMode ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 0.75)',
            },
            '&.Mui-disabled:before': {
              borderBottomStyle: 'none',
              opacity: 0.5,
            },
            '&:hover:not(.Mui-disabled, .Mui-error):before': {
              borderBottom: 'none',
            },
            '&.Mui-focused:after': {
              borderBottom: 'none',
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '&:before': {
              borderBottom: '2px solid var(--TextField-brandBorderColor)',
            },
            '&:hover:not(.Mui-disabled, .Mui-error):before': {
              borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
            },
            '&.Mui-focused:after': {
              borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '.MuiFilledInput-root .MuiInputBase-input': {
              paddingTop: 12,
              paddingBottom: 12,
            },
            '.MuiInputBase-root': {
              paddingTop: 0,
              paddingBottom: 0,
            },
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            'input': {
              paddingTop: 12,
              paddingBottom: 12,
            },

            'label + &': {
              marginTop: '0.85em',
            },

            '&.MuiInputBase-multiline': {
              paddingTop: 12,
              paddingBottom: 12,

              'textarea': {
                minHeight: 48,
              }
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: 'white',
            transition: 'none',
            transform: 'none',
            fontWeight: 'bold',
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            paddingTop: 12,
            paddingBottom: 12,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            '&.MuiInputLabel-sizeMedium': {
              fontSize: '1.25rem',
            }
          }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            marginLeft: 8,
          }
        }
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            margin: '-12px !important',
            height: '47px',
            maxHeight: 'none',
            width: '47px',
            justifyContent: 'center',
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 0,
          },
        }
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundImage: 'none',
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontFamily: "'DM Serif Display', sans-serif",
            fontSize: '2.125rem',
            lineHeight: 'inherit',
            padding: 16,
            paddingBottom: 0,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: 16,
          },
        },
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            fontFamily: "'DM Sans', sans-serif",
            fontSize: '1rem',
            color: prefersDarkMode ? '#E0AFB6' : '#434343',
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            '&:not(.MuiPickersLayout-actionBar)': {
              flexDirection: 'column',
              gap: 8,

              '.MuiButtonBase-root': {
                width: '100%',
              }
            }
          },
        },
      },
    },
  }), [prefersDarkMode]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default Layout
