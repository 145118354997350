import { IconButton, Slider, Typography, keyframes } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';
import ClosedCaptionOffIcon from '@mui/icons-material/ClosedCaptionOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactHlsPlayer from '@gumlet/react-hls-player';
import { track } from '../helpers/mixpanel';
import Image from './Image';

function detectMob() {
  return ('ontouchstart' in document.documentElement);
}
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

type VideoProps = {
  title?: string,
  showTitle?: boolean,
  src?: {
    src?: string,
    webm?: string,
    mp4?: string,
    caption?: string,
  },
  poster?: string,
  onTimeUpdate?: (currentTime: number, duration: number) => void,
} & BoxProps;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
`;
const fadeOut = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.49);
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
`;

export function useSingleAndDoubleClick(
  handleSingleClick: () => void,
  handleDoubleClick: () => void,
  delay = 250
) {
  const [click, setClick] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (click === 1) {
        handleSingleClick();
      }
      setClick(0);
    }, delay);

    if (click === 2) {
      handleDoubleClick();
    }

    return () => clearTimeout(timer);

  }, [click, handleSingleClick, handleDoubleClick, delay]);

  return () => setClick(prev => prev + 1);
}

type WebkitHTMLVideoElement = HTMLVideoElement & {
  webkitEnterFullscreen?: () => void,
  webkitExitFullscreen?: () => void,
  webkitDisplayingFullscreen?: boolean,
};

function Video({ sx, poster, children, title, src, showTitle = true, onTimeUpdate, ...props }: VideoProps) {
  const [playing, setPlaying] = useState<boolean | null>(null);
  const [showButtons, setShowButtons] = useState(true);
  const [showFullscreen, setShowFullscreen] = useState(false);
  const [showCaptions, setShowCaptions] = useState(true);
  const [muted, setMuted] = useState(false);
  const [volume, setVolume] = useState(100);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const fullscreenRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let video = videoRef.current as WebkitHTMLVideoElement;
    const isFullscreen = Boolean(document.fullscreenElement);
    if (fullscreenRef.current && document.fullscreenEnabled) {
      if (isFullscreen !== showFullscreen) {
        if (showFullscreen) {
          try {
            fullscreenRef.current.requestFullscreen();
          } catch (e) {
            console.warn('not allowed');
          }
        } else if (document.fullscreenElement) {
          document.exitFullscreen();
        }
      }
    } else if (!document.fullscreenEnabled && video?.webkitEnterFullscreen) { // Fix for safari's lack of modern fullscreen api
      if (showFullscreen) {
        video.play().then(() => video.webkitEnterFullscreen?.());
      } else {
        video.webkitExitFullscreen?.();
      }
    }
  }, [showFullscreen]);

  useEffect(() => {
    const onFullscreenChange = () => {
      const isFullscreen = Boolean(document.fullscreenElement);
      setShowFullscreen(isFullscreen);
    };

    window.addEventListener('fullscreenchange', onFullscreenChange);
    return () => window.removeEventListener('fullscreenchange', onFullscreenChange);
  }, [setShowFullscreen]);

  // Fix for iOS safari's lack of modern fullscreen api
  useEffect(() => {
    let video = videoRef.current as WebkitHTMLVideoElement;
    let interval: NodeJS.Timeout;
    if (showFullscreen && video?.webkitEnterFullscreen && !document.fullscreenEnabled) {
      interval = setInterval(() => {
        if (!video.webkitDisplayingFullscreen) {
          setShowFullscreen(false);
          videoRef.current.pause();
          setPlaying(false); // safari pauses playback when going out of fullscreen.
          // safari doesn't trigger any volume event changes.
          const nextVolume = video.volume * 100;
          setMuted(nextVolume === 0 || video.muted);
          setVolume(nextVolume);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [showFullscreen]);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.volume = volume / 100;
    }
  }, [volume]);
  
  useEffect(() => {
    const video = videoRef.current;
    let startTime = Date.now();

    const handleTimeUpdate = () => {
      if (!video?.paused) {
        const currentTime = Math.floor(video.currentTime);
        const currentProgress = Math.floor((currentTime / video?.duration) * 100);
        track('Video Time Update', {
          'Video Name': title,
          'Current Time': currentTime,
          'Current Progress': currentProgress,
          'Total Duration': video?.duration,
        });
        if (video && onTimeUpdate) {
          onTimeUpdate(video?.currentTime, video?.duration);
        }
      }
    };

    const handlePlay = () => {
      startTime = Date.now();  // Reset start time on play
      track('Video Play', {
        'Video Name': title,
      });
    };

    const handlePause = () => {
      const watchedTime = (Date.now() - startTime) / 1000;
      track('Video Paused', {
        'Video Name': title,
        'Watched Time': watchedTime
      });
    };

    const handleEnded = () => {
      const totalWatchedTime = (Date.now() - startTime) / 1000;
      track('Video Ended', {
        'Video Name': title,
        'Total Watched Time': totalWatchedTime
      });
    };

    if (video) {
      video.addEventListener('timeupdate', handleTimeUpdate);
      video.addEventListener('play', handlePlay);
      video.addEventListener('pause', handlePause);
      video.addEventListener('ended', handleEnded);
    }

    return () => {
      if (video) {
        video.removeEventListener('timeupdate', handleTimeUpdate);
        video.removeEventListener('play', handlePlay);
        video.removeEventListener('pause', handlePause);
        video.removeEventListener('ended', handleEnded);
      }
    };
  });

  const handleVideoPlayPause = useCallback(() => {
    setPlaying(state => {
      const nextState = !state;
      if (nextState) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
      return nextState;
    });
  }, []);

  const handleVideoFullscreen = useCallback(() => {
    setShowFullscreen(state => !state);
  }, []);

  const handleToggleCaptions = useCallback(() => {
    setShowCaptions(state => !state);
  }, []);

  const handleToggleMute = useCallback(() => {
    if (muted) {
      setMuted(false);
      setVolume(100);
    } else {
      setMuted(true);
      setVolume(0);
    }
  }, [muted]);

  const handleVolumeChange = useCallback((e) => {
    setVolume(e.target.value);
    if (e.target.value === 0) {
      setMuted(true);
    } else {
      setMuted(false);
    }
  }, []);

  const handleVideoEnd = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.pause();
      setPlaying(false);
    }
  }, []);

  useEffect(() => {
    let t: NodeJS.Timeout;
    if (playing && showButtons) {
      t = setTimeout(() => {
        setShowButtons(false);
      }, 2000);
    }
    const touchMove = () => setShowButtons(true);
    window.addEventListener('touchstart', touchMove);
    return () => {
      clearTimeout(t);
      window.removeEventListener('touchstart', touchMove);
    }
  }, [playing, showButtons]);

  const throttledShowButtons = useCallback(() => {
    setShowButtons(true)
  }, []);

  const handleVideoClick = useSingleAndDoubleClick(handleVideoPlayPause, handleVideoFullscreen);

  return (
    <Box
      className="block"
      sx={{
        my: 2,
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? '#0001' : '#fff1',
        borderRadius: 4,
        position: 'relative',
        paddingTop: '56%',
        overflow: 'hidden',
        video: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: 4,
        },
        'video::cue': {
          color: 'white',
          fontFamily: "'DM Sans', sans-serif",
        },
        'video::-webkit-media-text-track-display': {
          width: 'calc(100% - 16px) !important',
          margin: '8px',
          top: 'auto !important',
          bottom: '0 !important',
          borderRadius: '0 !important',
          padding: 1,
          ...!detectMob() && !isSafari && {
            backdropFilter: 'blur(4px)',
          },
        },
        ...sx,
      }}
      {...props}
    >
      {src && (
        <Box
          ref={fullscreenRef}
          onMouseMove={throttledShowButtons}
          onMouseLeave={() => setShowButtons(false)}
          sx={{
            position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
            '@media (max-aspect-ratio: 16/9)': {
              '&:fullscreen #controls:before': {
                opacity: 0,
              },
            },
          }}
        >
          <Image
            id="logo"
            src="/logo.png"
            sx={{
              width: { xs: 64, sm: 100 },
              position: 'absolute',
              top: { xs: 12, sm: 32 },
              left: { xs: 'calc(50% - 32px)', sm: 'calc(50% - 50px)' },
              zIndex: 1,
            }}
          />
          <Box
            id="video"
            onClick={handleVideoClick}
            sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }}
          >
            {src?.m3u8 ? (
              <ReactHlsPlayer
                playerRef={videoRef}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  borderRadius: 0,
                  pointerEvents: 'none',
                }}
                src={src?.m3u8}
                playsInline
                muted={muted}
                preload="metadata"
                poster={poster}
                onEnded={handleVideoEnd}
              >
                {src?.caption && showCaptions && (<track src={src.caption} kind="captions" default />)}
              </ReactHlsPlayer>
            ) : (
              <video
                ref={videoRef}
                onEnded={handleVideoEnd}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  borderRadius: 0,
                  pointerEvents: 'none',
                }}
                playsInline
                muted={muted}
                preload="metadata"
                poster={poster}
              >
                {src?.src && (<source type="video/mp4" src={src?.src} />)}
                {src?.mp4 && (<source src={src.mp4 + '#t=0.001'} type="video/mp4" />)}
                {src?.webm && (<source src={src.webm} type="video/webm" />)}
                {src?.caption && showCaptions && (<track src={src.caption} kind="captions" default />)}
              </video>
            )}
          </Box>
          {title && showTitle && !showCaptions && !showFullscreen && (
            <Box id="title" sx={{
              position: 'absolute',
              bottom: 0,
              p: 1,
              left: 0,
              right: 0,
              zIndex: 2,
              background: '#0008',
              backdropFilter: 'blur(10px)',
              lineHeight: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              pointerEvents: 'none',
              userSelect: 'none',
              borderBottomLeftRadius: 16,
              borderBottomRightRadius: 16,
              transition: 'opacity 0.5s, transform 0.2s',
              opacity: showButtons || !playing ? 1 : 0,
              '@media screen and (min-width: 400px)': {
                p: 2,
              },
            }}>
              <IconButton
                onClick={handleVideoPlayPause}
                sx={{
                  width: 44, height: 44, p: 1,
                  borderRadius: '50%',
                  pointerEvents: 'all',
                  mr: 1,
                  border: 'solid 2px white',
                  background: '#fff2 !important',
                  '@media screen and (min-width: 400px)': {
                    mr: 2,
                  }
                }}
              >
                {playing ? (
                  <PauseIcon />
                ) : (
                  <PlayArrowIcon />
                )}
              </IconButton>
              <Typography variant="body1" sx={{ flex: 1, fontStyle: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '1.2rem' }}>
                {title}
              </Typography>
            </Box>
          )}
          <Box
            id="controls"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              opacity: showButtons || !playing ? 1 : 0,
              zIndex: 2,
              pointerEvents: 'none',
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0, left: 0, right: 0, height: 168,
                pointerEvents: 'none',
                background: 'linear-gradient(0, #0000, #000)',
                transition: 'opacity 250ms ease 0s',
                opacity: 0.5,
              },
            }}
          >
            <IconButton
              sx={{
                width: { xs: 44, sm: 64 }, height: { xs: 44, sm: 64 }, p: 1,
                borderRadius: '50%',
                position: 'absolute',
                top: { xs: 'calc(50% - 22px)', sm: 'calc(50% - 32px)' },
                left: { xs: 'calc(50% - 22px)', sm: 'calc(50% - 32px)' },
                transition: 'opacity 0.5s, transform 0.2s',
                background: '#0004 !important',
                pointerEvents: 'none',
                color: '#fff',
                opacity: playing || (title && showTitle) ? 0 : 1,
                ...playing === true && {
                  animation: `${fadeIn} linear 500ms forwards`,
                },
                ...playing === false && {
                  animation: `${fadeOut} linear 500ms forwards`
                },
              }}
            >
              {[true, null].includes(playing) ?  (
                <PlayArrowIcon sx={{ fontSize: { sm: '32px' } }} />
                ) : (
                <PauseIcon sx={{ fontSize: { sm: '32px' } }} />
              )}
            </IconButton>
            <Box sx={{
              position: 'absolute',
              top: { xs: 8, sm: 16 },
              left: { xs: 8, sm: 16 },
              height: { xs: 32, sm: 44 },
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              background: '#fff2 !important',
              borderRadius: 22,
              transition: 'width 100ms ease 0s',
              overflow: 'hidden',
              pointerEvents: 'all',
              width: { xs: 32, sm: 44 },
              '@media screen and (min-width: 720px) and (min-height: 720px)': {
                '&:hover': {
                  width: 144 + 5 * 8,
                }
              },
            }}>
              <IconButton
                onClick={handleToggleMute}
                sx={{
                  width: { xs: 32, sm: 44 }, height: { xs: 32, sm: 44 },
                  flexBasis: { xs: 32, sm: 44 },
                  color: muted ? '#000' : '#fff',
                  background: muted ? '#fff !important' : '#fff0 !important',
                }}
              >
                {muted ? <VolumeOffIcon /> : (volume >= 50 ? <VolumeUpIcon /> : <VolumeDownIcon />)}
              </IconButton>
              <Slider
                id="slider"
                size="small"
                aria-label="Volume"
                value={volume}
                onChange={handleVolumeChange}
                sx={{
                  display: 'none',
                  '@media screen and (min-width: 720px)': {
                    display: 'block',
                  },
                  width: 100, color: 'white', ml: 2, mr: 3,
                  '.MuiSlider-thumb:hover': {
                    boxShadow: '0px 0px 0px 8px #fff2 !important'
                  },
                  '.MuiSlider-thumb.Mui-active': {
                    boxShadow: '0px 0px 0px 10px #fff4 !important'
                  }
                }}
              />
            </Box>
            {src?.caption && (
              <IconButton
                onClick={handleToggleCaptions}
                sx={{
                  position: 'absolute',
                  top: { xs: 8, sm: 16 },
                  right: { xs: 48, sm: 72 },
                  width: { xs: 32, sm: 44 }, height: { xs: 32, sm: 44 }, p: 1,
                  borderRadius: '50%',
                  pointerEvents: 'all',
                  ml: 1,
                  '&, &:hover': {
                    background: '#fff2 !important',
                    color: '#fff',
                  },
                  '@media screen and (min-width: 400px)': {
                    ml: 2,
                  }
                }}
              >
                {showCaptions ? <ClosedCaptionIcon /> : <ClosedCaptionOffIcon />}
              </IconButton>
            )}
            <IconButton
              onClick={handleVideoFullscreen}
              sx={{
                position: 'absolute',
                pointerEvents: 'all',
                top: { xs: 8, sm: 16 },
                right: { xs: 8, sm: 16 },
                width: { xs: 32, sm: 44 }, height: { xs: 32, sm: 44 }, p: 1,
                borderRadius: '50%',
                ml: 1,
                background: '#fff2 !important',
                '@media screen and (min-width: 400px)': {
                  ml: 2,
                }
              }}
            >
              {showFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Video;
