/* eslint-disable import/prefer-default-export */
const nonEmpty = (o: any) => (o ?? null) !== null;

const mergePictures = (pictures: any[], changes: any[], nextIndex: number): any[] => {
  const destination: Node[] = [];
  pictures.forEach((item, index) => {
    destination[index] = item;
  });
  changes.forEach((item, i) => {
    const index = nextIndex + i;
    destination[index] = item;
  });
  return destination.filter(nonEmpty);
};

export default mergePictures;
