export default async function askCameraPermission() {
  try {
    // This will prompt the user for permission to access the camera
    const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    
    // Permission was granted.

    // Now that we have the stream, we can release it because we only wanted the permission
    stream.getTracks().forEach(track => track.stop());
  } catch (error) {
    // Permission was denied or another error occurred
    // console.error('Camera permission denied', error);
  }
}
