import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Button, Chip } from '@mui/material';
import { useCallback, useState } from 'react';
import Image from '../../components/Image';
import MarkdownText from '../../components/MarkdownText';
import Section from '../../components/Section';
import { fadeIn } from './helpers';

const PlanSelector = ({ active, setActive, t }) => {
  const commonArrowStyles = useCallback((plan, direction) => ({
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: 24,
    [direction]: -36,
    borderRadius: 4,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    width: 32,
    height: 16,
    background: 'transparent',
    zIndex: active === plan ? 2 : 0,
    ...direction === 'left' && {
      borderBottomLeftRadius: 0,
      boxShadow: active === plan ? `16px 0 0 #3D161D` : `16px 0 0 #2C0F14`,
    },
    ...direction === 'right' && {
      borderBottomRightRadius: 0,
      boxShadow: active === plan ? `-16px 0 0 #3D161D` : `-16px 0 0 #2C0F14`,
    },
  }), [active]);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, zIndex: 1 }}>
      {['discover', 'explore', 'pinpoint'].map((plan, i) => (
        <Button
          key={plan}
          variant="contained"
          disableElevation
          disableRipple
          onClick={() => setActive(plan)}
          sx={{
            flex: 1,
            p: 1,
            m: 0,
            pb: '32px',
            mb: '-24px',
            borderRadius: 4,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderWidth: 4,
            borderBottomWidth: 0,
            borderStyle: 'solid',
            position: 'relative',
            transition: 'none',
            '&, &:hover': {
              borderColor: active === plan ? '#3D161D' : '#2C0F14',
              backgroundColor: active === plan ? '#3D161D' : '#2C0F14',
            },
            ...(i < 2 && {
              '&:after': commonArrowStyles(plan, 'right'),
            }),
            ...(i > 0 && {
              '&:before': commonArrowStyles(plan, 'left'),
            }),
          }}
        >
          <Typography component="h3" variant="subtitle2" textAlign="center">
            {t(`MatchmakingPrograms.${plan}.title`)}
          </Typography>
        </Button>
      ))}
    </Box>
  );
}

const PlanDetails = ({ active, handleStartDiscover, handleBookCall, t }) => {
  const planComponents = {
    discover: DiscoverPlan,
    explore: ExplorePlan,
    pinpoint: PinpointPlan,
  };

  const ActivePlanComponent = planComponents[active];
  return <ActivePlanComponent handleStartDiscover={handleStartDiscover} handleBookCall={handleBookCall} t={t} />;
};

const basePlanStyle = {
  display: 'flex',
  flexDirection: 'column',
  borderWidth: 4,
  cursor: 'pointer',
  borderStyle: 'solid',
  borderColor: '#3D161D',
  backgroundColor: '#2C0F14',
  borderRadius: 4,
  p: 2,
  zIndex: 2,
  position: 'relative',
};

const PlanPrice = ({ children }) => {
  return (
    <Chip
      size="small"
      sx={{ my: 1.5, fontSize: 13, background: '#3D161D', fontWeight: 'bold' }}
      label={children}
    />
  );
};

const PlanName = ({ children }) => {
  return (
    <Typography component="h3" variant="h3" textAlign="center">
      {children}
    </Typography>
  );
}

const PlanDescription = ({ children }) => {
  return (
    <Typography variant="body2" textAlign="center" sx={{ fontSize: { xs: '13px', sm: '15px' } }}>
      {children}
    </Typography>
  );
}

const PlanBody = ({ children }) => {
  return (
    <Box sx={{
      '& p': {
        fontSize: { xs: '13px', sm: '15px' },
        textAlign: { xs: 'center', sm: 'left' },
      },
      '& span:not(.MuiChip-label)': {
        fontSize: { xs: '15px', sm: '17px' },
        textAlign: { xs: 'center', sm: 'left' },
        display: { xs: 'block', sm: 'inline-block' },
      },
      ul: {
        my: 2,
        pl: 2,
      },
      li: {
        fontSize: { xs: '13px', sm: '15px' },
        my: 0,
        py: 0,
      },
    }}>
      <MarkdownText>
        {children}
      </MarkdownText>
    </Box>
  );
}

const PlanContinuation = ({ children }) => {
  return (
    <Box
      sx={{
        flex: 0,
        p: 2,
        borderRadius: 4,
        mt: 2,
        mx: '-4px',
        mb: '-4px',
        borderWidth: 4,
        borderStyle: 'solid',
        borderColor: '#3D161D',
      }}
    >
      <Typography variant="body2" textAlign="center" sx={{ fontSize: { xs: '13px', sm: '15px' } }}>
        {children}
      </Typography>
    </Box>
  )
}

const PlanActions = ({ children }) => {
  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: '#3D161D',
        borderRadius: 4,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        mb: 2,
        mt: '-16px',
        pt: '32px',
        zIndex: 1,
      }}
    >{children}</Box>
  )
};

const PlanActionText = ({ children }) => {
  return (
    <Typography variant="body2" textAlign="center" sx={{ fontSize: { xs: '13px', sm: '15px' } }}>
      {children}
    </Typography>
  );
}

const PlanButton = ({ children, onClick }) => {
  return (
    <Button
      fullWidth
      color="primary"
      disableElevation
      variant="contained"
      sx={{ mt: 2 }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

const PlanActionImage = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
    <Image src="/matchmakers.png" sx={{ height: 52 }} />
  </Box>
);

const PlanDisclaimer = ({ children }) => (
  <Typography variant="body2" textAlign="center" sx={{ px: 2, opacity: 0.5, fontSize: { xs: '13px', sm: '15px' } }}>
    {children}
  </Typography>
);

const DiscoverPlan = ({ handleStartDiscover, t }) => (
  <Box>
    <Box sx={{ ...basePlanStyle }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        mb: 2,
      }}>
        <PlanName>{t('MatchmakingPrograms.discover.title', 'Upptäck')}</PlanName>
        {/* <PlanPrice>{t('MatchmakingPrograms.discover.price', '3 900 SEK')}</PlanPrice> */}
        <PlanDescription>{t('MatchmakingPrograms.discover.description', 'Detta paket passar dig som är nyfiken på vår matchmaking och vill förstå dina relations-behov och hur tidigare mönster håller dig tillbaka från att hitta kärleken.')}</PlanDescription>
      </Box>
      <PlanBody>{t('MatchmakingPrograms.discover.details', `*Vad som ingår*\n\n- 2 sessioner med din matchmaker\n- Övningarna som ingår i Del 1\n\nDet sker ingen aktiv matchmaking under denna del av programmet.\n\n*Programmets längd: 1 månad*`)}</PlanBody>
      <PlanContinuation>{t('MatchmakingPrograms.discover.extra', 'Efter månad 1 kan du välja att addera matchmaking i 3 månader åt gången.*')}</PlanContinuation>
    </Box>
    <PlanActions>
      <PlanActionText>{t('MatchmakingPrograms.discover.push', 'Med detta program kan du komma igång redan idag. Efter din betalning får du din första övning samt bokningslänk för att boka in ditt första samtal med din matchmaker/coach.')}</PlanActionText>
      <PlanButton onClick={handleStartDiscover}>
        {t('MatchmakingPrograms.discover.action', 'Kom igång idag')}
      </PlanButton>
    </PlanActions>
    <PlanDisclaimer>{t('MatchmakingPrograms.discover.disclaimer', 'OBS! Vi har begränsade antal platser i våra program och med våra matchmakers. Vi accepterar enbart personer som vi verkligen tycker passar våra program och som vi tror att vi kan hjälpa.')}</PlanDisclaimer>
  </Box>
);

const ExplorePlan = ({ handleBookCall, t }) => (
  <Box>
    <Box sx={{ ...basePlanStyle }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        mb: 2,
      }}>
        <PlanName>{t('MatchmakingPrograms.explore.title', 'Utforska')}</PlanName>
        {/* <PlanPrice>{t('MatchmakingPrograms.explore.price', '21 500 SEK')}</PlanPrice> */}
        <PlanDescription>{t('MatchmakingPrograms.explore.description', 'Detta paket passar dig som vill förstå dig själv bättre, upptäcka dina relationsmönster och gå på utforskande dejter med personer som din matchmaker noggrant valt ut åt dig.')}</PlanDescription>
      </Box>
      <PlanBody>{t('MatchmakingPrograms.explore.details', '*Vad som ingår*\n\n- 4 sessioner med din matchmaker\n- Övningarna som ingår i Del 1 & 2\n- Sökningar genom tusentals profiler\n- 2 noggrant utvalda introduktioner\n- En kärleksexpert vid din sida under hela programmet\n\n*Programmets längd: 4 månader*')}</PlanBody>
      <PlanContinuation>{t('MatchmakingPrograms.explore.extra', 'Efter månad 4 kan du lägga till mer matchmaking i 3 månader åt gången.')}</PlanContinuation>
    </Box>
    <PlanActions>
      <PlanActionImage />
      <PlanActionText>{t('MatchmakingPrograms.explore.push', 'Boka ett kostnadsfritt samtal med oss så utforskar vi hur vi kan hjälpa dig.')}</PlanActionText>
      <PlanButton onClick={handleBookCall}>
        {t('MatchmakingPrograms.explore.action', 'Boka kostnadsfritt samtal')}
      </PlanButton>
    </PlanActions>
    <PlanDisclaimer>{t('MatchmakingPrograms.explore.disclaimer', 'OBS! Vi har begränsade antal platser i våra program och med våra matchmakers. Vi accepterar enbart personer som vi verkligen tycker passar våra program och som vi tror att vi kan hjälpa.')}</PlanDisclaimer>
  </Box>
);


const PinpointPlan = ({ handleBookCall, t }) => (
  <Box>
    <Box sx={{ ...basePlanStyle }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        mb: 2,
      }}>
        <PlanName>{t('MatchmakingPrograms.pinpoint.title', 'Hitta rätt')}</PlanName>
        {/* <PlanPrice>{t('MatchmakingPrograms.pinpoint.price', '38 000 SEK')}</PlanPrice> */}
        <PlanDescription>{t('MatchmakingPrograms.pinpoint.description', 'Passar dig som inte längre vill vänta på kärleken, är redo för att träffa den rätta och leva det livet du drömmer om.')}</PlanDescription>
      </Box>
      <PlanBody>{t('MatchmakingPrograms.pinpoint.details', '*Vad som ingår*\n\n- Minst 5 garanterade handplockade introduktioner\n- 7 sessioner med din matchmaker\n- Övningarna som ingår i Del 1, 2 + verktyg\n- Sökningar genom tusentals profiler\n- Minst 5 garanterade introduktioner\n- Planerade och ihopsatta dejter\n- Värdefull feedback från dina dejter\n- En kärleksexpert vid din sida under hela programmet\n\n*Programmets längd: 6 månader*')}</PlanBody>
    </Box>
    <PlanActions>
      <PlanActionImage />
      <PlanActionText>{t('MatchmakingPrograms.pinpoint.push', 'Boka ett kostnadsfritt samtal med oss så utforskar vi hur vi kan hjälpa dig.')}</PlanActionText>
      <PlanButton onClick={handleBookCall}>
        {t('MatchmakingPrograms.pinpoint.action', 'Boka kostnadsfritt samtal')}
      </PlanButton>
    </PlanActions>
    <PlanDisclaimer>{t('MatchmakingPrograms.discover.disclaimer', 'OBS! Vi har begränsade antal platser i våra program och med våra matchmakers. Vi accepterar enbart personer som vi verkligen tycker passar våra program och som vi tror att vi kan hjälpa.')}</PlanDisclaimer>
  </Box>
);

const MatchmakingPrograms = ({ duration = 3, handleBookCall, handleStartDiscover }) => {
  const { t } = useTranslation();
  const [active, setActive] = useState('pinpoint');

  return (
    <Section
      sx={{
        ...duration && {
          opacity: 0,
          animation: `${fadeIn} 500ms forwards linear`,
          animationDelay: `${duration + 1}s`,
        },
      }}
    >
      <Typography component="h3" variant="h3" textAlign="center" sx={{ mb: 4 }}>
        {t('MatchmakingPrograms.packages.title', 'Våra paket')}
      </Typography>

      <PlanSelector active={active} setActive={setActive} t={t} />

      <PlanDetails active={active} handleStartDiscover={handleStartDiscover} handleBookCall={handleBookCall} t={t} />
    </Section>
  );
}

export default MatchmakingPrograms;
