const uploadFile = (url: string, file: File, setProgress: (progress: number) => void) => new Promise<void>((resolve, reject) => {
  const xhr = new XMLHttpRequest();

  // Event listener for upload progress
  if (setProgress) {
    xhr.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        const percentCompleted = Math.round((event.loaded * 100) / event.total);
        setProgress(percentCompleted);
      }
    });
  }

  // Event listener for completion of upload
  xhr.addEventListener('load', () => {
    resolve();
  });

  // Event listener for error during upload
  xhr.addEventListener('error', () => {
    reject();
  });

  xhr.open('PUT', url);
  xhr.setRequestHeader('Content-Type', file.type);
  xhr.send(file);
});

export default uploadFile;
