import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Section from '../../components/Section';
import MatchmakingDimensions from './MatchmakingDimensions';
import { fadeIn } from './helpers';

const NetworkMatchmaker = ({ duration = 3 }) => {
  const { t } = useTranslation();
  return (
    <Section
      small
      sx={{
        textAlign: 'center',
        em: { color: 'white', fontWeight: 'bold' },
        background: 'none !important',
        opacity: 0,
        animation: `${fadeIn} 500ms forwards linear`,
        animationDelay: `${duration + 1}s`,
      }}
    >
      <Typography component="h2" variant="h2">
        {t('network_matchmaker.title', 'Detta kollar våra matchmakers på')}
      </Typography>
      <MatchmakingDimensions />
    </Section>
  );
}

export default NetworkMatchmaker;
