import { Box, Typography } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

const container = {
  background: (theme: any) => theme.palette.primary.main,
  p: 1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 2,
};

const Notice = ({ info }: { info?: string | null }) => {
  if (info) {
    return (
      <Box sx={container}>
        <InfoIcon />
        <Typography variant="caption" fontSize="small" fontStyle="normal" sx={{ ml: 1, flex: 1, color: 'white' }}>
          {info}
        </Typography>
      </Box>
    );
  }
  return null;
}

export default Notice;
