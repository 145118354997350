import { InMemoryCache } from '@apollo/client';
import cached from './cached.json';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        viewer: {
          merge(existing = [], incoming: any[]) {
            return { ...existing, ...incoming };
          },
        },
      },
    },
  },
});
// Prefill cache with data.
cache.restore(cached);

export default cache;
