import { gql } from "@apollo/client";
import UserViewerFragment from "../fragments/UserViewerFragment";

const UpdateUserPreferenceMutation = gql`
  mutation UpdateUserPreferenceMutation($fields: InputUserPreference!) {
    updateUserPreference(fields: $fields) {
      validationErrors {
        field
        message
      }
      user {
        ...UserViewerFragment
      }
    }
  }
  ${UserViewerFragment}
`;

export default UpdateUserPreferenceMutation;
