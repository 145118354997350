import createObjectId from "./createObjectId";

const getUniqueId = () => {
  let uniqueId = localStorage.getItem('uniqueId');
  if (!uniqueId) {
    uniqueId = createObjectId();
    localStorage.setItem('uniqueId', uniqueId);
  }
  return uniqueId;
}

export default getUniqueId;
