import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Section from '../../components/Section';
import { fadeIn } from './helpers';

const MatchmakingQuote = ({ duration }) => {
  const { t } = useTranslation();
  return (
    <Section
      highlight
      sx={{
        alignItems: 'center', flexDirection: 'row',
        ...duration > 0 && {
          opacity: 0,
          animation: `${fadeIn} 500ms forwards linear`,
          animationDelay: `${duration + 1}s`,
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="body2" textAlign="center" fontStyle="italic" sx={{ mb: 1 }}>
          “{t('matchmaker.quote', 'Det känns så himla förstklassigt och otroligt bra stöd. Jag var inte beredd på alla de insikter som skulle komma från övningarna, jag känner mig typ som en helt ny människa. Mina kompisar säger att jag pratar på ett sätt som jag aldrig har gjort förut. Det har överträffat allt, det är så häftigt verkligen!')}”
        </Typography>
        <Typography component="h5" variant="h5" textAlign="center">
          {t('matchmaker.quote_author', '– {{name}}, {{age}} år', { name: 'Madeleine', age: 34 })}
        </Typography>
      </Box>
    </Section>
  );
}

export default MatchmakingQuote;
