import { Box, Typography, keyframes } from '@mui/material';

const AnimatedTitle = ({ text, animate = true }: { text: string, animate: boolean }) => {
  const words = text.split(' ');

  const fadeIn = keyframes`
    to {
      opacity: 1;
    }
  `;

  return (
    <Typography
      component="h1"
      variant="h1"
      sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', mt: { xs: 3, sm: 5 }, px: 1 }}
    >
      {words.map((word, index) => (
        <Box
          key={word + index}
          component="span"
          sx={{ 
            ml: 1,
            ...animate && {
              opacity: 0,
              animation: `${fadeIn} 1s forwards`, 
              animationDelay: `${(0.1 * index) + (index > 0 ? 1 : 0) * 2 }s`
            },
          }}
        >
          {word}
        </Box>
      ))}
    </Typography>
  );
};

export default AnimatedTitle;
