import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Block from '../../components/Block';
import ProfileOption from '../../components/ProfileOption';
import MarkdownText from '../../components/MarkdownText';

const UserBlock = ({ title, description, labelType, label, user }) => {
  return (
    <Block sx={{ p: { xs: 2, sm: 3 } }}>
      {title && (
        <Typography component="h3" variant="h3">
          {title}
        </Typography>
      )}
      <MarkdownText>
        {description}
      </MarkdownText>
      {user?.firstName && user?.lastName && (
        <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <ProfileOption
            label={[user.firstName, user.lastName].join(' ')}
            labelType={labelType}
            description={label}
            picture={user.picture}
          />
        </Box>
      )}
    </Block>
  );
};

export default UserBlock;
