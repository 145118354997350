import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { setAuthToken } from "../client";
import ViewerQuery from '../queries/ViewerQuery';
import { identify, updateTrackingProfile } from "../../helpers/mixpanel";

const useAuthenticated = (query = ViewerQuery) => {
  const { data } = useQuery(query);
  const user = data?.viewer?.user;
  const token = data?.viewer?.token;

  useEffect(() => {
    if (token) {
      // Update auth token when it changes.
      setAuthToken(token);
    }
    if (user?._id) {
      identify(user._id);
      updateTrackingProfile(user);
    }
  }, [token]);

  return { user };
};

export default useAuthenticated;
