import { Box } from '@mui/material';
import Video from './Video';
import MarkdownText from './MarkdownText';

type FormHeaderProps = {
  description: string,
  videoTitle?: string,
  videoSrc?: {
    webm?: string,
    mp4?: string,
    caption?: string,
  },
};

const FormHeader = ({
  description,
  videoTitle,
  videoSrc,
}: FormHeaderProps) => {
  return (
    <Box sx={{ px: 2, pb: 0.125 }}>
      <Box sx={{
        mt: 1,
        mb: 2,
        '@media screen and (min-width: 640px)': {
          mt: 3,
          mb: 4,
          '& p': {
            fontSize: '20px',
          },
        },
      }}>
        <MarkdownText>
          {description}
        </MarkdownText>
      </Box>
      <Video
        title={videoTitle}
        src={videoSrc}
      />
    </Box>
  );
}

export default FormHeader;
