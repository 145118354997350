import { useTranslation } from 'react-i18next';
import Body from '../components/Body';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Typography } from '@mui/material';
import MarkdownText from '../components/MarkdownText';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { useCallback, useRef, useState } from 'react';
import Image from '../components/Image';
import LoginDialog from '../forms/LoginDialog';
import dayjs from 'dayjs';
import useLocalStorage from '../helpers/useLocalStorage';
import i18n from '../data/i18n';
import { useAuth } from '../data/auth/token';
import Section from '../components/Section';
import LoggedInDialog from '../forms/LoggedInDialog';
import { track } from '../helpers/mixpanel';

const question = {
  borderBottom: 'solid 1px #fff2',
  my: 1,

  p: {
    fontStyle: 'italic',
  }
};

const answer = {
  transition: 'all 300ms ease 0s',
  overflow: 'hidden',
  boxSizing: 'border-box',
};

const button = {
  px: 0,
  py: 2,
  fontStyle: 'normal',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  borderRadius: 0,
  textAlign: 'left',

  '.MuiButton-endIcon': {
    flex: 1,
    justifyContent: 'flex-end',
    opacity: 0.5,
  },
};

type QuestionProps = {
  item: {
    title: string;
    description: string;
  };
};

const Question = ({ item }: QuestionProps) => {
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>();

  const handleClick = useCallback(() => {
    if (ref.current?.offsetHeight) {
      setHeight(ref.current.offsetHeight);
    }
    setOpen(state => !state);
  }, []);

  return (
    <Box sx={question}>
      <Typography
        variant="body1"
        sx={button}
        fullWidth
        component={Button}
        onClick={handleClick}
        endIcon={(
          <KeyboardArrowDownIcon sx={{
            transition: 'transform 300ms ease 0s',
            transform: open? 'rotate(180deg)': '',
          }} />
        )}
      >{item.title}</Typography>
      <Box sx={[answer, { maxHeight: open ? height : 0 }]}>
        <Box ref={ref} sx={{ pb: 2 }}>
          <MarkdownText>{item.description}</MarkdownText>
        </Box>
      </Box>
    </Box>
  )
}

function formatToTodayOrTomorrow(date: string) {
  const d = dayjs(date);
  if (d.isToday()) {
    return i18n.t('today', 'idag') + ' kl ' + d.format('HH:mm');
  } else if (d.isTomorrow()) {
    return i18n.t('tomorrow', 'imorgon');
  } else {
    return d.format('dddd Do MMMM');
  }
}

const agents = {
  elin: {
    name: 'Elin Ribjer',
    picture: '/elin.avif',
  },
  christina: {
    name: 'Christina Olnils',
    picture: '/christina.png',
  },
  lemarc: {
    name: 'Lemarc Thomas',
    picture: '/lemarc.png',
  },
  anna: {
    name: 'Anna Marin',
    picture: '/anna.png',
  },
};

export default function Membership() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const name = searchParams.get('name');
  const utmMedium = searchParams.get('utm_medium');
  const auth = useAuth();
  const [showLogin, setShowLogin] = useState(false);
  const [showLoggedIn, setShowLoggedIn] = useState(false);
  const [expirationDate] = useLocalStorage<string>('expirationDate', dayjs().add(3, 'day').endOf('day').toISOString(), true);

  const matchmaker = searchParams.get('matchmaker') || 'christina';
  const agent = agents[matchmaker as keyof typeof agents] || agents.elin;

  const pageTitle = utmMedium === 'cpc'
    ? t('membership.ad_page_title', 'Gå med i vårt matchmaking-nätverk')
    : t('membership.page_title', 'Din inbjudan till Relate Matchmaking');
  const title = utmMedium === 'cpc'
    ? t('membership.ad_plain_title', 'Gå med i vårt matchmaking-nätverk helt kostnadsfritt ♥️')
    : (name
      ? t('membership.title', '{{name}}, jag vill gärna ha med dig i vårt nätverk ♥️', { name })
      : t('membership.plain_title', 'Du är inbjuden att gå med i vårt nätverk helt kostnadsfritt ♥️')
    );

  const description = t('membership.description', 'Som medlem i vårt matchmaking-nätverk har du chans att matchas och introduceras för någon av våra klienter.', {
    date: formatToTodayOrTomorrow(expirationDate),
  });

  const benefits = [
    {
      icon: '/hand.svg',
      description: t('membership_benefit_1.description', 'Chans att matchas och introduceras till rätt personer för just dig'),
    },
    {
      icon: '/heart.svg',
      description: t('membership_benefit_2.description', 'Vid en introduktion planerar vi dejten åt er så ni kan fokusera på att lära känna varandra'),
    },
    {
      icon: '/person.svg',
      description: t('membership_benefit_3.description', 'En diskret profil som endast våra matchmakers har tillgång till'),
    },
    {
      icon: '/flash.svg',
      description: t('membership_benefit_4.description', 'Slipp allt svajpande och chattande, gå direkt på bra dejter istället'),
    },
  ];

  const process = [
    {
      icon: '/one.svg',
      title: t('membership_process_1.title', 'Ansök om medlemskap'),
      description: t('membership_process_1.description', 'Ansök om ett kostnadsfritt medlemskap idag. Din ansökan granskas omedelbart och kan oftast godkännas automatiskt.'),
    },
    {
      icon: '/two.svg',
      title: t('membership_process_2.title', 'Svara på våra frågor'),
      description: t('membership_process_2.description', 'När din ansökan har blivit godkänd svarar du på frågor om vem du är och vem du längtar efter. Det är bästa sättet för våra matchmakers att veta vem som är rätt person för just dig.'),
    },
    {
      icon: '/three.svg',
      title: t('membership_process_3.title', 'Bli introducerad'),
      description: t('membership_process_3.description', 'När du har skapat din profil och verifierat dig är du redo att bli introducerad för andra i nätverket. Våra matchmakers hör av sig om en av våra klienter är en riktigt bra match och vi vill ta dig till nästa steg. '),
    },
  ];

  const questions = [
    {
      title: t('membership_question_1.title', 'Vad är Relate Matchmaking?'),
      description: t('membership_question_1.description', 'Äntligen är personlig matchmaking tillgänglig för alla! På Relate Matchmaking finns kärlek- och relationsexperter som, genom en noggrann matchmakingprocess, hjälper människor att hitta meningsfulla och varaktiga relationer. Med Sveriges största nätverk av singlar som är redo för en seriös relation så hjälper vi dig att hitta bland alla fiskar i havet.'),
    },
    {
      title: t('membership_question_2.title', 'Varför matchmaking?'),
      description: t('membership_question_2.description', 'Med matchmaking får du som klient en personlig matchmaker som ger dig individanpassade resurser och verktyg. Din matchmaker introducerar personligen för matchningar som har det du söker – baserat på dina behov och preferenser.\n\nSom medlem får du ett mer hälsosamt dejtande utan svajping och du slipper slösad tid med personer som egentligen inte passar dig. Istället introducerar vi bara dig till klienter när och om vi ser att en genuin kompatibilitet finns.\n\nVilken väg du än väljer kommer du bort från dejtingapps-utbrändheten och till ett tryggare, mänskligare och mer effektivt sätt att hitta kärleken på.'),
    },
    {
      title: t('membership_question_3.title', 'Kostar det att vara medlem?'),
      description: t('membership_question_3.description', 'Nej, medlemskap i vårt nätverk är kostnadsfritt.'),
    },
    {
      title: t('membership_question_4.title', 'Vad är nätverksmedlem och klient?'),
      description: t('membership_question_4.description', 'Som medlem i vårt nätverk har du chans att matchas och introduceras för någon av våra klienter. Dock är du inte garanterad några matchningar eller introduktioner.\n\nSom klient hos oss går du igenom en noggrann matchmakingprocess tillsammans med din egna personliga matchmaker. Du får bland annat garanterade introduktioner med utvalda matchningar samt tips, stöd och verktyg av din matchmaker.'),
    },
    {
      title: t('membership_question_5.title', 'Hur hanterar ni min information?'),
      description: t('membership_question_5.description', 'När du skickar in dina svar på formuläret till oss så blir du medlem i Relates matchningsnätverk. Vi tar dataskydd, diskretion och sekretess på största allvar. Vi använder din data endast för matchningsändamål enligt våra användarvillkor och integritetspolicy och säljer aldrig vidare till tredjeparter.'),
    },
    {
      title: t('membership_question_6.title', 'Hur kan jag kontakta Relate?'),
      description: t('membership_question_6.description', 'Du är välkommen att skriva dina frågor och tankar till Relate på [hello@relate-matchmaking.com](mailto:hello@relate-matchmaking.com).'),
    }
  ];

  const handleClick = useCallback(() => {
    if (!auth?.user) {
      // Open login modal.
      setShowLogin(true);
    } else if (auth?.user?.appliedAt) {
      track('already_applied');
      navigate('/');
    } else if (auth?.user && !auth?.user?.firstName && !auth?.user?.lastName) {
      // Skip profile selection if none exist already.
      navigate('/application?service=member');
    } else {
      // Start profile form if logged in.
      setShowLoggedIn(true);
    }
  }, [auth]);

  return (
    <Body>
      <Helmet>
        <title>Relate | {pageTitle}</title>
      </Helmet>
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <Image src="/logo.png" sx={{ width: 65 }} />
      </Box>
      <Box>
        <Section
          sx={{
            textAlign: 'center',
            em: { color: 'white', fontWeight: 'bold' },
          }}
        >
          <Box sx={{ mb: 5 }}>
            <Typography component="h1" variant="h1" sx={{ mb: 4 }}>{title}</Typography>
            <Typography variant="body1">{description}</Typography>
          </Box>
          {name ? (
            <>
              <Button
                onClick={handleClick}
                fullWidth
                size="large"
                variant="contained"
                sx={{
                  mb: 1,
                  backgroundAttachment: 'fixed',
                  backgroundImage:  'repeating-radial-gradient(circle at 100px -400px, #76451F66 0, transparent 800px), repeating-linear-gradient(#be8a6144, #be8a6122)',
                }}
              >
                  {t('invitation.action', 'Svara på frågorna idag')}
              </Button>
              <Typography variant="caption" component="div" textAlign="center" sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <WatchLaterIcon sx={{ fontSize: 17, mr: '4px' }} />
                {t('invitation.help', 'Tar ca 20 minuter')}
              </Typography>
            </>
          ) : (
            <>
              <Button
                onClick={handleClick}
                fullWidth
                size="large"
                variant="contained"
                sx={{
                  mb: 1,
                  backgroundAttachment: 'fixed',
                  backgroundImage:  'repeating-radial-gradient(circle at 100px -400px, #76451F66 0, transparent 800px), repeating-linear-gradient(#be8a6144, #be8a6122)',
                }}
              >
                  {t('membership.action', 'Gå med i vårt nätverk')}
              </Button>
              <Typography variant="caption" component="div" textAlign="center" sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                {t('membership.help', 'Medlemskap i vårt nätverk är kostnadsfritt.')}
              </Typography>
            </>
          )}
          {agent && name && (
            <Box sx={{
              p: 2,
              py: 3,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <Image
                src={agent?.picture}
                sx={{ width: 48, height: 48, borderRadius: '50%', flex: 0, mr: 1.5 }}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant="h5" sx={{ mb: 0 }}>{agent?.name}</Typography>
                <Typography variant="caption">
                  {t('matchmaker_at_relate', 'Matchmaker på Relate')}
                </Typography>
              </Box>
            </Box>
          )}
        </Section>
        <Section highlight sx={{ flexDirection: 'row' }}>
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography component="h2" variant="h2" textAlign="center">
              {t('membership.benefits_title', 'Som medlem får du')}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              {benefits.map((item) => (
                <Box key={item.icon} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', my: 2 }}>
                  <Box
                    component="img"
                    src={item.icon}
                    sx={{
                      width: 52, height: 52, minWidth: 52, mr: 2,
                      borderRadius: 26,
                      backgroundAttachment: 'fixed',
                      backgroundImage:  'repeating-radial-gradient(circle at 0 0, #230e11 0, transparent 200px), repeating-linear-gradient(#4a362b88, #4a362b44)',
                    }}
                  />
                  <Typography variant="body2" sx={{ flex: 1 }}>{item.description}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              width: '40%',
              background: 'url(/membership.png)',
              backgroundPosition: 'left center',
              backgroundSize: 'auto 100%',
              ml: 6,
              mr: -6,
              mt: -6,
              mb: -6,
              borderTopRightRadius: '32px',
              borderBottomRightRadius: '32px',
              display: 'none',
              '@media screen and (min-width: 720px)': { display: 'flex' },
            }}
          />
        </Section>
        <Section>
          <Typography component="h2" variant="h2" textAlign="center">
            {t('membership.process_title', 'Så går det till')}
          </Typography>
          <Typography variant="body2" textAlign="center">
            {t('membership.process_description', 'Detta är hur det går till för dig som medlem i nätverket:')}
          </Typography>
          <Box>
            {process.map((item) => (
              <Box key={item.icon} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 6 }}>
                <Box
                  component="img"
                  src={item.icon}
                  sx={{
                    width: 52, height: 52, minWidth: 52, mb: 2,
                    borderRadius: 26,
                    backgroundAttachment: 'fixed',
                    backgroundImage:  'repeating-radial-gradient(circle at 0 0, #230e11 0, transparent 100px), repeating-linear-gradient(#4a362b88, #4a362b44)',
                  }}
                />
                <Box sx={{ display: 'flex', gap: 2, flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography component="h3" variant="h3">{item.title}</Typography>
                  <Typography variant="body2" textAlign="center">{item.description}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Section>
        <Section highlight sx={{ alignItems: 'center', flexDirection: 'row' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body2" textAlign="center" fontStyle="italic" sx={{ mb: 1 }}>
              {t('membership.quote', 'Mycket roligare att bli kontaktad av en matchmaker och personligen bli inbjuden till en dejt, än att bara få en notifikation från en app. Det kändes verkligen som att man hittade så många stunder där vi båda svarade “Så tänker jag också!” Här stämde så många saker redan från början kändes det som.')}
            </Typography>
            <Typography component="h5" variant="h5" textAlign="center">
              {t('membership.quote_author', '– Medlem i nätverket')}
            </Typography>
          </Box>
          <Box 
            component="img" 
            src="/erik.png" 
            sx={{
              width: 192, height: 192, ml: 4,
              borderRadius: 96,
              display: 'none',
              '@media screen and (min-width: 720px)': { display: 'flex' },
            }}
          />
        </Section>
        <Section>
          {name ? (
            <Box sx={{ pb: 4, borderBottom: 'solid 1px #fff2' }}>
              <Box sx={{ mb: 3, textAlign: 'center' }}>
                <Typography component="h2" variant="h2" textAlign="center">
                  {t('invitation.selling_title', 'Svara på frågorna idag')}
                </Typography>
                <MarkdownText>
                  {t('invitation.selling_description', 'Vi söker kontinuerligt efter matchningar för våra klienter, svara på frågorna och gå med i vårt nätverk för att få möjligheten att bli introducerad.\n\nDet kostar inget och du slipper dejtingappar - du behöver bara svara på vår matchmakers frågor.')}
                </MarkdownText>
              </Box>
              <Button
                onClick={handleClick}
                fullWidth
                size="large"
                variant="contained"
                sx={{
                  mb: 1,
                  backgroundAttachment: 'fixed',
                  backgroundImage:  'repeating-radial-gradient(circle at 10px 0, #76451F66 0, transparent 600px), repeating-linear-gradient(#be8a6144, #be8a6122)',
                }}
              >
                {t('invitation.action')}
              </Button>
              <Typography variant="caption" component="div" textAlign="center" sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                {t('membership.help', 'Medlemskap i vårt nätverk är kostnadsfritt.')}
              </Typography>
            </Box>
          ) : (
            <Box sx={{ pb: 4, borderBottom: 'solid 1px #fff2' }}>
              <Box sx={{ mb: 3, textAlign: 'center' }}>
                <Typography component="h2" variant="h2" textAlign="center">
                  {t('membership.selling_title', 'Gå med idag')}
                </Typography>
                <MarkdownText>
                  {t('membership.selling_description', 'Vi söker kontinuerligt efter matchningar för våra klienter, gå med i vårt nätverk för att få möjligheten att bli introducerad.\n\nDet kostar inget och du slipper dejtingappar - du behöver bara svara på vår matchmakers frågor.')}
                </MarkdownText>
              </Box>
              <Button
                onClick={handleClick}
                fullWidth
                size="large"
                variant="contained"
                sx={{
                  mb: 1,
                  backgroundAttachment: 'fixed',
                  backgroundImage:  'repeating-radial-gradient(circle at 10px 0, #76451F66 0, transparent 600px), repeating-linear-gradient(#be8a6144, #be8a6122)',
                }}
              >
                {t('membership.action')}
              </Button>
              <Typography variant="caption" component="div" textAlign="center" sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <WatchLaterIcon sx={{ fontSize: 17, mr: '4px' }} />
                {t('membership.help_time', 'Det tar cirka 5 minuter.')}
              </Typography>
            </Box>
          )}
          <Box sx={{ mt: 4 }}>
            <Box sx={{ pb: 3 }}>
              <Typography component="h2" variant="h2" textAlign="center">
                {t('membership.matchmaking_title', 'Vill du hellre ha en egen personlig matchmaker?')}
              </Typography>
              <Typography variant="body2" textAlign="center">
                {t('membership.matchmaking_description', 'Med din egna personlig matchmaker går du igenom en noggrann matchmakingprocess där du bland annat får garanterade introduktioner med utvalda matchningar samt tips, stöd och verktyg av din matchmaker.')}
              </Typography>
            </Box>
            <Button
              component={Link}
              to={'/matchmaker' + (name ? `?name=${name}`: '')}
              fullWidth
              size="large"
              variant="outlined"
              sx={{
                mb: 1,
                backgroundAttachment: 'fixed',
                backgroundImage:  'repeating-radial-gradient(circle at 0 0, #230e11 0, transparent 600px), repeating-linear-gradient(#4a362b88, #4a362b44)',
              }}
            >
              {t('membership.matchmaking_action', 'Läs mer om vårt matchmakingprogam')}
            </Button>
          </Box>
        </Section>
        <Section highlight>
          <Typography component="h2" variant="h2">
            {t('membership.questions_title', 'Så funkar det')}
          </Typography>
          <Box>
            {questions.map((item) => (
              <Question key={item.title} item={item} />
            ))}
          </Box>
        </Section>
        <Section sx={{ alignItems: 'center' }}>
          <Image src="/logo.png" sx={{ width: 84 }} />
          <Typography variant="caption">
            {t('slogan')}
          </Typography>
        </Section>
        <Section />
        {showLogin && (
          <LoginDialog
            open
            handleClose={() => setShowLogin(false)}
            onLoggedIn={(user) => {
              if (user?.appliedAt) {
                track('already_applied');
                navigate('/');
              } else if (user && !user?.firstName && !user?.lastName) {
                // Skip profile selection if none exist already.
                navigate('/application?service=member');
              } else {
                setShowLoggedIn(true);
              }
            }}
          />
        )}
        {showLoggedIn && (
          <LoggedInDialog
            open
            handleClose={() => setShowLoggedIn(false)}
            nextPath="/application?service=member"
          />
        )}
      </Box>
    </Body>
  );
}
