import { useTranslation } from 'react-i18next';
import Body from '../components/Body';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Typography } from '@mui/material';
import MarkdownText from '../components/MarkdownText';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import Block from '../components/Block';
import { useCallback } from 'react';
import Image from '../components/Image';
import { useAuth } from '../data/auth/token';

const step = {
  my: 2,
  position: 'relative',

  '&:not(:last-of-type):after': {
    content: '""',
    display: 'block',
    background: '#8E603C',
    position: 'absolute',
    width: 3,
    height: 48,
    top: 25,
    left: 7,
  }
};

const button = {
  px: 0,
  my: -2,
  fontStyle: 'normal',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  borderRadius: 0,
  textAlign: 'left',
};

type StepProps = {
  item: {
    title: string;
    complete: boolean;
    next?: boolean;
    upcoming?: boolean;
  };
};

const Step = ({ item }: StepProps) => {
  return (
    <Box sx={[step, {
      ...item?.next && {
        '&:not(:last-of-type):after': {
          background: '#5C4044',
        },
      },
    }]}>
      <Typography
        variant="body1"
        fullWidth
        component={Button}
        sx={[button, {
          ...item?.next ? {
            color: 'white !important',
          } : {
            color: '#89696D !important',
          },
        }]}
        startIcon={(
          <Box sx={{
            width: 24,
            height: 24,
            borderRadius: 12,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...item.complete ? {
              background: '#8E603C',
            } : {
              background: '#38191E',
              ...item?.upcoming ? {
                boxShadow: 'inset 0 0 0 3px #5C4044',
              } : {
                boxShadow: 'inset 0 0 0 3px #8E603C',
              }
            },
          }}>
            {item.complete && <CheckIcon sx={{ fontSize: 12, color: 'white', stroke: 'white', strokeWidth: 2 }} />}
          </Box>
        )}
      >{item.title}</Typography>
    </Box>
  )
}

export default function Complete() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const matchId = searchParams.get('match');
  const auth = useAuth();

  const name = auth?.user?.firstName;

  const pageTitle = t('complete.page_title', 'Välkommen till Relate Matchmaking');
  const title = t('complete.title', 'Tack {{name}}!', { name });
  const description = t('invitation.description', 'Jag ser verkligen fram emot att ta del av dina svar!\n\nInnan jag kan introducera dig till någon i vårt nätverk så behöver du verifiera dig genom att spela in en kort video.\n\nOm allt känns rätt kommer jag ringa eller skicka ett SMS när vi är redo för att introducera er för varandra.');

  const handleClick = useCallback(() => {
    if (matchId) {
      navigate('/verify?match=' + matchId);
    } else {
      navigate('/verify');
    }
  }, [auth]);
  const handleSkip = useCallback(() => {
    if (matchId) {
      navigate('/?match=' + matchId);
    } else {
      navigate('/');
    }
  }, [auth]);

  const steps = [{
    title: t('complete.step_1.title', 'Svara på mina frågor'),
    complete: true,
  }, {
    title: t('complete.step_2.title', 'Verifiera ditt konto'),
    complete: false,
    next: true,
  }, {
    title: t('complete.step_3.title', 'Redo för att introduceras'),
    complete: false,
    upcoming: true,
  }]

  return (
    <Body>
      <Helmet>
        <title>Relate | {pageTitle}</title>
      </Helmet>
      <Box sx={{ p: 2, pt: 4, textAlign: 'center', em: { color: 'white', fontWeight: 'bold' } }}>
        <Box sx={{ mb: 3 }}>
          <Typography component="h1" variant="h1" sx={{ mb: 2 }}>{title}</Typography>
          <MarkdownText>{description}</MarkdownText>
        </Box>
        <Box sx={{
          p: 2,
          py: 3,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Image
            src="/elin.avif"
            sx={{ width: 48, height: 48, borderRadius: '50%', flex: 0, mr: 1.5 }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography component="h4" variant="h4" sx={{ mb: 0 }}>Elin Ribjer</Typography>
            <Typography component="h5" variant="h5">
              {t('matchmaker_at_relate', 'Matchmaker på Relate')}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Block>
        <Typography component="h2" variant="h2">
          {t('complete.verification_title', 'Nästan där!')}
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          {t('complete.verification_description', 'Verifiering tar endast 2 minuter.')}
        </Typography>
        <Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: 2,
          }}>
            <Box sx={{
              display: 'flex',
              flex: 0,
              whiteSpace: 'nowrap',
              flexDirection: 'column',
            }}>
              {steps.map((item) => (
                <Step key={item.title} item={item} />
              ))}
            </Box>
          </Box>
          <Button
            onClick={handleClick}
            fullWidth
            size="large"
            variant="contained"
            sx={{ mb: 1 }}
          >
            {t('complete.action', 'Verifiera ditt konto')}
          </Button>
          <Button
            onClick={handleSkip}
            fullWidth
            size="large"
            variant="text"
          >
            {t('complete.skip', 'Verifiera senare')}
          </Button>
        </Box>
      </Block>
      <Box sx={{ display: 'flex', alignItems: 'center', px: 2, py: 4, mb: 2, mt: 4, flexDirection: 'column', borderTop: 'solid 1px #38191E' }}>
        <Image src="/logo.png" sx={{ width: 84 }} />
        <Typography variant="caption">
          {t('slogan')}
        </Typography>
      </Box>
    </Body>
  );
}
