import { useTranslation } from 'react-i18next';
import Body from '../components/Body';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Typography } from '@mui/material';
import MarkdownText from '../components/MarkdownText';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { useCallback, useRef, useState } from 'react';
import Image from '../components/Image';
import LoginDialog from '../forms/LoginDialog';
import dayjs from 'dayjs';
import useLocalStorage from '../helpers/useLocalStorage';
import i18n from '../data/i18n';
import { useAuth } from '../data/auth/token';
import Section from '../components/Section';
import LoggedInDialog from '../forms/LoggedInDialog';
import { track } from '../helpers/mixpanel';
import Video from '../components/Video';
import client from '../data/client';
import UpdateUserApplicationMutation from '../data/mutations/UpdateUserApplicationMutation';

const question = {
  borderBottom: 'solid 1px #fff2',
  my: 1,

  p: {
    fontStyle: 'italic',
  }
};

const answer = {
  transition: 'all 300ms ease 0s',
  overflow: 'hidden',
  boxSizing: 'border-box',
};

const button = {
  px: 0,
  py: 2,
  fontStyle: 'normal',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  borderRadius: 0,
  textAlign: 'left',

  '.MuiButton-endIcon': {
    flex: 1,
    justifyContent: 'flex-end',
    opacity: 0.5,
  },
};

type QuestionProps = {
  item: {
    title: string;
    description: string;
  };
};

const Question = ({ item }: QuestionProps) => {
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>();

  const handleClick = useCallback(() => {
    if (ref.current?.offsetHeight) {
      setHeight(ref.current.offsetHeight);
    }
    setOpen(state => !state);
  }, []);

  return (
    <Box sx={question}>
      <Typography
        variant="body1"
        sx={button}
        fullWidth
        component={Button}
        onClick={handleClick}
        endIcon={(
          <KeyboardArrowDownIcon sx={{
            transition: 'transform 300ms ease 0s',
            transform: open? 'rotate(180deg)': '',
          }} />
        )}
      >{item.title}</Typography>
      <Box sx={[answer, { maxHeight: open ? height : 0 }]}>
        <Box ref={ref} sx={{ pb: 2 }}>
          <MarkdownText>{item.description}</MarkdownText>
        </Box>
      </Box>
    </Box>
  )
}

function formatToTodayOrTomorrow(date: string) {
  const d = dayjs(date);
  if (d.isToday()) {
    return i18n.t('today', 'idag') + ' kl ' + d.format('HH:mm');
  } else if (d.isTomorrow()) {
    return i18n.t('tomorrow', 'imorgon');
  } else {
    return d.format('dddd Do MMMM');
  }
}

export default function Matchmaker() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const name = searchParams.get('name');
  const auth = useAuth();
  const [showLogin, setShowLogin] = useState(false);
  const [showLoggedIn, setShowLoggedIn] = useState(false);
  const [expirationDate] = useLocalStorage<string>('expirationDate', dayjs().add(3, 'day').endOf('day').toISOString(), true);
  const isApplied = Boolean(auth?.user?.appliedAt);

  const pageTitle = t('matchmaker.page_title', 'Din inbjudan till Relate Matchmaking');
  const title = name ? t('matchmaker.title', '{{name}}, låt oss introducera dig till din drömpartner ♥️', {
    name,
  }) : t('matchmaker.plain_title', 'Låt oss introducera dig till din drömpartner ♥️');
  const description = t('matchmaker.description', 'Hos oss slipper du svajpa och chatta – låt istället våra matchmakers göra jobbet åt dig.', {
    date: formatToTodayOrTomorrow(expirationDate),
  });

  const benefits = [
    {
      icon: '/hand.svg',
      description: t('matchmaker_benefit_1.description', 'Minst 5 garanterade handplockade introduktioner'),
    },
    {
      icon: '/person.svg',
      description: t('matchmaker_benefit_2.description', '7 coachande videosamtal (1 per månad)'),
    },
    {
      icon: '/message.svg',
      description: t('matchmaker_benefit_3.description', 'Kontinuerlig personlig kontakt med din matchmaker'),
    },
    {
      icon: '/heart.svg',
      description: t('matchmaker_benefit_4.description', 'Verktyg & övningar för att lära känna dig själv och vem som passar just dig'),
    },
    {
      icon: '/flash.svg',
      description: t('matchmaker_benefit_5.description', 'Tips inför dina dejter, och feedback från dem efteråt'),
    },
    {
      icon: '/search.svg',
      description: t('matchmaker_benefit_6.description', 'En matchmaker som går igenom tusentals personer för att hitta rätt för dig'),
    },
  ];

  const process = [
    {
      icon: '/one.svg',
      title: t('matchmaker_process_1.title', 'Upptäck'),
      description: t('matchmaker_process_1.description', 'Under första delen av programmet djupdyker vi in i några av matchmaking-världens bästa verktyg och övningar. Tillsammans utforskar vi dina djupaste relationsbehov och får syn på vad som håller dig tillbaka från att hitta kärleken.\n\nReflektionerna och insikterna som kommer ur denna process är otroligt kraftfulla. Det är så vi förbereder dig för kärlek. Ett sätt att bearbeta det som varit, skapa mening, förstå oss själva och vad du behöver.\n\nDenna del lägger grunden för din framtida kärlek.'),
    },
    {
      icon: '/two.svg',
      title: t('matchmaker_process_2.title', 'Utforska'),
      description: t('matchmaker_process_2.description', 'I andra delen skickas du ut på utforskande dejter! Din matchmaker står redo att handplocka minst 2 spännande personer och planerar in minnesvärda dejter år er.\n\nVarje dejt blir en möjlighet att lära känna dig själv ännu bättre och definiera vad du verkligen söker i en partner. Feedback från dessa dejter blir en värdefull byggsten, där du och din matchmaker skapar en tydlig bild av vem din perfekta match är.'),
    },
    {
      icon: '/three.svg',
      title: t('matchmaker_process_3.title', 'Hitta rätt'),
      description: t('matchmaker_process_3.description', 'Efter att ha fördjupat dig i våra fantastiska övningar & verktyg, utforskat spännande dejter med utvalda personer och samlat på dig nya insikter är det dags för den sista delen av programmet. Här zoomar vi in närmare på vem det är du längtar efter – och att hitta rätt.\n\nDin matchmaker gräver fram dina allra mest kompatibla matchningar och ser till att du har det du behöver för att nå din drömrelation.'),
    },
  ];

  const questions = [
    {
      title: t('matchmaker_question_1.title', 'Vad är Relate Matchmaking?'),
      description: t('matchmaker_question_1.description', 'Äntligen är personlig matchmaking tillgänglig för alla! På Relate Matchmaking finns kärlek- och relationsexperter som, genom en noggrann matchmakingprocess, hjälper människor att hitta meningsfulla och varaktiga relationer. Med Sveriges största nätverk av singlar som är redo för en seriös relation så hjälper vi dig att hitta bland alla fiskar i havet.'),
    },
    {
      title: t('matchmaker_question_2.title', 'Varför matchmaking?'),
      description: t('matchmaker_question_2.description', 'Med matchmaking får du som klient en personlig matchmaker som ger dig individanpassade resurser och verktyg. Din matchmaker introducerar personligen för matchningar som har det du söker – baserat på dina behov och preferenser.\n\nSom medlem får du ett mer hälsosamt dejtande utan svajping och du slipper slösad tid med personer som egentligen inte passar dig. Istället introducerar vi bara dig till klienter när och om vi ser att en genuin kompatibilitet finns.\n\nVilken väg du än väljer kommer du bort från dejtingapps-utbrändheten och till ett tryggare, mänskligare och mer effektivt sätt att hitta kärleken på.'),
    },
    {
      title: t('matchmaker_question_4.title', 'Vad är nätverksmedlem och klient?'),
      description: t('matchmaker_question_4.description', 'Som klient hos oss går du igenom en noggrann matchmakingprocess tillsammans med din egna personliga matchmaker. Du får bland annat garanterade introduktioner med utvalda matchningar samt tips, stöd och verktyg av din matchmaker.\n\nSom medlem i vårt nätverk har du chans att matchas och introduceras för någon av våra klienter. Dock är du inte garanterad några matchningar eller introduktioner.'),
    },
    {
      title: t('matchmaker_question_5.title', 'Hur hanterar ni min information?'),
      description: t('matchmaker_question_5.description', 'När du skickar in dina svar på formuläret till oss så blir du medlem i Relates matchningsnätverk. Vi tar dataskydd, diskretion och sekretess på största allvar. Vi använder din data endast för matchningsändamål enligt våra användarvillkor och integritetspolicy och säljer aldrig vidare till tredjeparter.'),
    },
    {
      title: t('matchmaker_question_6.title', 'Hur kan jag kontakta Relate?'),
      description: t('matchmaker_question_6.description', 'Du är välkommen att skriva dina frågor och tankar till Relate på [hello@relate-matchmaking.com](mailto:hello@relate-matchmaking.com).'),
    }
  ];

  const handleClick = useCallback(() => {
    if (!auth?.user) {
      // Open login modal.
      setShowLogin(true);
    } else if (auth?.user?.appliedAt) {
      track('already_applied');
      navigate('/');
    } else if (auth?.user && !auth?.user?.firstName && !auth?.user?.lastName) {
      // Skip profile selection if none exist already.
      navigate('/application?service=matchmaker');
    } else {
      // Start profile form if logged in.
      setShowLoggedIn(true);
    }
  }, [auth]);

  return (
    <Body>
      <Helmet>
        <title>Relate | {pageTitle}</title>
      </Helmet>
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <Image src="/logo.png" sx={{ width: 65 }} />
      </Box>
      <Box>
        <Section
          sx={{
            textAlign: 'center', em: { color: 'white', fontWeight: 'bold' },
          }}
        >
          <Box sx={{ mb: 3, pb: 2 }}>
            <Typography component="h1" variant="h1" sx={{ mb: 4 }}>{title}</Typography>
            <Typography variant="body1">{description}</Typography>
          </Box>
          <Video
            title={t('matchmaker.video_title', 'Elin berättar om vår matchmaking')}
            src={{
              webm: '/matchmaker.webm',
              mp4: '/matchmaker.mp4',
              caption: '/matchmaker.vtt',
            }}
          />
          <Box sx={{ my: 4 }}>
            <MarkdownText>
              {t('matchmaker.body', 'Tillsammans med en av världens mest framstående matchmaking-agenturer, Lemarc Thomas, har vi tagit fram ett 6-månaders program för att hjälpa dig hitta och bygga relationen du drömmer om.\n\nMed din personliga matchmaker får du skräddarsydda dejter med handplockade matchningar, och samtidigt den självkännedom, kunskap och feedback som behövs för att starta och bygga en hållbar och meningsfull relation.\n\nVi gör det lätt att träffa rätt.')}
            </MarkdownText>
          </Box>
          <Button
            onClick={handleClick}
            fullWidth
            size="large"
            variant="contained"
            sx={{
              mb: 1,
              backgroundAttachment: 'fixed',
              backgroundImage:  'repeating-radial-gradient(circle at 400px -100px, #76451F66 0, transparent 800px), repeating-linear-gradient(#be8a6144, #be8a6122)',
            }}
          >
            {t('matchmaker.action', 'Ansök om personlig matchmaker idag')}
          </Button>
          <Typography variant="caption" component="div" textAlign="center" sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
            <WatchLaterIcon sx={{ fontSize: 17, mr: '4px' }} />
            {t('matchmaker.help', 'Tar cirka 5 minuter')}
          </Typography>
        </Section>
        <Section
          highlight
          sx={{
            textAlign: 'center',
            h3: {
              mb: 4,
            },
            'p + h3': {
              mt: 4,
            },
            'h3 + ul': {
              pt: 0,
              mt: 0,
            },
            ul: {
              textAlign: 'left',
            },
          }}
        >
          <MarkdownText>{`## Matchmaking hjälper dig som ...\n\n- Känner dig vilse i dejtingdjungeln\n\n- Inte vill lämna kärleken åt slumpen\n\n- Inte vet vem du vill eller borde träffa\n\n- Aldrig känner att dina dejter klickar helt\n\n- Upplever dejting som en gissningslek\n\n- Dras till ohälsosamma relationer – och vill förändra det\n\n- Saknar modet att vara dig själv när du dejtar.\n\n- Är osäker på dina behov i en relation`}</MarkdownText>
        </Section>
        <Section>
          <Typography component="h2" variant="h2" textAlign="center">
            {t('matchmaker.process_title', 'Vårt program')}
          </Typography>
          <Typography variant="body2" textAlign="center">
            {t('matchmaker.process_description', 'Programmet är uppdelat i 3 delar som guidar dig till dina allra mest kompatibla matchningar.')}
          </Typography>
          <Box sx={{ textAlign: 'center' }}>
            {process.map((item) => (
              <Box key={item.icon} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 6 }}>
                <Box
                  component="img"
                  src={item.icon}
                  sx={{
                    width: 52, height: 52, minWidth: 52, mb: 2,
                    borderRadius: 26,
                    backgroundAttachment: 'fixed',
                    backgroundImage:  'repeating-radial-gradient(circle at 0 0, #230e11 0, transparent 100px), repeating-linear-gradient(#4a362b88, #4a362b44)',
                  }}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', my: 2 }}>
                  <Typography component="h3" variant="h3" sx={{ mb: 0 }}>{item.title}</Typography>
                </Box>
                <MarkdownText>{item.description}</MarkdownText>
              </Box>
            ))}
          </Box>
        </Section>
        <Section highlight sx={{ alignItems: 'center', flexDirection: 'row' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body2" textAlign="center" fontStyle="italic" sx={{ mb: 1 }}>
              “{t('matchmaker.quote', 'Det känns så himla förstklassigt och otroligt bra stöd. Jag var inte beredd på alla de insikter som skulle komma från övningarna, jag känner mig typ som en helt ny människa. Mina kompisar säger att jag pratar på ett sätt som jag aldrig har gjort förut. Det har överträffat allt, det är så häftigt verkligen!')}”
            </Typography>
            <Typography component="h5" variant="h5" textAlign="center">
              {t('matchmaker.quote_author', '– {{name}}, {{age}} år', { name: 'Madeleine', age: 34 })}
            </Typography>
          </Box>
        </Section>
        <Section>
          <Box sx={{ textAlign: 'center', mb: 4, }}>
            <Typography component="h2" variant="h2" textAlign="center">
              {t('matchmaker.sales_title', 'Ansök idag')}
            </Typography>
            <MarkdownText>
              {t('matchmaker.sales', 'Vi har högt tryck på de begränsade platser som finns tillgängliga med våra matchmakers, därför väljer vi bara dom som vi tror vi kan hjälpa. Boka en tid för att ta reda på om vi kan hjälpa dig.')}
            </MarkdownText>
          </Box>
          <Box sx={{ pb: 4, borderBottom: 'solid 1px #fff2' }}>
            <Button
              onClick={handleClick}
              fullWidth
              size="large"
              variant="contained"
              sx={{
                mb: 1,
                backgroundAttachment: 'fixed',
                backgroundImage:  'repeating-radial-gradient(circle at 10px 0, #76451F66 0, transparent 600px), repeating-linear-gradient(#be8a6144, #be8a6122)',
              }}
            >
              {t('matchmaker.action')}
            </Button>
            <Typography variant="caption" component="div" textAlign="center" sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
              <WatchLaterIcon sx={{ fontSize: 17, mr: '4px' }} />
              {t('matchmaker.help')}
            </Typography>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Box sx={{ pb: 3 }}>
              <Typography component="h2" variant="h2" textAlign="center">
                {t('matchmaker.membership_title', 'Gå med i vårt nätverk istället')}
              </Typography>
              <Typography variant="body2" textAlign="center">
                {t('matchmaker.membership_description', 'Om du inte vill ha en personlig matchmaker kan du istället ansöka om medlemskap i vårt nätverk. Då har du chansen att matcha med någon av våra klienter men är inte garanterad några introduktioner.')}
              </Typography>
            </Box>
            <Button
              component={Link}
              to={'/membership' + (name ? `?name=${name}`: '')}
              fullWidth
              size="large"
              variant="outlined"
              sx={{
                mb: 1,
                backgroundAttachment: 'fixed',
                backgroundImage:  'repeating-radial-gradient(circle at 0 0, #230e11 0, transparent 600px), repeating-linear-gradient(#4a362b88, #4a362b44)',
              }}
            >
              {t('matchmaker.membership_action', 'Gå med i vårt nätverk idag')}
            </Button>
            <Typography variant="caption" component="div" textAlign="center" sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
              {t('matchmaker.membership_help', 'Medlemskap i vårt nätverk är kostnadsfritt.')}
            </Typography>
          </Box>
        </Section>
        <Section highlight>
          <Typography component="h2" variant="h2">
            {t('matchmaker.questions_title', 'Så funkar matchmaking')}
          </Typography>
          <Box>
            {questions.map((item) => (
              <Question key={item.title} item={item} />
            ))}
          </Box>
        </Section>
        <Section sx={{ alignItems: 'center' }}>
          <Image src="/logo.png" sx={{ width: 84 }} />
          <Typography variant="caption">
            {t('slogan')}
          </Typography>
        </Section>
        <Section />
        {showLogin && (
          <LoginDialog
            open
            handleClose={() => setShowLogin(false)}
            onLoggedIn={(user) => {
              if (user?.appliedAt) {
                track('already_applied');
                navigate('/');
              } else if (user && !user?.firstName && !user?.lastName) {
                // Skip profile selection if none exist already.
                navigate('/application?service=matchmaker');
              } else {
                setShowLoggedIn(true);
              }
            }}
          />
        )}
        {showLoggedIn && (
          <LoggedInDialog
            open
            handleClose={() => setShowLoggedIn(false)}
            nextPath="/application?service=matchmaker"
          />
        )}
      </Box>
    </Body>
  );
}
