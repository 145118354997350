import Box from '@mui/material/Box';
import { useField } from 'react-final-form';
import { useAuth } from '../../data/auth/token';
import ProfileOption from '../ProfileOption';
import { useTranslation } from 'react-i18next';

type ProfileOptionsFieldProps = {
  name: string;
};

const ProfileOptionsField = ({ name }: ProfileOptionsFieldProps) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { input } = useField(name, { defaultValue: true });

  return (
    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <ProfileOption
        label={[user.firstName, user.lastName].join(' ')}
        description={t('ProfileOptionsField.description', 'Din profil från Relate Date')}
        picture={user.picture}
        selected={input.value === true}
        onClick={() => input.onChange(true)}
      />
      <ProfileOption
        label={t('ProfileOptionsField.create_new_profile', 'Skapa ny profil')}
        selected={input.value === false}
        onClick={() => input.onChange(false)}
      />
    </Box>
  );
};

export default ProfileOptionsField;
