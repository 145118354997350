const nonEmpty = (o: any) => o != null;

const deleteItemsForIds = (target: any[], items: string[]) => {
  const destination: any[] = target.slice();
  items.forEach((_id: string) => {
    const index = destination.findIndex((o: any) => o && o._id === _id);
    if (index >= 0) {
      delete destination[index];
    }
  });
  return destination.filter(nonEmpty);
};

export default deleteItemsForIds;
