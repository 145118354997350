import gql from 'graphql-tag';

const fragment = gql`
  fragment UserPictureFragment on UserPicture {
    _id
    uri(size: "gallery", format: "jpeg") @connection(key: "uri")
  }
`;

export default fragment;
