import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import PhoneField from '../components/fields/PhoneField';
import CodeField from '../components/fields/CodeField';
import CheckboxField from '../components/fields/CheckboxField';

import { useEffect, useRef, useState } from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { required, validateCode, validatePhone } from '../components/fields/helpers';
import { FORM_ERROR, FormApi } from 'final-form';
import client, { setAuthToken } from '../data/client';
import { sentPhoneTimeouts } from '../data/auth/sentPhoneNumbers';
import CountDownSendAgain from '../components/fields/CountDownSendAgain';
import LoginMutation from '../data/mutations/LoginMutation';
import MarkdownText from '../components/MarkdownText';
import { track } from '../helpers/mixpanel';

const spinner = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  pointerEvents: 'none',
};

type LoginDialogProps = {
  open?: boolean;
  handleClose: () => void;
  defaultValues?: { userId?: string };
  onLoggedIn: (user?: any) => void;
};

const LoginDialog = ({ open = false, handleClose, defaultValues, onLoggedIn }: LoginDialogProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(!!defaultValues);
  const [showConfirm, setShowConfirm] = useState(!!defaultValues);
  const [showTryAgain, setShowTryAgain] = useState(false);
  const [showPhone, setShowPhone] = useState(!defaultValues);
  const initialValues = useRef<any>(null);

  const handleReset = () => {
    delete initialValues.current?.code;
    setShowPhone(true);
    setShowConfirm(false);
    setShowTryAgain(false);
  };

  useEffect(() => {
    const { userId = null } = defaultValues || {};
    if (!userId) {
      setLoading(false);
      return;
    }
    // First time opening the form, send a login request using the userId.

    // Skip submit request if there is an exisiting timeout
    if (sentPhoneTimeouts.get(userId) - Date.now() > 0) {
      setLoading(false);
      return;
      // return { [FORM_ERROR]: t('too_many_login_requests', 'För många inloggningsförsök') };
    }

    // disallow sending multiple request with same phone within 30 seconds.
    sentPhoneTimeouts.add(userId);

    client.mutate({
      mutation: LoginMutation,
      variables: {
        method: 'userId',
        input: userId,
      },
    }).then((res: any) => {
      if (res?.data?.login?.token) {
        setAuthToken(res.data.login.token);
      } else {
        handleReset();
      }
      setLoading(false);
    }).catch((err: any) => {
      if (err.message.includes('To many login request')) {
        // return { [FORM_ERROR]: t('too_many_login_requests', 'För många inloggningsförsök') };
      }
      // return { [FORM_ERROR]: err.message };
      handleReset();
    });
  }, []);

  const onSubmit = async (values: any, form: FormApi) => {
    const { phone, code } = values;
    initialValues.current = values;
    if (showConfirm) {
      track('submit_login_code');
      delete initialValues.current.code;
      form.reset();

      try {
        const res = await client.mutate({
          mutation: LoginMutation,
          variables: {
            method: 'code',
            input: code,
          },
        });
        if (res?.data?.login?.token) {
          setAuthToken(res.data.login.token);
          await client.refetchQueries({ include: 'active' });
          track('submit_login_code_success');
          onLoggedIn(res.data.login?.user);
        } else {
          track('submit_login_code_failed');
        }
      } catch (err: any) {
        track('submit_login_code_error');
        if (err.message.includes('login_request_faulty_code')) {
          return { [FORM_ERROR]: t('login_request_faulty_code', 'Fel kod') };
        }
        if (err.message.includes('too_many_login_retries')) {
          setShowTryAgain(true);
          return { [FORM_ERROR]: t('too_many_login_attempts', 'För många försök') };
        }
        return { [FORM_ERROR]: err.message };
      }
    } else {
      track('submit_login_phone');
      // Skip submit request if there is an exisiting timeout
      if (sentPhoneTimeouts.get(phone) - Date.now() > 0) {
        track('submit_login_phone_blocked');
        return { [FORM_ERROR]: t('too_many_login_requests', 'För många inloggningsförsök') };
      }

      // disallow sending multiple request with same phone within 30 seconds.
      sentPhoneTimeouts.add(phone);

      try {
        const res = await client.mutate({
          mutation: LoginMutation,
          variables: {
            method: 'phone',
            input: phone,
          },
        });

        if (res?.data?.login?.token) {
          setAuthToken(res.data.login.token);
          setShowConfirm(true);
          track('submit_login_phone_success');
        } else {
          track('submit_login_phone_failed');
        }
      } catch (err: any) {
        if (err.message.includes('To many login request')) {
          track('submit_login_phone_blocked');
          return { [FORM_ERROR]: t('too_many_login_requests', 'För många inloggningsförsök') };
        }
        track('submit_login_phone_error');
        return { [FORM_ERROR]: err.message };
      }
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="login-dialog-title"
      aria-describedby="login-dialog-description"
      sx={{
        '.MuiDialog-paper': {
          background: 'none',
          boxShadow: 'none',
          m: 2,
        },
        '.MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          backdropFilter: 'blur(10px)',
        }
      }}
    >
      {loading && (
        <Box sx={spinner}>
          <CircularProgress sx={{ color: 'white' }} size={24} />
        </Box>
      )}
      <Box sx={{ ...loading && { opacity: 0, pointerEvents: 'none' }}}>
        <Form
          key={showConfirm ? 'code' : 'phone'}
          initialValues={initialValues.current}
          onSubmit={onSubmit}
          render={({
            form,
            errors,
            submitError,
            submitting,
            hasValidationErrors,
            hasSubmitErrors,
            submitSucceeded,
            dirty,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>    
              <Box sx={{
                borderRadius: 4,
                background: '#38191E',
                boxShadow: '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
                mb: 2
              }}>
                <DialogTitle id="login-dialog-title">
                  {showConfirm
                    ? t('LoginDialog.title', 'Bekräfta ditt nummer')
                    : t('LoginDialog.title_phone', 'Kom igång med ditt nummer')}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="login-dialog-description">
                    {showConfirm
                      ? t('LoginDialog.description', 'Ange koden du fått via SMS för att bekräfta att det är du, vi har skickat till ditt nummer.')
                      : t('LoginDialog.description_phone', 'Du skapar ditt konto med ditt telefonnummer, vilket möjliggör säker åtkomst till ditt konto genom verifiering med en SMS-bekräftelsekod.')}
                  </DialogContentText>
                  <Box sx={{ mt: 1 }}>
                    {showPhone && (
                      <PhoneField
                        id="phone"
                        label={t('phone', 'Telefonnummer')}
                        name="phone"
                        autoFocus
                        config={{ ...!showConfirm && { validate: validatePhone } }}
                        disabled={showConfirm || submitting}
                      />
                    )}
                    {showConfirm && (
                      <CodeField
                        name="code"
                        label={t('code', 'Kod')}
                        type="number"
                        id="code"
                        placeholder={t('enter_code', 'Ange din kod')}
                        helperText={t('required_code', 'Ange din kod som du fick via SMS')}
                        autoFocus
                        config={{ validate: validateCode }}
                        disabled={submitting}
                      />
                    )}
                  </Box>
                </DialogContent>
              </Box>
              <DialogActions sx={{ p: 0, gap: 2 }}>
                {!showConfirm && (
                  <CheckboxField
                    sx={{ pt: 1, pb: 2 }} 
                    name="accepted"
                    label={t('accept_terms_and_conditions') + ' *'}
                    config={{ validate: required }}
                    componentsProps={{
                      typography: {
                        component: MarkdownText,
                      },
                    }}
                  />
                )}
                {submitError && (
                  <Alert severity="error" sx={{ mt: 2, mb: 2, borderRadius: 4 }}>
                    <AlertTitle>{t('error_title', 'Något gick fel...')}</AlertTitle>
                    {submitError}
                  </Alert>
                )}
                <Button
                  type="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                  disabled={submitting || hasValidationErrors || ((hasSubmitErrors || submitSucceeded) && !dirty) || showTryAgain}
                >
                  <CountDownSendAgain timer={errors?.phone} form={form} field="phone">
                    {t('continue', 'Fortsätt')}
                  </CountDownSendAgain>
                </Button>
                {!showTryAgain && showConfirm && (
                  <Button
                    size="large"
                    variant="text"
                    onClick={handleReset}
                  >
                    {t('didnt_get_a_code', `Jag fick ingen kod`)}
                  </Button>
                )}
                {showTryAgain && (
                  <Button
                    size="large"
                    variant="text"
                    onClick={handleReset}
                  >
                    {t('try_again', `Prova på nytt`)}
                  </Button>
                )}
              </DialogActions>
            </form>
          )}
        />
      </Box>
    </Dialog>
  )
}

export default LoginDialog;
