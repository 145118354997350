import { RefObject, useCallback, useState, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SizeRange, VirtuosoHandle } from 'react-virtuoso'
import { FormSpyRenderProps } from 'react-final-form';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { GroupItemType } from '../pages/Profile';
import { isComplete } from './ProgressIndicator';
import { ValidationErrors } from 'final-form';
import { track } from '../helpers/mixpanel';

type SubmitButtonProps = FormSpyRenderProps & {
  fields: GroupItemType[];
  virtuoso: RefObject<VirtuosoHandle>;
  atBottom: boolean;
  handleSubmit: () => void;
};

function calculateTopStartIndex(ranges: SizeRange[], scrollTop: number) {
  let totalSize = 0;
  let headings = 0;
  let headingSize = ranges[0].size;

  for (const range of ranges) {
    const startIndex = range.startIndex;
    const endIndex = range.endIndex !== null ? range.endIndex : startIndex;

    for (let i = startIndex; i <= endIndex; i++) {
      totalSize += range.size;

      if (range.size === headingSize) {
        headings += 1;
      } else if (totalSize >= scrollTop + 100) {
        return i - headings + 1;
      }
    }
  }

  return -1;
}

export function getValueAtPath(obj: any, path: string) {
  return path.split('.').reduce((o, key) => (o && o[key] !== 'undefined' ? o[key] : null), obj);
}

function isFinished(fields: GroupItemType[], values: Record<string, any>, errors: ValidationErrors): boolean {
  return fields.every(field => !field.name || field.name === 'extra' || (
    isComplete(getValueAtPath(values, field.name))
    && !getValueAtPath(errors, field.name)
  ));
};

const getErrorField = (fields: GroupItemType[], errors: ValidationErrors, visited?: { [key: string]: boolean }, dirtyFields?: { [key: string]: boolean }) => {
  let errorFieldIndex = -1;
  let errorField = null;
  if (errors) {
    Object.keys(errors).forEach(fieldName => {
      if (visited?.[fieldName] || dirtyFields?.[fieldName]) {
        const fieldIndex = fields.findIndex(field => field.name === fieldName);
        if (fieldIndex >= 0 && (fieldIndex < errorFieldIndex || errorFieldIndex < 0)) {
          errorField = fieldName;
          errorFieldIndex = fieldIndex;
        }
      }
    });
  }
  return { errorField, errorFieldIndex };
}

const SubmitButton = ({ fields, form, virtuoso, values, active, errors, visited, atBottom, handleSubmit, submitting }: SubmitButtonProps) => {
  const { t } = useTranslation();
  const dirtyFields = useMemo(() => {
    return fields.reduce((acc, field) => ({
      ...acc,
      ...field?.name && {
        [field.name]: isComplete(values?.[field.name]),
      },
    }), {});
  }, [values]);
  const { errorField, errorFieldIndex } = getErrorField(fields, errors, visited, dirtyFields);
  const errorValue = errorField && errors?.[errorField];
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const label = useMemo(() => {
    if (typeof errorValue === 'string') {
      return errorValue;
    }
    if (!atBottom && !isFinished(fields, values, errors)) {
      return t('next', 'Nästa');
    }
    return t('continue', 'Fortsätt');
  }, [errorValue, atBottom]); 
  const handleClick = useCallback((e) => {
    const finished = isFinished(fields, values, errors);
    e.preventDefault();
    track('submit_button_pressed', { finished, errorField, atBottom });
    if (!virtuoso.current) return;
    if (errorFieldIndex >= 0) {
      // scroll to error field.
      const errorFieldName = fields[errorFieldIndex].name;
      if (errorFieldName) {
        virtuoso.current?.scrollToIndex({
          index: errorFieldIndex,
          behavior: 'smooth',
        });
        // Blur all other fields.
        fields.map(field => field?.name && field?.name !== errorField && form.blur(field.name));
        clearTimeout(timeout.current as NodeJS.Timeout);
        // Blur next field when animation is done.
        timeout.current = setTimeout(() => {
          form.blur(errorFieldName);
          form.focus(errorFieldName);
        }, 500);
      }
    } else if (!atBottom && !finished) {
      virtuoso.current.getState(({ ranges, scrollTop }) => {
        // Get the top most next index in the viewport.
        const startIndex = calculateTopStartIndex(ranges, scrollTop);
        
        // Get the next incomplete field.
        const nextIndex = startIndex + Math.max(0, fields.slice(startIndex).findIndex(field => {
          return field.name && (getValueAtPath(errors, field.name) || !isComplete(getValueAtPath(values, field.name)));
        }));

        const nextFieldName = fields[nextIndex].name;
        if (nextFieldName) {
          virtuoso.current?.scrollToIndex({
            index: nextIndex,
            behavior: 'smooth',
          });
          // Blur all other fields.
          fields.map(field => field?.name && field?.name !== errorField && form.blur(field.name));
          clearTimeout(timeout.current as NodeJS.Timeout);
          // Blur next field when animation is done.
          timeout.current = setTimeout(() => {
            form.blur(nextFieldName);
            form.focus(nextFieldName);
          }, 500);
        }
      });
    } else if (atBottom || finished) {
      if (finished) {
        handleSubmit();
      } else {
        handleOpenModal();
      }
    }
    // continue to next step or ask to continue
  }, [atBottom, errors, values, fields, active, errorField, errorFieldIndex]);

  const [open, setOpen] = useState(false);

  const handleOpenModal = () => {
    track('submit_button_show_modal');
    setOpen(true);
  };

  const handleClose = () => {
    track('submit_button_hide_modal');
    setOpen(false);
  };

  const handleGoBack = () => {
    track('submit_button_go_back');
    setOpen(false);
    // Get the next incomplete field.
    const nextIndex = Math.max(0, fields.findIndex(field => {
      return field.name && (errors?.[field.name] || !isComplete(getValueAtPath(values, field.name)));
    }));
    const nextFieldName = fields[nextIndex].name;
    if (nextFieldName) {
      track('submit_button_scroll_to', { nextFieldName });
      virtuoso.current?.scrollToIndex({
        index: nextIndex,
        behavior: 'smooth',
      });
      // Blur all other fields.
      fields.map(field => field?.name && field?.name !== errorField && form.blur(field.name));
      clearTimeout(timeout.current as NodeJS.Timeout);
      // Blur next field when animation is done.
      timeout.current = setTimeout(() => {
        form.blur(nextFieldName);
        form.focus(nextFieldName);
      }, 500);
    }
  };

  const handleContinue = () => {
    track('submit_button_continue_anyways');
    setOpen(false);
    handleSubmit();
  };

  return (
    <Box
      sx={{
        p: 2, pt: 1,
        position: 'sticky',
        bottom: 0,
        width: '100%',
        zIndex: 2,
        backgroundColor: '#110808',
        boxShadow: '0 -24px 24px 0px #110808',
      }}
    >
      <Button
        type="submit"
        fullWidth
        size="large"
        variant="contained"
        disabled={submitting}
        onClick={handleClick}
        sx={{ height: 'calc(14px * 1.75 + 16px)' }}
      >
        {submitting ? (
          <CircularProgress size={20} color="inherit" />
        ) : label}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ '.MuiPaper-root': { borderRadius: 4 } }}
      >
        <DialogTitle id="alert-dialog-title">
          {t('FormContinueDialog.title', 'Fortsätt utan att fylla alla fält?')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {t('FormContinueDialog.description', 'För att underlätta matchningen, vänligen fyll i alla fält. Ju mer information du delar, desto bättre matchning kan vi göra.')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="large" variant="contained" onClick={handleGoBack}>{t('go_back_and_fill_out', 'Gå tillbaka och fyll i')}</Button>
          <Button size="large" variant="text" onClick={handleContinue} autoFocus>
            {t('continue_anyway', 'Fortsätt ändå')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SubmitButton;
