import { gql } from "@apollo/client";
import UserProfileFragment from "../fragments/UserProfileFragment";

const UserProfileQuery = gql`
query UserProfileQuery {
    viewer {
      user {
        ...UserProfileFragment
      }
    }
  }
  ${UserProfileFragment}
`;

export default UserProfileQuery;
