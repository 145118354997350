import gql from 'graphql-tag';

const DeletePictureUploadMutation = gql`
  mutation DeletePictureUploadMutation($userPictureId: ID!) {
    userPicture {
      deleteUpload(userPictureId: $userPictureId)
    }
  }
`;

export default DeletePictureUploadMutation;
