import { ApolloClient, createHttpLink } from '@apollo/client';
import { create } from 'web-worker-proxy';
import getUniqueId from './auth/getUniqueId';
import cache from './cache';
import ViewerQuery from './queries/ViewerQuery';
import { reset, track } from '../helpers/mixpanel';

const authProxy = create(new Worker(new URL('./proxy.ts', import.meta.url), { type: 'module' }));

const httpLink = createHttpLink({
  fetch: async (_: string, { signal, ...options }: any = {}): Promise<Response> => {
    const { body, status, statusText } = await authProxy.fetch(options);
    return new Response(body, { status, statusText });
  },
});

const client = new ApolloClient({
  link: httpLink,
  cache,
});

export const startup = async (referralToken?: string | null) => {
  // Prepare cache and tokens on startup.
  if (await authProxy.setup(getUniqueId(), referralToken)) {
    // Preload client data.
    await client.query({ query: ViewerQuery });
  }
};

export const setAuthToken = async (token: string | null) => {
  await authProxy.setAuthToken(token);
};

export const logout = async () => {
  localStorage.clear();
  await authProxy.logout();
  client.refetchQueries({ include: 'all' });
  reset();
  track('logout');
};

export default client;
