import { UseFieldConfig, useField } from 'react-final-form';
import { default as MuiTextField, TextFieldProps as MuiTextFieldProps } from "@mui/material/TextField";
import { useEffect, useRef } from 'react';

type TextFieldProps<FieldValue, InputValue> = MuiTextFieldProps & {
  name: String;
  config?: UseFieldConfig<FieldValue, InputValue>;
};

function TextField<FieldValue = any, InputValue = FieldValue>({
  name,
  config,
  ...props
}: TextFieldProps<FieldValue, InputValue>): JSX.Element {
  const { input, meta } = useField(name, config);
  const ref = useRef();

  useEffect(() => {
    if (meta.active) {
      ref.current.focus();
    }
  }, [ref, meta.active]);

  return (
    <MuiTextField
      inputRef={ref}
      error={Boolean(meta.submitError && !meta.active && meta.error)}
      margin="normal"
      fullWidth
      variant="filled"
      helperText={meta.submitError && !meta.active && meta.error}
      InputLabelProps={{ shrink: true }}
      {...input}
      {...props}
    />
  );
}

export default TextField;
