import { UseFieldConfig, useField } from 'react-final-form';
import { FormControlLabel, Checkbox, FormControlLabelProps, FormControl, FormHelperText } from "@mui/material";

type CheckboxFieldProps<FieldValue, InputValue> = Omit<FormControlLabelProps, 'control'> & {
  name: String;
  config?: UseFieldConfig<FieldValue, InputValue>;
};

function CheckboxField<FieldValue = any, InputValue = FieldValue>({
  name,
  config,
  sx,
  ...props
}: CheckboxFieldProps<FieldValue, InputValue>): JSX.Element {
  const { meta, input } = useField(name, { type: 'checkbox', ...config });
  return (
    <FormControl error={Boolean(meta.submitFailed && meta.error)} sx={sx}>
      <FormControlLabel
        componentsProps={{ typography: { variant: 'body2' } }}
        {...input}
        sx={{ mx: 0 }}
        control={(
          <Checkbox
            value="remember"
          />
        )}
        {...props}
      />
      {meta.submitFailed && meta.error && (
        <FormHelperText id="my-helper-text">{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
}

export default CheckboxField;
