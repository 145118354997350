const cacheKey = 'sentPhoneNumbers';

const getCache = (): { [key: string]: number } => {
  const cache = JSON.parse(localStorage.getItem(cacheKey) || '{}');
  return cache;
}

const setCache = (value: { [key: string]: number }) => {
  localStorage.setItem(cacheKey, JSON.stringify(value));
}

// Cleanup the cache and add timers to cleanup when expires.
const cleanup = () => {
  const timeouts = getCache();
  Object.keys(timeouts).forEach(key => {
    const timeout = timeouts[key];
    const duration = timeout - Date.now();
    if (duration <= 0) {
      delete timeouts[key];
    } else {
      setTimeout(() => {
        const timeouts = getCache();
        delete timeouts[key];
        setCache(timeouts);
      }, duration);
    }
  });
  setCache(timeouts);
}

export const sentPhoneTimeouts = (() => {
  const duration = 30 * 1000;
  cleanup();

  return {
    get: (key: string) => {
      const cache = getCache();
      return cache[key];
    },

    add: (key: string) => {
      const cache = getCache();
      cache[key] = Date.now() + duration;
      setCache(cache);
      setTimeout(() => {
        const cache = getCache();
        delete cache[key];
        setCache(cache);
      }, duration);
    },

    remove: (key: string) => {
      const cache = getCache();
      delete cache[key];
      setCache(cache);
    },

    values: () => {
      const cache = getCache();
      return Object.values(cache);
    },
  };
})();
