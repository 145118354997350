import gql from 'graphql-tag';

const StartVideoUploadMutation = gql`
  mutation StartVideoUploadMutation {
    userVideo {
      startUpload {
        _id
        url
      }
    }
  }
`;

export default StartVideoUploadMutation;
