import { gql } from "@apollo/client";
import UserViewerFragment from "../fragments/UserViewerFragment";

const LoginMutation = gql`
  mutation LoginMutation($method: String!, $input: String!) {
    login(method: $method, input: $input) {
      token
      user {
        ...UserViewerFragment
      }
    }
  }
  ${UserViewerFragment}
`;

export default LoginMutation;
