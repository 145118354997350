import { Box, BoxProps } from '@mui/material';
import Image from './Image';

const ResponsiveImage = ({ src, sx, component, borderRadius, disabled, onClick, ...props }: BoxProps & { disabled?: boolean, src?: string }) => {
  return (
    <Box
      component={component}
      position="relative"
      width="100%"
      disabled={disabled}
      onClick={onClick}
      sx={{
        p: 0,
        paddingBottom: '125%', // For a 8:10 aspect ratio
        borderRadius,
      }}
    >
      <Image
        key={src}
        src={src}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%', 
          height: '100%',
          objectFit: 'cover',
          borderRadius,
          ...sx,
        }}
        {...props}
      />
    </Box>
  );
}

export default ResponsiveImage;
