import { Box } from '@mui/material';
import MarkdownText from './MarkdownText';

const GroupHeading = ({ title }: { title: string }) => {
  return (
    <Box sx={{ px: 2, py: 1 }}>
      <MarkdownText>
        {'## ' + title}
      </MarkdownText>
    </Box>
  )
}

export default GroupHeading;
