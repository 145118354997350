import { Box, Chip, Typography } from '@mui/material';
import MarkdownText from '../../components/MarkdownText';
import Block from '../../components/Block';
import { useTranslation } from 'react-i18next';

const colors = {
  needs: '#FDC451',
  values: '#A86EE1',
  beliefs: '#FFA07A',
  life_stage: '#77B651',
  personality: '#259FCF',
  interests: '#FFA700',
  attraction: '#EE5951',
  sex: '#CC1133',// EE5951
};

const dimensions = [
  'needs',
  'values',
  'beliefs',
  'life_stage',
  'personality',
  'interests',
  'attraction',
  'sex',
];

const dimensionFields = {
  needs: [
    'partnerType',
    'relationNeedPreference',
    'relationFeelingPreference',
    'loveLanguage',
    'relationshipPreference',
    'futureLifePreference',
  ],
  values: [
    'lifeValues',
    'relationshipValues',
    'societyValues',
    'political',
    'religiousUpbringing',
    'religion',
    'religiousBelief',
  ],
  beliefs: [
    'alcohol',
    'alcoholPreference',
    'tobacco',
    'tobaccoPreference',
    'childhood',
    'childhoodPreference',
    'drugs',
    'dealbreakers',
  ],
  life_stage: [
    'lifeStage',
    'relationshipStatus',
    'marriagePreference',
    'haveKids',
    'haveKidsPreference',
    'livesWithKids',
    'livesWithKidsPreference',
    'wantKids',
    'wantKidsPreference',
    'wantKidsWhen',
    'pets',
    'petsPreference',
    'income',
    'incomePreference',
    'locations',
    'locationsPreference',
  ],
  personality: [
    'energy',
    'energyPreference',
    'personality',
    'personalityPreference',
    'personalityType',
  ],
  interests: [
    'interest',
    'typicalSunday',
    'homeDecoration',
    'vacationHome',
    'occupationRole',
    'educationTopics',
    'firstDatePreference',
  ],
  attraction: [
    'aroma',
    'taste',
    'age',
    'agePreference',
    'sex',
    'sexPreference',
    'sexuality',
    'sexualityPreference',
    'height',
    'heightPreference',
    'educationLevel',
    'educationLevelPreference',
  ],
  sex: [
    'sexFrequencyPreference',
    'sexLanguagePreference',
  ],
};

const fieldDescriptions = {
  partnerType: 'Vem du är som partner',
  relationNeedPreference: 'Dina behov i en relation',
  relationFeelingPreference: 'Hur du vill känna dig i en relation',
  loveLanguage: 'Dina kärleksspråk',
  relationshipPreference: 'Din önskan på typ av relation',
  futureLifePreference: 'Vad du önskar i er framtid',
  lifeValues: 'Hur du vill leva ditt liv',
  relationshipValues: 'Vad du uppskattar mest i en relation',
  societyValues: 'Vad du tycker är viktigast i samhället',
  political: 'Dina politiska värderingar',
  religiousUpbringing: 'Vilken religös uppfostran du haft',
  religion: 'Dina religösa värderingar',
  religiousBelief: 'Hur religös du är',
  alcohol: 'Din användning av alkohol',
  alcoholPreference: 'Din preferens på alkoholanvändning',
  tobacco: 'Din brukande av tobak',
  tobaccoPreference: 'Din preferens på användning av tobak',
  childhood: 'Din uppväxt',
  childhoodPreference: 'Din preferens på uppväxt',
  drugs: 'Din inställning till droger',
  dealbreakers: 'Dina dealbreakers',
  lifeStage: 'Var du är i livet just nu',
  relationshipStatus: 'Din relationsstatus',
  marriagePreference: 'Din preferens på tidigare äktenskap',
  haveKids: 'Om du har barn',
  haveKidsPreference: 'Om du är öppen för barn',
  livesWithKids: 'Om du bor med barn',
  livesWithKidsPreference: 'Din preferens på om någon bor med barn',
  wantKids: 'Om du vill ha barn',
  wantKidsPreference: 'Din preferens på om någon vill ha barn',
  wantKidsWhen: 'När du vill ha barn',
  pets: 'Om du har djur',
  petsPreference: 'Om du är öppen för att träffa någon med barn',
  income: 'Din inkomst',
  incomePreference: 'Din preferens på inkomst',
  locations: 'Din plats',
  locationsPreference: 'Din preferens på plats',
  energy: 'Din energi',
  energyPreference: 'Din preferens på energi',
  personality: 'Din personlighet',
  personalityPreference: 'Din preferens på personlighet',
  personalityType: 'Din personlighetstyp',
  interest: 'Dina intressen',
  typicalSunday: 'Hur du vill spendera en söndag',
  homeDecoration: 'Hur du skulle inreda ditt hem',
  vacationHome: 'Hur du föredrar att bo på semester',
  occupationRole: 'Din yrkesroll',
  educationTopics: 'Dina utbildningsämnen',
  firstDatePreference: 'Hur du vill spendera en första dejt',
  age: 'Din ålder',
  agePreference: 'Din preferens på ålder',
  sex: 'Ditt kön',
  sexPreference: 'Vilket kön du föredrar',
  sexuality: 'Din sexualtitet',
  sexualityPreference: 'Vilken sexualitet du föredrar',
  height: 'Din längd',
  heightPreference: 'Vilka längder du är öppen för',
  educationLevel: 'Din utbildningsnivå',
  educationLevelPreference: 'Utbildningsnivå du föredrar',
  sexFrequencyPreference: 'Hur ofta du vill ha sex',
  sexLanguagePreference: 'Ditt sexspråk',
  aroma: 'Vilka du attraherar',
  taste: 'Vad du attraheras av',
}

const MatchmakingDimensions = () => {
  const { t } = useTranslation();

  return (
    <Box>
      {dimensions.map(dimension => (
        <Block key={dimension}  sx={{ mx: 0, p: { xs: 2, sm: 3 } }}>
          <Box key={dimension} sx={{ mb: 2, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{
              width: 16,
              height: 16,
              borderRadius: 8,
              background: colors[dimension],
              mr: 1,
            }} />
            <Typography component="h3" variant="h3" sx={{ mb: 0 }}>{t(`Member.${dimension}.name`)}</Typography>
          </Box>
          <Box sx={{ textAlign: 'left' }}>
            <MarkdownText>
              {t(`Member.${dimension}.description`, {
                needs: 'Identifierar och matchar grundläggande relationella krav och önskemål. Inkluderar partners typ, känslomässiga behov och framtida livsstilspreferenser för att skapa en stabil grund.\n\nFör dig utgår vi ifrån:',
                values: 'Sammanför personer med liknande livs- och samhällsvärderingar, inklusive synen på familj, karriär och moraliska frågor, samt politiska och religiösa övertygelser.\n\nFör dig utgår vi ifrån:',
                beliefs: 'Matchar baserat på djupare personliga övertygelser och åsikter om livet och samhället, inklusive inställningar till alkohol, tobak och droger samt barndomsupplevelser.\n\nFör dig utgår vi ifrån:',
                life_stage: 'Tar hänsyn till individernas nuvarande livssituation och framtida preferenser, inklusive relationell status, äktenskap och barn, boendearrangemang och ekonomi.\n\nFör dig utgår vi ifrån:',
                personality: 'Anpassar baserat på energinivå, personlighetstyper och preferenser för att skapa en balanserad dynamik och komplettera varandra.\n\nFör dig utgår vi ifrån:',
                interests: 'Matchar baserat på gemensamma hobbyer, intressen och aktiviteter för att främja meningsfulla upplevelser och samvaro.\n\nFör dig utgår vi ifrån:',
                attraction: 'Tar hänsyn till preferenser för attraktion gentemot olika egenskaper hos en partner, inklusive ålder, kön, sexuell läggning och längd.\n\nFör dig utgår vi ifrån:',
                sex: 'Fokuserar på individernas sexuella preferenser och kommunikationsstilar för att främja en hälsosam och tillfredsställande sexuell relation.\n\nFör dig utgår vi ifrån:',
              }[dimension])}
            </MarkdownText>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: 0.5,
              mt: 1,
            }}>
              {dimensionFields[dimension].map(field => (
                <Chip
                  size="small"
                  key={field}
                  label={fieldDescriptions[field]}
                  sx={{
                    background: colors[dimension] + '88',
                  }}
                />
              ))}
            </Box>
          </Box>
        </Block>
      ))}
    </Box>
  );
}

export default MatchmakingDimensions;
