import memoizee from "memoizee";
import mixpanel from "mixpanel-browser";

// Setup mixpanel tracking.
const mixpanelToken = import.meta.env.VITE_MIXPANEL_TOKEN;
const trackingDisabled = !Boolean(mixpanelToken);
if (mixpanelToken) {
  mixpanel.init(mixpanelToken, {
    persistence: 'localStorage',
    api_host: 'https://api-eu.mixpanel.com',
  });
}

const customNavigateEvents: { [key: string]: string} = {
  '/': 'navigate_home',
  '/signup': 'navigate_signup',
  '/login': 'navigate_login',
  '/application': 'navigate_application',
  '/invitation': 'navigate_invitation',
  '/profile': 'navigate_profile',
  '/preferences': 'navigate_preferences',
  '/complete': 'navigate_complete',
  '/verify': 'navigate_verify',
  '/membership': 'navigate_membership',
  '/matchmaker': 'navigate_matchmaker',
  '/are-you-ready': 'navigate_are_you_ready',
  '/dating-types': 'navigate_dating_types',
  '/attachment-theory': 'navigate_attachment_theory',
  '/love-languages': 'navigate_love_languages',
  '/training': 'navigate_training',
  '/training-video': 'navigate_training_video',
  '/training-booking': 'navigate_training_booking',
}

export const trackPageView = memoizee((path, searchParams) => {
  if (trackingDisabled) return;
  const key = path.replace(/\/$/, '');
  const event = customNavigateEvents?.[key];
  if (event) {
    try {
      mixpanel.track(event, {
        path: location.pathname,
        search: searchParams,
      });
    } catch (err) {}
  } else {
    mixpanel.track('navigate_other', {
      path: location.pathname,
      search: searchParams,
    });
  }
}, { length: 1, maxAge: 2000 });

export const track = memoizee((event: string, params?: any) => {
  if (trackingDisabled) return;
  mixpanel.track(event, params);
}, { maxAge: 1000 });

export const reset = () => {
  if (trackingDisabled) return;
  try { mixpanel.reset(); } catch (err) {}
};

export const identify = (userId?: string) => {
  if (trackingDisabled || !userId) return;
  try { mixpanel.identify(userId); } catch (err) {}
}

export const updateTrackingProfile = (user: any) => {
  if (trackingDisabled || !user) return;
  try {
    mixpanel.people.set({
      "$email": user.email,
      "$first_name": user.firstName,
      "$last_name": user.lastName,
      ...user?.service && { service: user.service },
      // any other user properties
    });
  } catch (err) {}
};
