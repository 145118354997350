import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, createSearchParams, RouterProvider } from "react-router-dom";
import './index.css'
import ErrorPage from './error-page';
import { ApolloProvider } from '@apollo/client';
import { HelmetProvider } from 'react-helmet-async';
import client, { startup } from './data/client';
import AuthProvider from './components/auth/AuthProvider';
import RequireAuth from './components/auth/RequireAuth';
import Layout from './Layout';
import './data/i18n';
import Home from './pages/Home'
import Login from './pages/Login';
import Signup from './pages/Signup';
import Invitation from './pages/Invitation';
import Complete from './pages/Complete';
import Verification from './pages/Verification';
import Profile from './pages/Profile';
import Application from './pages/Application';
import Preferences from './pages/Preferences';
import ScrollToTop from './components/ScrollToTop';
import './helpers/mixpanel';
// import './helpers/sentry';
import Membership from './pages/Membership';
import Matchmaker from './pages/Matchmaker';
import AreYouReady from './pages/AreYouReady';
import DatingTypes from './pages/DatingTypes';
import AttachmentTheory from './pages/AttachmentTheory';
import LoveLanguages from './pages/LoveLanguages';
import Start from './pages/Start';
import Training from './pages/Training';
import TrainingVideo from './pages/TrainingVideo';
import TrainingBooking from './pages/TrainingBooking';

const router = createBrowserRouter([
  {
    path: "/",
    loader: async () => {
      const searchParams = createSearchParams(location.search);
      const referralToken = searchParams.get('referral_token');
      await startup(referralToken);
      return null;
    },
    children: [
      {
        path: "/",
        element: <RequireAuth membership><ScrollToTop /><Home /></RequireAuth>,
        errorElement: <ErrorPage />,
      },
      {
        path: "login",
        element: <><ScrollToTop /><Login /></>,
        errorElement: <ErrorPage />,
      },
      {
        path: "signup",
        element: <><ScrollToTop /><Signup /></>,
        errorElement: <ErrorPage />,
      },
      {
        path: "start",
        element: <><ScrollToTop /><Start /></>,
        errorElement: <ErrorPage />,
      },
      {
        path: "application",
        element: <RequireAuth><ScrollToTop /><Application /></RequireAuth>,
        errorElement: <ErrorPage />,
      },
      {
        path: "invitation",
        element: <><ScrollToTop /><Invitation /></>,
        errorElement: <ErrorPage />,
      },
      {
        path: "are-you-ready",
        element: <><ScrollToTop /><AreYouReady /></>,
        errorElement: <ErrorPage />,
      },
      {
        path: "dating-types",
        element: <><ScrollToTop /><DatingTypes /></>,
        errorElement: <ErrorPage />,
      },
      {
        path: "attachment-theory",
        element: <><ScrollToTop /><AttachmentTheory /></>,
        errorElement: <ErrorPage />,
      },
      {
        path: "love-languages",
        element: <><ScrollToTop /><LoveLanguages /></>,
        errorElement: <ErrorPage />,
      },
      {
        path: "profile",
        element: <RequireAuth><ScrollToTop /><Profile /></RequireAuth>,
        errorElement: <ErrorPage />,
      },
      {
        path: "preferences",
        element: <RequireAuth><ScrollToTop /><Preferences /></RequireAuth>,
        errorElement: <ErrorPage />,
      },
      {
        path: "complete",
        element: <RequireAuth><ScrollToTop /><Complete /></RequireAuth>,
        errorElement: <ErrorPage />,
      },
      {
        path: "verify",
        element: <RequireAuth><ScrollToTop /><Verification /></RequireAuth>,
        errorElement: <ErrorPage />,
      },
      {
        path: "membership",
        element: <><ScrollToTop /><Membership /></>,
        errorElement: <ErrorPage />,
      },
      {
        path: "matchmaker",
        element: <><ScrollToTop /><Matchmaker /></>,
        errorElement: <ErrorPage />,
      },
      {
        path: "training",
        element: <><ScrollToTop /><Training /></>,
        errorElement: <ErrorPage />,
      },
      {
        path: "training-video",
        element: <><ScrollToTop /><TrainingVideo /></>,
        errorElement: <ErrorPage />,
      },
      {
        path: "training-booking",
        element: <><ScrollToTop /><TrainingBooking /></>,
        errorElement: <ErrorPage />,
      },
    ]
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <AuthProvider>
        <HelmetProvider>
          <Layout>
            <RouterProvider router={router} />
          </Layout>
        </HelmetProvider>
      </AuthProvider>
    </ApolloProvider>
  </React.StrictMode>,
);
