import { useField, useForm } from 'react-final-form';
import TextField, { TextFieldProps as MuiTextFieldProps } from "@mui/material/TextField";
import { Box, Button, CircularProgress, FormControl, FormHelperText, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';

type OptionType<T> = {
  value: T;
  label: string;
};

type OptionsFieldProps<T> = Omit<MuiTextFieldProps, 'onChange'> & {
  name: string;
  options?: OptionType<T>[];
  description?: string;
  other?: boolean;
  multiple?: boolean;
  onChange: (value: string[] | string | undefined) => void;
  compare?: (a: any, b: any) => boolean;
  SearchBox: JSX.ElementType,
};

type OptionButtonProps<T> = {
  option: OptionType<T>;
  selected: boolean;
  fullWidth: boolean;
  onClick: (value: T) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

const spinner = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  pointerEvents: 'none',
};

function OptionButton<T>({ option, onClick, onFocus, onBlur, selected, fullWidth }: OptionButtonProps<T>) {
  const [loading, setLoading] = useState(false);
  const handleClick = useCallback(async () => {
    if (option.value?.onClick && !selected) {
      setLoading(true);
      const result = await option.value.onClick();
      setLoading(false);
      if (result) {
        onClick({
          ...option.value,
          ...result,
        });
      }
    } else {
      onClick(option.value);
    }
  }, [onClick, selected]);

  return (
    <Box
      sx={{
        position: 'relative',
        ...fullWidth && { width: '100%' },
      }}
    >
      <Button
        size="small"
        variant={loading || selected ? 'contained' : 'outlined'}
        onClick={handleClick}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={loading}
        sx={{
          ...loading && { opacity: 0.5 },
          '@media screen and (max-width: 400px)': {
            ...fullWidth && { width: '100%' },
          },
        }}
      >
        {option.label}
      </Button>
      {loading && (
        <Box sx={spinner}>
          <CircularProgress size={24} />
        </Box>
      )}
    </Box>
  );
}

const container = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: 1,
  mt: 1.5,
  mb: 1.5,

  '.MuiButton-text': {
    fontStyle: 'italic',
    color: '#89696D !important',
    textDecoration: 'underline',
    textUnderlineOffset: '4px',
    '&, &:hover': {
    },
  },
};

function OptionsField<T>({
  name,
  required,
  options,
  multiple = false,
  other = false,
  compare = (a: any, b: any) => a === b,
  onChange,
  SearchBox,
  ...props
}: OptionsFieldProps<T>): JSX.Element {
  const { t } = useTranslation();
  const form = useForm('OptionsField');
  const { input, meta } = useField(name);
  const [showOther, setShowOther] = useState<T | null>(null);
  const inputRef = useRef<HTMLInputElement>();
  const ready = useRef(false);
  const fullWidth = useMemo(() => {
    return Math.max(...(options || [])?.map(option => option.label?.length)) > 40;
  }, [options]);

  useEffect(() => {
    if (onChange && ready.current) {
      onChange(input.value);
    }
    ready.current = true;
  }, [onChange, input.value]);

  const clickQueue = useRef<T[]>([]);
  const isProcessing = useRef<boolean>(false);
  const processClicks = useCallback(() => {
    if (isProcessing.current) {
      return;
    }

    isProcessing.current = true;

    const value = clickQueue.current.shift();
    if (value) {
      const field = form.getFieldState(name) as { value: T[] };
      if (multiple) {
        if (value === 'everyone' || (value as any)?.id === 'everyone') {
          input.onChange([value]);
        } else {
          const selected = field.value instanceof Array ? [...field.value] : [];
          const index = selected.findIndex(o => compare(o, value));
          if (index < 0) {
            selected.push(value);
          } else {
            selected.splice(index, 1);
          }
          const values = selected.filter(option => option !== 'everyone' && (option as any)?.id !== 'everyone');
          input.onChange(values);
        }
      } else {
        input.onChange(field?.value === value ? undefined : value);
      }
    } else if (!multiple) {
      input.onChange(undefined);
    }
    
    isProcessing.current = false;

    if (clickQueue.current.length) {
      processClicks();
    }
  }, []);

  const onOptionClick = useCallback((value: T) => {
    clickQueue.current.push(value);
    processClicks();
  }, [multiple, processClicks]);

  const isSelected = useMemo(() => multiple
    ? (items: any[], val: any): boolean => Boolean((items || []).find(o => compare(o, val)))
    : (item: any, val: any): boolean => compare(item, val), [multiple, compare]);

  const renderOption = useCallback((option: OptionType<T>) => {
    const selected = isSelected(input.value, option.value);
    return (
      <OptionButton
        key={JSON.stringify(option.value)}
        option={option}
        onClick={onOptionClick}
        selected={selected}
        fullWidth={fullWidth}
      />
    );
  }, [input.value, onOptionClick, isSelected]);

  const renderOtherOption = useCallback((option: OptionType<T>) => {
    const selected = isSelected(input.value, option.value);
    return (
      <OptionButton
        key={JSON.stringify(option.value)}
        option={option}
        onClick={() => setShowOther(option.value)}
        selected={selected}
        fullWidth={fullWidth}
      />
    );
  }, [input.value, isSelected]);

  const placeholderOtherOption = 'Skriv ditt egna svar...';

  const others = useMemo(() => {
    if (!other) return [];
    const values: T[] = input.value ? (input.value instanceof Array ? input.value : [input.value]) : [];
    return values.filter((value: T) => !((options || []).find(o => compare(o.value, value)))).map(value => ({
      value,
      label: (value as any)?.name || `${value}`,
    }));
  }, [other, input.value, options]);

  const saveOther = useCallback(() => {
    // add or update other option.
    if (inputRef.current?.value) {
      const value = inputRef.current.value as T;
      if (showOther) {
        onOptionClick(showOther);
      }
      const pattern = new RegExp(`^${(value as any)?.name || value}$`, 'i');
      const existing = (options || []).find(option => pattern.test(option.label) || pattern.test(option.value) || pattern.test(option.value?.id));
      if (existing) {
        onOptionClick(existing.value);
      } else if (!(multiple ? (input.value || []).find((o: any) => compare(o, value)) : compare(input.value, value))) {
        onOptionClick(value);
      }
    } else if (showOther) {
      onOptionClick(multiple ? showOther : '');
    }
    setShowOther(null);
  }, [showOther, input.value, multiple]);

  return (
    <FormControl variant="filled" margin="normal" sx={{ '&:first-of-type': { mt: 0 }, '&:last-of-type': { mb: 0 } }}>
      <Typography component="label" variant="h6" sx={{ mb: 0.5, fontWeight: 700 }}>{props?.label}{required ? ' *' : ''}</Typography>
      <Typography variant="caption">{props?.description}</Typography>
      {options && (
        <Box sx={container}>
          {options.map(renderOption)}
          {others.map(renderOtherOption)}
          {Boolean(other && showOther === null) && (
            <Button
              size="small"
              onClick={() => setShowOther('')}
            >
              {t('options.other', placeholderOtherOption)}
            </Button>
          )}
        </Box>
      )}
      {(showOther !== null) && (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
          {SearchBox ? (
            <SearchBox
              inputRef={inputRef}
              variant="filled"
              sx={{ flex: 1 }}
              autoFocus
              onBlur={(e: any, value: T) => {
                if ((!e.target.value?.length && !showOther) || compare(value, showOther)) {
                  // clear value if no changes was made.
                  setShowOther(null);
                }
              }}
              defaultValue={showOther}
            />
          ) : (
            <TextField
              inputRef={inputRef}
              variant="filled"
              placeholder={t('options.other', placeholderOtherOption)}
              defaultValue={typeof showOther === 'string' ? showOther : ''}
              autoFocus
              name="value"
              sx={{ flex: 1 }}
              onBlur={(e) => {
                if ((!e.target.value?.length && !showOther) || e.target.value === showOther) {
                  // clear value if no changes was made.
                  setShowOther(null);
                }
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  saveOther();
                }
              }}
            />
          )}
          <Button
            size="large"
            variant="contained"
            sx={{ borderRadius: 2 }}
            onClick={(e) => {
              e.preventDefault();
              saveOther();
            }}
          >
            <CheckIcon />
          </Button>
        </Box>
      )}
      {Boolean(meta.touched && meta.error || props?.helperText) && (
        <FormHelperText>
          {meta.error || props?.helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default OptionsField;
