import { useCallback, useState, useMemo, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupedVirtuoso, VirtuosoHandle } from 'react-virtuoso'
import { Helmet } from 'react-helmet-async';
import { Form, FormSpy } from 'react-final-form';
import { Box, CircularProgress } from '@mui/material';
import { flatten } from 'flat';
import Body from '../components/Body';
import OptionsField from '../components/fields/OptionsField';
import TextField from '../components/fields/TextField';
import ProgressIndicator from '../components/ProgressIndicator';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useLocalStorage from '../helpers/useLocalStorage';
import client from '../data/client';
import UpdateUserPreferenceMutation from '../data/mutations/UpdateUserPreferenceMutation';
import GroupHeading from '../components/GroupHeading';
import GroupItem from '../components/GroupItem';
import { useQuery } from '@apollo/client';
import UserPreferenceFieldsQuery from '../data/queries/UserPreferenceFieldsQuery';
import UserPreferenceQuery from '../data/queries/UserPreferenceQuery';
import StepIndicator from '../components/StepIndicator';
import FormHeader from '../components/FormHeader';
import SubmitButton from '../components/SubmitButton';
import RangeField from '../components/fields/RangeField';
import ScaleField from '../components/fields/ScaleField';
import { track } from '../helpers/mixpanel';
import { useAuth } from '../data/auth/token';

export type GroupItemOptionType = {
  label: string,
  value: string,
}
export type GroupItemType = {
  name?: string,
  component: (_:any) => JSX.Element,
  multiple?: boolean,
  label?: string,
  description?: string,
  placeholder?: string,
  options?: GroupItemOptionType[]
}
export type GroupType = {
  title: string,
  items: GroupItemType[],
}

const spinner = { height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' };

export default function Preferences() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const matchId = searchParams.get('match');
  const title = t('preferences.title', 'Vem längtar du efter?');
  const virtuoso = useRef<VirtuosoHandle>(null);
  const [atBottom, setAtBottom] = useState(false);
  const { user } = useAuth();
  const { data: preferenceFieldsData, loading: preferenceFieldsLoading } = useQuery(UserPreferenceFieldsQuery, { fetchPolicy: 'cache-and-network' });
  const { data: preferenceData, loading: preferenceLoading } = useQuery(UserPreferenceQuery, { fetchPolicy: 'cache-and-network' });
  const { __typename, ...defaultValues } = preferenceData?.viewer?.user?.settings?.preferences || {};
  const options: { [key: string]: string[] } = preferenceFieldsData?.userPreferenceFields;
  const loading = preferenceFieldsLoading || preferenceLoading;

  const replace = (value: string, match: string, replacement: string) => {
    if (value === match) {
      return replacement;
    }
    return value;
  }

  const [initialValues, setInitialValues] = useLocalStorage('preferences', {
    ...user && {
      sex: user?.settings?.preferences?.sex || null,
      sex_orientation: ['everyone'],
      age: 'range',
      age_range: user.age > 30 ? { min: user.age - 10, max: user.age + 10 } : { min: user.age - 5, max: user.age + 5 },
      height: user?.settings?.preferences?.height?.enabled ? 'range' : 'everyone',
      ...user?.settings?.preferences?.height?.enabled && {
        height_range: user?.settings?.preferences?.height?.min ? {
          min: user.settings.preferences.height.min,
          max: user.settings.preferences.height.max,
        } : { min: 1.3, max: 2.2 },
      },
      relationship: 'everyone',
      education_level: user?.settings?.preferences?.education_level || 'primary_school',
      alcohol: replace(user?.settings?.preferences?.drinking, 'everyone', 'yes'),
      tobacco: replace(user?.settings?.preferences?.smoking, 'everyone', 'yes'),
      have_kids: replace(user?.settings?.preferences?.children, 'everyone', 'yes'),
      want_kids: replace(user?.settings?.preferences?.family_plans, 'everyone', 'yes'),
      marriage: ['everyone'],
    },
    ...defaultValues,
    ...defaultValues?.age && {
      age: defaultValues?.age?.enabled ? 'range' : 'everyone',
      age_range: {
        min: defaultValues?.age?.min,
        max: defaultValues?.age?.max,
      },
    },
    ...defaultValues?.height && {
      height: defaultValues?.height?.enabled ? 'range' : 'everyone',
      height_range: {
        min: defaultValues?.height?.min,
        max: defaultValues?.height?.max,
      },
    },
  });

  const [showLivesWithKids, setShowLivesWithKids] = useState(false);
  const [showAgeRange, setShowAgeRange] = useState(initialValues?.age === 'range');
  const [showHeightRange, setShowHeightRange] = useState(initialValues?.height === 'range');

  // const defaultValues = user ? {
  //   sex: user?.settings?.preferences?.sex || null,
  //   sex_orientation: ['everyone'],
  //   age: 'range',
  //   age_range: user.age > 30 ? { min: user.age - 10, max: user.age + 10 } : { min: user.age - 5, max: user.age + 5 },
  //   height: user?.settings?.preferences?.height?.enabled ? 'range' : 'everyone',
  //   ...user?.settings?.preferences?.height?.enabled && {
  //     height_range: user?.settings?.preferences?.height?.min ? {
  //       min: user.settings.preferences.height.min,
  //       max: user.settings.preferences.height.max,
  //     } : { min: 1.3, max: 2.2 },
  //   },
  //   relationship: 'everyone',
  //   education_level: user?.settings?.preferences?.education_level || 'primary_school',
  //   alcohol: replace(user?.settings?.preferences?.drinking, 'everyone', 'yes'),
  //   tobacco: replace(user?.settings?.preferences?.smoking, 'everyone', 'yes'),
  //   have_kids: replace(user?.settings?.preferences?.children, 'everyone', 'yes'),
  //   want_kids: replace(user?.settings?.preferences?.family_plans, 'everyone', 'yes'),
  //   marriage: ['everyone'],
  // } : {};

  const onSubmit = useCallback(async (fields: { [key: string]: any }) => {
    track('submit_preferences');
    try {
      const {
        age,
        height,
        age_range, height_range, // pick out fields we don't want to pass down.
        ...values
      } = fields;
      const response = await client.mutate({
        mutation: UpdateUserPreferenceMutation,
        variables: {
          fields: {
            ...values,
            // Transform age and age_range to InputAge
            ...Boolean(age && age_range?.min && age_range?.max) && {
              age: {
                enabled: fields?.age === 'range',
                ...age_range, // append min and max on the age input
              },
            },
            // Transform height and height_range to InputHeight
            ...Boolean(height && height_range?.min && height_range?.max) && {
              height: {
                enabled: fields?.height === 'range',
                ...height_range, // append min and max on the height input
              },
            },
          },
        },
      });

      if (response?.data?.updateUserPreference?.user) {
        track('submit_preferences_success');
        if (matchId) {
          navigate('/complete?match=' + matchId);
        } else {
          navigate('/');
        }
      } else {
        track('submit_preferences_failed');
      }
    } catch (err) {
      track('submit_preferences_error');
    }
  }, [matchId]);

  const fieldGroups = useMemo<GroupType[]>(() => !loading && options ? [{
    title: '**2/2:** ' + title,
    items: [{
      component: FormHeader,
      description: t('preferences.description', 'Nu vet vi vem du är! Men vem letar du efter? Här kommer du både få klargöra dina dealbreakers men framförallt drömma dig framåt!'),
      videoTitle: t('preferences.video_title', 'Elin berättar vad vi behöver för att hitta rätt person för dig'),
      videoSrc: {
        mp4: t('preferences.video_mp4_src', '/preferences_sv.mp4'),
        webm: t('preferences.video_webm_src', '/preferences_sv.webm'),
        caption: t('preferences.video_caption_src', '/preferences_sv.vtt'),
      },
    }],
  }, {
    title: t('preferences.group_title', 'Personliga preferenser'),
    items: [
      {
        component: OptionsField, 
        multiple: true,
        other: true,
        name: 'sex',
        label: t(`sex.label`, 'Vilka vill du träffa?'),
        description: t(`sex.description`, 'Du kan välja flera alternativ.'),
        options: (options.sex || []).map(key => ({
          value: key,
          label: t(`sex.${key}`),
        })),
      },
      {
        component: OptionsField, 
        multiple: true,
        other: true,
        name: 'sex_orientation',
        label: t(`sex_orientation.label`, 'Vilka läggningar är du öppen för?'),
        description: t(`sex_orientation.description`, 'Du kan välja flera alternativ.'),
        options: (options.sex_orientation || []).map(key => ({
          value: key,
          label: t(`sex_orientation.${key}`),
        })),
      },
      {
        component: OptionsField,
        name: 'age',
        label: t(`preferred_age.label`, 'Vilken ålder är du öppen för?'),
        options: ['everyone', 'range'].map(key => ({
          value: key,
          label: t(`age_option.${key}`),
        })),
        onChange: (value: string | undefined) => {
          const nextShowAgeRange = value === 'range';
          if (nextShowAgeRange !== showAgeRange) {
            setShowAgeRange(nextShowAgeRange);
          }
        },
      },
      ...showAgeRange ? [{
        component: RangeField,
        name: 'age_range',
        label: t(`preferred_age_range.label`, 'Vilket åldersintervall tänker du dig?'),
        min: 18,
        max: 100,
        step: 1,
        overlap: 8,
        valueLabelFormat: (value: number) => `${value}${value === 100 ? '+' : ''}`,
        info: (value: [number, number]) => {
          if (Math.abs(value[1] - value[0]) < 15) {
            return 'Notera att detta påverkar sannolikheten för att vi hittar en match för dig. Vissa andra kvalitéer kan vara att föredra framför ålder.';
          }
          return null;
        },
      }] : [],
      {
        component: OptionsField,
        name: 'height',
        label: t(`preferred_height.label`, 'Längd du kan tänka dig'),
        options: ['everyone', 'range'].map(key => ({
          value: key,
          label: t(`height_option.${key}`),
        })),
        onChange: (value: string | undefined) => {
          const nextShowHeightRange = value === 'range';
          if (nextShowHeightRange !== showHeightRange) {
            setShowHeightRange(nextShowHeightRange);
          }
        },
      },
      ...showHeightRange ? [{
        component: RangeField,
        name: 'height_range',
        label: t(`preferred_height_range.label`, 'Vilket längdintervall tänker du dig?'),
        min: 1.30,
        max: 2.20,
        step: 0.01,
        overlap: 10,
        valueLabelFormat: (value: number) => `${Math.round(value * 100)}${value >= 2.20 ? '+' : ''}cm`,
        info: (value: [number, number]) => {
          if (Math.abs(value[1] - value[0]) < 0.20) {
            return 'Notera att detta påverkar sannolikheten för att vi hittar en match för dig. Vissa andra kvalitéer kan vara att föredra framför längd.';
          }
          return null;
        },
      }] : [],
      {
        component: OptionsField, 
        name: 'relationship',
        label: t(`relationship.label`, 'Vilken relationstyp är du öppen för? '),
        options: (options.relationship || []).map(key => ({
          value: key,
          label: t(`relationship.${key}`),
        })),
      },
      {
        component: OptionsField, 
        multiple: true,
        other: true,
        name: 'first_date',
        label: t(`first_date.label`, 'Hur skulle en ideal första dejt se ut?'),
        options: (options.first_date || []).map(key => ({
          value: key,
          label: t(`first_date.${key}`),
        })),
      },
      {
        component: ScaleField,
        min: 1,
        max: 10,
        step: 1,
        name: 'time_with_partner',
        label: t(`time_with_partner.label`, 'Hur mycket av din tid vill du spendera med din partner?'),
        description: t(`time_with_partner.description`, 'På en skala 1-10. 1 = väldigt lite, 10 = hela tiden'),
      },
    ]
  },
  {
    title: t('partner_preferences.title', 'Partner-preferenser'),
    items: [
      {
        component: OptionsField,
        name: 'education_level',
        label: t(`preferred_education_level.label`, 'Vilken lägsta utbildningsnivå har din ideal partner?'),
        options: (options.education_level || []).map(key => ({
          value: key,
          label: t(`education_level.${key}`),
        })),
      },
      {
        component: OptionsField,
        multiple: true,
        name: 'income',
        label: t(`preferred_income.label`, 'Föredragen inkomst nivå (SEK/mån)'),
        description: t(`preferred_income.description`, 'Du kan välja flera alternativ.'),
        options: (options.income || []).map(key => ({
          value: key,
          label: t(`income.${key}`),
        })),
      },
      {
        component: OptionsField,
        multiple: true,
        other: true,
        name: 'childhood',
        label: t(`preferred_childhood.label`, 'Beskriv din ideal partners uppväxt'),
        description: t(`preferred_childhood.description`, 'Du kan välja flera alternativ.'),
        options: (options.childhood || []).map(key => ({
          value: key,
          label: t(`childhood.${key}`),
        })),
      },
    ]
  },
  {
    title: t('dealbreakers.title', 'Dealbreakers'),
    items: [
      {
        component: OptionsField,
        name: 'pets',
        label: t(`preferred_pets.label`, 'Skulle du dejta någon som vill ha/har husdjur?'),
        options: (options.pets || []).map(key => ({
          value: key,
          label: t(`preferred_pets.${key}`),
        })),
      },
      {
        component: OptionsField, 
        name: 'alcohol',
        label: t(`preferred_alcohol.label`, 'Skulle du dejta någon som dricker alkohol?'),
        options: (options.alcohol || []).map(key => ({
          value: key,
          label: t(`preferred_alcohol.${key}`),
        })),
      },
      {
        component: OptionsField, 
        name: 'tobacco',
        label: t(`preferred_tobacco.label`, 'Skulle du dejta någon som röker/snusar?'),
        options: (options.tobacco || []).map(key => ({
          value: key,
          label: t(`preferred_tobacco.${key}`),
        })),
      },
      {
        component: OptionsField,
        name: 'have_kids',
        label: t(`preferred_have_kids.label`, 'Skulle du dejta någon som har barn?'),
        description: t(`preferred_have_kids.description`, 'Du kan välja flera alternativ.'),
        options: (options.have_kids || []).map(key => ({
          value: key,
          label: t(`preferred_have_kids.${key}`),
        })),
        onChange: (value: string | undefined) => {
          const nextShowLivesWithKids = value === 'yes';
          if (nextShowLivesWithKids !== showLivesWithKids) {
            setShowLivesWithKids(nextShowLivesWithKids);
          }
        },
      },
      ...showLivesWithKids ? [{
        component: OptionsField,
        multiple: true,
        name: 'lives_with_kids',
        label: t(`lives_with_kids.label`, 'Vilka personer med barn är du öppen för?'),
        description: t(`lives_with_kids.description`, 'Du kan välja flera alternativ.'),
        options: (options.lives_with_kids || []).map(key => ({
          value: key,
          label: t(`lives_with_kids.${key}`),
        })),
      }] : [],
      {
        component: OptionsField,
        name: 'want_kids',
        label: t(`preferred_want_kids.label`, 'Skulle du dejta någon som vill ha barn/fler barn?'),
        options: (options.want_kids || []).map(key => ({
          value: key,
          label: t(`want_kids.${key}`),
        })),
      },
      {
        component: OptionsField, 
        multiple: true,
        name: 'marriage',
        label: t(`marriage.label`, 'Vilka tidigare äktenskapserfarenheter skulle du dejta?'),
        description: t(`marriage.description`, 'Du kan välja flera alternativ.'),
        options: (options.marriage || []).map(key => ({
          value: key,
          label: t(`marriage.${key}`),
        })),
      },
      {
        component: OptionsField, 
        multiple: true,
        other: true,
        name: 'dealbreakers',
        label: t(`dealbreakers.label`, 'Vilka är dina övriga dealbreakers?'),
        description: t(`dealbreakers.description`, 'Om personer gör eller har något av detta så är du inte intresserad av att dejta'),
        options: (options.dealbreakers || []).map(key => ({
          value: key,
          label: t(`dealbreakers.${key}`),
        })),
      },
    ]
  },
  {
    title: t('future.title', 'Framtid'),
    items: [
      {
        component: OptionsField, 
        multiple: true,
        other: true,
        name: 'future_life',
        label: t(`future_life.label`, 'Vad ser du i er framtid?'),
        description: t(`future_life.description`, 'Du kan välja flera alternativ.'),
        options: (options.future_life || []).map(key => ({
          value: key,
          label: t(`future_life.${key}`),
        })),
      },
      {
        component: OptionsField, 
        multiple: true,
        other: true,
        name: 'relation_feeling',
        label: t(`relation_feeling.label`, 'Föreställ dig att du är 5 år in i framtiden och du är i förhållandet, vad vill du känna?'),
        options: (options.relation_feeling || []).map(key => ({
          value: key,
          label: t(`relation_feeling.${key}`),
        })),
      },
      {
        component: OptionsField,
        multiple: true,
        other: true,
        name: 'relation_need',
        label: t(`relation_need.label`, 'Vilka av följande skulle få dig att känna dig älskad, trygg, tillfredsställd och fri att vara dig själv i ett långsiktigt förhållande?'),
        options: (options.relation_need || []).map(key => ({
          value: key,
          label: t(`relation_need.${key}`),
        })),
      },
    ]
  },
  {
    title: t('attraction.title', 'Attraktion'),
    items: [
      {
        component: OptionsField, 
        multiple: true,
        other: true,
        name: 'personality',
        label: t(`personality_traits.label`, 'Personlighetsdrag jag är attraherad av...'),
        options: (options.personality || []).map(key => ({
          value: key,
          label: t(`personality.${key}`),
        })),
      },
      {
        component: OptionsField, 
        multiple: true,
        other: true,
        name: 'energy',
        label: t(`preferred_energy.label`, 'Typ av energi jag är attraherad av...'),
        options: (options.energy || []).map(key => ({
          value: key,
          label: t(`energy.${key}`),
        })),
      },
    ]
  },
  {
    title: t('sexuality.title', 'Sexualitet'),
    items: [
      {
        component: OptionsField,
        multiple: true,
        name: 'sex_frequency',
        label: t(`sex_frequency.label`, 'Hur ofta vill du ha sex?'),
        description: t(`sex_frequency.description`, 'Du kan välja flera alternativ.'),
        options: (options.sex_frequency || []).map(key => ({
          value: key,
          label: t(`sex_frequency.${key}`),
        })),
      },
      {
        component: OptionsField,
        multiple: true,
        name: 'sex_language',
        label: t(`sex_language.label`, 'Vad skulle bäst beskriva ditt sexspråk?'),
        description: t(`sex_language.description`, 'Du kan välja flera alternativ.'),
        options: (options.sex_language || []).map(key => ({
          value: key,
          label: t(`sex_language.${key}`),
        })),
      },
    ]
  },
  {
    title: t('additional.title', 'Tillägg'),
    items: [
      {
        component: TextField,
        multiline: true,
        name: 'extra',
        label: t(`additional.label`, 'Finns det något som inte kom fram i dina önskemål som du skulle vilja att vi känner till?'),
        placeholder: t(`additional.placeholder`, 'Ditt svar...'),
        sx: { mt: 0 },
      }
    ]
  }] : [], [t, options, loading, showLivesWithKids, showAgeRange, showHeightRange]);

  const groupCounts = useMemo(() => { 
    return fieldGroups.map(fieldGroup => fieldGroup.items.length);
  }, [fieldGroups]);
  const fields = useMemo(() => {
    return fieldGroups.flatMap(fieldGroup => fieldGroup.items);
  }, [fieldGroups]);

  const renderGroupContent = useCallback((index: number) => (
    <GroupHeading title={fieldGroups[index].title} />
  ), [fieldGroups]);
  const renderItemContent = useCallback((index: number) => { 
    const { component: Component, ...props } = fields?.[index];
    return (
      <GroupItem name={props.name}>
        <Component key={props.name} {...props} />
      </GroupItem>
    );
  }, [fields]);
  const atBottomStateChange = useCallback((state: boolean) => {
    setAtBottom(state);
  }, [setAtBottom]);

  const validate = useCallback((values: { [key: string]: string }) => {
    const errors: { [key: string]: string } = {};
    Object.keys(values).forEach(field => {
      const value = values[field];
      switch (field) {
        case 'life_values':
        case 'relationship_values':
        case 'society_values': {
          const count = value.length
          if (![0, 6].includes(count)) {
            const text = t('values.action', {
              postProcess: 'interval', 
              count,
              defaultValue: '(0)[Välj 6];(1)[Välj 5 till];(2)[Välj 4 till];(3)[Välj 3 till];(4)[Välj 2 till];(5)[Välj 1 till];(6)[#];(7)[Välj 1 mindre];(7-inf)[Välj max 6];'
            });
            if (text !== '#') {
              errors[field] = text;
            }
          }
          break;
        }
        case 'love_language': {
          const count = value.length
          if (![0, 6].includes(count)) {
            const text = t('love_language.action', {
              postProcess: 'interval',
              count,
              defaultValue: '(0)[Välj 2];(1-2)[#];(3)[Välj 1 mindre];(4-inf)[Välj max 2];'
            });
            if (text !== '#') {
              errors[field] = text;
            }
          }
          break;
        }
        default:
          break;
      }
    });
    return errors;
  }, []);

  return (
    <Body>
      <Helmet>
        <title>Relate | {title}</title>
      </Helmet>
      {loading ? (
        <Box sx={spinner}>
          <CircularProgress />
        </Box>
      ) : (
        <Form
          onSubmit={onSubmit}
          subscription={{}}
          validate={validate}
        >
          {({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <StepIndicator>
                <ProgressIndicator fixed={100} />
                <ProgressIndicator fields={fields} />
              </StepIndicator>
              <GroupedVirtuoso
                ref={virtuoso}
                className="virtuoso"
                groupCounts={groupCounts}
                groupContent={renderGroupContent}
                itemContent={renderItemContent}
                useWindowScroll
                atBottomStateChange={atBottomStateChange}
                increaseViewportBy={{
                  top: 10000,
                  bottom: 10000,
                }}
              />
              <FormSpy
                fields={fields}
                virtuoso={virtuoso}
                atBottom={atBottom}
                handleSubmit={handleSubmit}
                component={SubmitButton}
              />
              <FormSpy
                subscription={{ initialValues: true }}
                onChange={() => {
                  // Set initial values so that dirty is true.
                  const values = flatten(initialValues || {}, { safe: true }) as { [key: string]: any };
                  form.batch(() => {
                    Object.keys(values).forEach(name => {
                      form.change(name, values[name]);
                    });
                  });
                }}
              />
              <FormSpy
                subscription={{ values: true }}
                onChange={({ values }) => {
                  setInitialValues(values);
                }}
              />
            </form>
          )}
        </Form>
      )}
    </Body>
  );
}
