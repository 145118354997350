import { gql } from "@apollo/client";
import UserViewerFragment from "../fragments/UserViewerFragment";

const ViewerQuery = gql`
  query ViewerQuery {
    viewer {
      token
      user {
        ...UserViewerFragment
      }
    }
  }
  ${UserViewerFragment}
`;

export default ViewerQuery;
