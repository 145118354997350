import { ReactNode, memo, useRef } from 'react';
import { useField } from 'react-final-form';
import { Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import Block from '../Block';
import { isComplete } from '../ProgressIndicator';

type GroupItemFieldProps = { name: string, children?: ReactNode };

const GroupItemField = ({ children, name }: GroupItemFieldProps) => {
  const ref = useRef<Element>(null);
  const { input, meta } = useField(name);
  const complete = isComplete(input.value) && !meta.error;
  const active = meta.active;

  return (
    <Box
      ref={ref}
      sx={{
        py: 2,
        transition: 'opacity 0.5s ease 0s',
        '@media screen and (min-width: 640px)': {
          py: 4,
        }
      }}
    >
      <Block
        sx={{
          my: 0,
          position: 'relative',
          ...active && { boxShadow: '0 0 0 3px #89696D' },
          ...complete && { boxShadow: '0 0 0 3px #8E603C' },
          transition: 'box-shadow 0.5s ease 0s',
          
          label: {
            marginRight: 1,
          },
        }}
      >
        {complete ? (
          <CheckCircleIcon sx={{ color: '#8E603C', position: 'absolute', right: 8, top: 8 }} />
        ) : (
          <CircleIcon sx={{ color: '#220E11', position: 'absolute', right: 8, top: 8 }} />
        )}
        {children}
      </Block>
    </Box>
  );
};

export default memo(GroupItemField);
