import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import Image from '../../components/Image';
import Section from '../../components/Section';
import { fadeIn } from './helpers';

const MatchmakerEnd = ({ duration, handleBookCall }) => {
  const { t } = useTranslation();
  return (
    <Section>
      <Box
        sx={{
          p: 2,
          backgroundColor: '#3D161D',
          borderRadius: 4,
          mb: 2,
          ...duration > 0 ? {
            opacity: 0,
            animation: `${fadeIn} 500ms forwards linear`,
            animationDelay: `${duration + 1}s`,
          } : {},
        }}
      >
        <Typography component="h3" variant="h3" textAlign="center">
          {t('MatchmakerEnd.end.title', 'Osäker på vad som passar dig?')}
        </Typography>
        <Typography variant="body2" textAlign="center" sx={{ fontSize: { xs: '13px', sm: '15px' } }}>
          {t('MatchmakerEnd.end.description', 'Du är välkommen att boka ett förutsättningslöst samtal med oss så utforskar vi tillsammans vad som är bäst för dig.')}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', pt: 1 }}>
          <Image src="/matchmakers.png" sx={{ height: 52 }} />
        </Box>
        <Button
          fullWidth
          color="primary"
          disableElevation
          variant="contained"
          sx={{ mt: 2 }}
          onClick={() => handleBookCall('none')}
        >
          {t('MatchmakerEnd.end.action', 'Boka en tid för samtal')}
        </Button>
      </Box>
    </Section>
  );
}

export default MatchmakerEnd;
