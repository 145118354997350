
import { keyframes } from '@mui/material';

export const fadeIn = keyframes`
  to {
    opacity: 1;
  }
`;

export function formatDate(input: string | number | Date): string {
  const weekdays: string[] = ['söndag', 'måndag', 'tisdag', 'onsdag', 'torsdag', 'fredag', 'lördag'];
  const months: string[] = ['januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti', 'september', 'oktober', 'november', 'december'];

  const date: Date = new Date(input);
  const today: Date = new Date();
  const tomorrow: Date = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  if (date.toDateString() === today.toDateString()) {
      return `idag kl ${formatTime(date)}`;
  } else if (date.toDateString() === tomorrow.toDateString()) {
      return `imorgon kl ${formatTime(date)}`;
  } else {
      return `${weekdays[date.getDay()]} den ${date.getDate()} ${months[date.getMonth()]} kl ${formatTime(date)}`;
  }
}

export function formatTime(date: Date): string {
  const hours: string = padZero(date.getHours());
  const minutes: string = padZero(date.getMinutes());
  return `${hours}:${minutes}`;
}

export function padZero(num: number): string {
  return num < 10 ? `0${num}` : `${num}`;
}
