import React from "react";
import { AuthContext, authProvider } from "../../data/auth/token";
import useAuthenticated from "../../data/auth/useAuthenticated";

function AuthProvider({ children }: { children: React.ReactNode }) {
  const { user } = useAuthenticated();

  const signin = (newUser: any, callback: VoidFunction) => {
    return authProvider.signin(newUser, () => {
      callback();
    });
  };

  const signout = (callback: VoidFunction) => {
    return authProvider.signout(() => {
      callback();
    });
  };

  const value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
