import { UseFieldConfig, useField } from 'react-final-form';
import { TextFieldProps as MuiTextFieldProps } from "@mui/material/TextField";
import CodeInput from '../CodeInput';
import { Box, FormControl, FormHelperText, InputLabel } from '@mui/material';

type TextFieldProps = MuiTextFieldProps & {
  name: String;
  config?: UseFieldConfig<string, string>;
};

const control = {
  width: '100%',
};
const field = {
  marginTop: '32px',
};

function CodeField({
  name,
  config,
  ...props
}: TextFieldProps): JSX.Element {
  const { input, meta } = useField(name, config);
  return (
    <FormControl variant="filled" margin="normal" sx={control}>
      <InputLabel htmlFor="code">{props?.label}</InputLabel>
      <Box sx={field}>
        <CodeInput
          disabled={props?.disabled}
          {...input}
        />
      </Box>
      <FormHelperText>
        {meta.touched && !meta.active && meta.error || props?.helperText}
      </FormHelperText>
    </FormControl>
  );
}

export default CodeField;
