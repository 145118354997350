import { ReactNode, memo } from 'react';
import GroupItemField from './fields/GroupItemField';

const GroupItem = ({ children, name }: { name?: string, children?: ReactNode }) => {
  if (name) {
    return <GroupItemField children={children} name={name} />
  }
  else {
    return children;
  }
};

export default memo(GroupItem);
