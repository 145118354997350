import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import MarkdownText from '../../components/MarkdownText';
import Section from '../../components/Section';
import { fadeIn } from './helpers';

const MatchmakerInvitation = ({ user, duration = 3, handleMatchmakerDecline }) => {
  const { t } = useTranslation();
  return (
    <Section
      small
      highlight
      sx={{
        textAlign: 'center',
        em: { color: 'white', fontWeight: 'bold' },
        opacity: 0,
        animation: `${fadeIn} 500ms forwards linear`,
        animationDelay: `${duration + 1}s`,
        mx: 2,
        mb: 2,
        borderRadius: '16px !important',
        backgroundColor: theme => theme.palette.background.paper,
      }}
    >
      <Typography component="h2" variant="h2">
        {t('matchmaker_invitation.title', 'Lämna inte kärleken åt slumpen')}
      </Typography>
      <MarkdownText>
        {t('matchmaker_invitation.description', 'Dina svar visar att du passar för att ha en personlig matchmaker. Utforska vilket matchmaking program som passar dig bäst.')}
      </MarkdownText>
      <Box sx={{ pt: 2 }}>
        <Button
          type="submit"
          size="large"
          variant="contained"
          sx={{ mt: 1, minWidth: 320 }}
          onClick={handleMatchmakerDecline}
        >
          {t('matchmaker_invitation.action', 'Se våra program')}
        </Button>
      </Box>
    </Section>
  );
}

export default MatchmakerInvitation;
