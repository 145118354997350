import { gql } from "@apollo/client";
import UserViewerFragment from "../fragments/UserViewerFragment";

const UpdateUserHomeMutation = gql`
  mutation UpdateUserHomeMutation($fields: InputUserProfile!) {
    updateUserApplication(fields: $fields) {
      validationErrors {
        field
        message
      }
      user {
        ...UserViewerFragment
        potentialClient
        matchmakerSurveyAt
        matchmakerBookedAt
        matchmakerDeclinedAt
      }
    }
  }
  ${UserViewerFragment}
`;

export default UpdateUserHomeMutation;
