import { gql } from "@apollo/client";
import UserViewerFragment from "../fragments/UserViewerFragment";

const UpdateUserApplicationMutation = gql`
  mutation UpdateUserApplicationMutation($fields: InputUserProfile!) {
    updateUserApplication(fields: $fields) {
      validationErrors {
        field
        message
      }
      user {
        ...UserViewerFragment
      }
    }
  }
  ${UserViewerFragment}
`;

export default UpdateUserApplicationMutation;
