import Box from '@mui/material/Box';
import { SxProps } from '@mui/material';

function Section({ sx, fullWidth, small, highlight, ...props }: { sx: SxProps } & any) {
  return (
    <Box
      className="section"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ...highlight ? {
          px: small ? 2 : 3,
          py: small ? 4 : 4,
        } : {
          mx: small ? 2 : 3,
          my: small ? 4 : 4,
        },
        ...sx,
        '@media screen and (min-width: 640px)': {
          borderRadius: 8,
          ...highlight ? {
            py: 6,
            px: small ? 2 : 6,
          } : {
            my: 6,
            mx: small ? 2 : 6,
          }
        },
        ...highlight && {
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.background.paper : t.palette.secondary.main,
        },
      }}
      {...props}
    />
  );
}

export default Section;
