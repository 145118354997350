import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import Block from '../components/Block';
import ProfileOptionsField from '../components/fields/ProfileOptionsField';
import sleep from '../helpers/sleep';

const LoggedInForm = ({ nextPath }: { nextPath?: string }) => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const onSubmit = async (values: { prefill: boolean }) => {
    await sleep(300);
    navigate((nextPath || '/') + '?prefill=' + values.prefill);
    return;
  }
  return (
    <Form
      onSubmit={onSubmit}
      render={({
        submitting,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Block>
            <Typography component="h2" variant="h2">
              {t('logged_in.form_title', 'Använd tidigare profil?')}
            </Typography>
            <Typography variant="body2">
              {t('logged_in.form_description', 'Du har en profil sedan tidigare. Fortsätt med ditt tidigare konto eller skapa en ny profil från grunden.')}
            </Typography>
            <ProfileOptionsField name="prefill" />
          </Block>
          <Box sx={{ px: 2 }}>
            <Button
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              disabled={submitting}
              sx={{ mt: 1, mb: 2, height: 'calc(14px * 1.75 + 16px)' }}
            >
              {submitting ? (
                <CircularProgress size={20} color="inherit" />
              ) : t('continue', 'Fortsätt')}
            </Button>
          </Box>
        </form>
      )}
    />
  );
};

export default LoggedInForm;
