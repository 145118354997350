import { Box, Typography } from "@mui/material";
import { getSymbols, useClearByFocusCell, useFocusState } from "./utils";
import { ChangeEvent, useCallback, useLayoutEffect, useRef } from "react";
import { Cursor } from "./Cursor";

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: 1,
    position: 'relative',
  },
  input: {
    appearance: 'textfield',
    background: 'none',
    border: 0,
    outline: 0,
    width: '100%',
    // opacity: 0.01,
    color: 'transparent',
    caretColor: 'transparent',
    fontSize: 16,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  cellRoot: {
    width: 12,
    height: '1.4375em',
    lineHeight: '1.4375em',
    boxSizing: 'content-box',
    padding: '12px',
    borderRadius: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.09)',
  },
  cellText: {
    lineHeight: '1em',
    textAlign: 'center',
    fontStyle: 'normal',
  },
}

const cellCount = 6;

const onChangeText = (onChange: (value: string) => string) => (event: ChangeEvent<HTMLInputElement>) => {
  const rawValue = event.target.value || '';
  const value = rawValue.trim().replace(/[^\d]+/, '');
  onChange(value);
};

const Cell = ({ index, symbol, isFocused, onLayout }) => {
  const ref = useRef<HTMLDivElement>();
  useLayoutEffect(() => {
    if (ref.current) {
      const { x, y, width, height } = ref.current.getBoundingClientRect();
      onLayout({
        x: x + document.documentElement.scrollLeft,
        y: y + document.documentElement.scrollTop,
        width,
        height,
      });
    }
  }, [onLayout]);
  return (
    <Box
      ref={ref}
      sx={[styles.cellRoot, isFocused && {
        backgroundColor: '#fff4',
        boxShadow: 'rgb(251, 247, 242) 0px 0px 0px 3px inset',
      }]}
    >
      <Typography sx={styles.cellText}>
        {symbol || (isFocused ? <Cursor /> : null)}
      </Typography>
    </Box>
  );
}

const CodeInput = ({
  value,
  onBlur,
  onFocus,
  onChange,
  disabled,
}) => {
  const focusState = useFocusState(onBlur, onFocus);
  const [onClick, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    onChange,
  });
  const renderCell = useCallback(({ index, symbol, isFocused }) => {
    return (
      <Cell
        key={index}
        index={index}
        symbol={symbol}
        isFocused={isFocused}
        onLayout={getCellOnLayoutHandler(index)}
      />
    );
  }, [getCellOnLayoutHandler]);
  const cells = getSymbols(value || '', cellCount).map(
    (symbol, index, symbols) => {
      const isFirstEmptySymbol = symbols.indexOf('') === index;
      return renderCell({
        index,
        symbol,
        isFocused: focusState.isFocused && isFirstEmptySymbol,
      });
    },
  );

  return (
    <Box sx={[styles.root, { ...disabled && { opacity: 0.5 } }]}>
      {cells}
      <Box
        component="input"
        name="code"
        type="text"
        pattern="\d{6}"
        inputMode="numeric"
        maxLength={6}
        sx={styles.input}
        autoComplete="one-time-code"
        onChange={onChangeText(onChange)}
        value={value}
        onBlur={focusState.onBlur}
        onFocus={focusState.onFocus}
        onClick={onClick}
        disabled={disabled}
      />
    </Box>
  );
};


export default CodeInput;
