import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import ProfileOptionsField from '../components/fields/ProfileOptionsField';
import sleep from '../helpers/sleep';

type LoggedInDialogProps = {
  open?: boolean;
  handleClose: () => void;
  nextPath?: string;
};

const LoggedInDialog = ({ open = false, handleClose, nextPath }: LoggedInDialogProps) => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const onSubmit = useCallback(async (values: { prefill: boolean }) => {
    await sleep(300);
    const [baseUrl, queryString] = (nextPath || '/').split('?');
    const params = new URLSearchParams(queryString || '');
    params.set('prefill', values.prefill ? 'true' : 'false');
    navigate(`${baseUrl}?${params.toString()}`);
    return;
  }, [nextPath]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="login-dialog-title"
      aria-describedby="login-dialog-description"
      sx={{
        '.MuiDialog-paper': {
          background: 'none',
          boxShadow: 'none',
          m: 2,
        },
        '.MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          backdropFilter: 'blur(10px)',
        }
      }}
    >
      <Form
        onSubmit={onSubmit}
        render={({
          submitting,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>    
            <Box sx={{
              borderRadius: 4,
              background: '#38191E',
              boxShadow: '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
              mb: 2
            }}>
              <DialogTitle id="login-dialog-title">
                {t('logged_in.form_title')}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="login-dialog-description">
                {t('logged_in.form_description')}
                </DialogContentText>
                <ProfileOptionsField name="prefill" />
              </DialogContent>
            </Box>
            <DialogActions sx={{ p: 0, gap: 2 }}>
              <Button
                type="submit"
                fullWidth
                size="large"
                variant="contained"
                disabled={submitting}
              >
                {submitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : t('continue', 'Fortsätt')}
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  )
}

export default LoggedInDialog;
