import { UseFieldConfig, useField, useForm } from 'react-final-form';
import { TextFieldProps as MuiTextFieldProps } from "@mui/material/TextField";
import { Box, Button, CircularProgress, FormControl, SxProps, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import ResponsiveImage from '../ResponsiveImage';
import startUpload from '../../helpers/startPictureUpload';
import UploadProgressPieChart from '../UploadProgressPieChart';
import mergePictures from '../../helpers/mergePictures';
import deleteItemsForIds from '../../helpers/deleteItemsForIds';
import StrictModeDroppable from '../StrictModeDroppable';
import reorder from '../../helpers/reorder';
import isIOS from '../../helpers/isIOS';
import deletePictureUpload from '../../helpers/deletePictureUpload';

type PictureFieldProps<FieldValue, InputValue> = MuiTextFieldProps & {
  name: string;
  description?: string;
  config?: UseFieldConfig<FieldValue, InputValue>;
};

const container = {
  display: 'flex',
  flexDirection: 'row',
  mr: -1,
  userSelect: 'none',
};

const wrapper = {
  display: 'flex !important',
  flexDirection: 'row',
  mt: 1.5,
  mb: 1.5,
}

const button = {
  width: 28,
  minWidth: 28,
  height: 28,
  borderRadius: '50%',
  position: 'absolute',
  bottom: -8,
  right: -8,
  p: 0,
  fontSmooth: 'never',
  zIndex: 1,
};

const spinner = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  pointerEvents: 'none',
};

type PictureSource = {
  _id?: string;
  uri?: string;
  file?: File;
}

type PictureProps = {
  source?: PictureSource;
  index: number;
  maxFiles?: number;
  onClick: (index: number | null) => boolean;
  onClickOptions: (index: number | null) => boolean;
  isFileDialogActive?: boolean;
  sx?: SxProps,
};

const Picture = memo(({ source, index, onClick, onClickOptions, sx }: PictureProps) => {
  const [loading, setLoading] = useState(Boolean(source));
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (loading) {
      timeout.current = setTimeout(() => setLoading(false), 5000);
    }

    // Remove listeners and clear timeout to avoid setting state when unmounted.
    return () => {
      clearTimeout(timeout.current as NodeJS.Timeout);
      window.removeEventListener('focus', onceFocused);
    };
  }, []);

  const onceFocused = useCallback(() => {
    // Remove this event listener to ensure it's only called once after input.click()
    window.removeEventListener('focus', onceFocused);
    timeout.current = setTimeout(() => setLoading(false), 1000);
  }, []);

  const handleClick = useCallback(() => {
    if (onClick?.(source ? index : null) && !isIOS()) {
      setLoading(true);

      // Listen for the main window to regain focus
      window.addEventListener('focus', onceFocused);
    }
  }, [onClick]);

  const handleClickOptions = useCallback(() => {
    if (onClickOptions?.(source ? index : null) && !isIOS()) {
      setLoading(true);
    }
  }, [onClickOptions]);

  const handleLoad = useCallback(() => {
    setLoading(false);
  }, []);

  const handleError = useCallback(() => {
    setLoading(false);
  }, []);

  return (
    <Box sx={{ position: 'relative', ...sx }}>
      <ResponsiveImage  
        component={source?.uri ? Box : Button}
        className="drag-handle"
        borderRadius={2}
        draggable={false}
        src={source?.uri}
        disabled={loading}
        onClick={handleClick}
        onLoad={handleLoad}
        onError={handleError}
        sx={{
          background: 'rgba(255, 255, 255, 0.09)',
          transition: 'opacity 320ms ease 0s',
          ...loading ? { opacity: 0.25 } : null,
          ...source?.file ? { opacity: 0.25 } : null,
          ...!source?.uri && {
            background: 'rgba(255, 255, 255, 0.05)',
            '&:after': {
              content: '""',
              display: 'flex',
              fontWeight: 700,
              color: '#89696D',
              width: '100%',
              height: '100%',
              borderRadius: 'inherit',
              justifyContent: 'center',
              alignItems: 'center',
              border: 'dashed 2px #89696D',
            }
          },
        }}
      />
      {!loading && (
        <Button
          variant="contained"
          onClick={handleClickOptions}
          disabled={loading}
          sx={[button, {
            ...loading ? { opacity: 0.5 } : null,
            ...source?.uri && {
              color: '#000',
              background: '#fff',
              '&:hover': {
                background: '#ddd',   
              },
            },
          }]}
        >
          <AddIcon
            sx={{
              fontSize: 16,
              strokeWidth: 2,
              ...source?.uri ? {
                fill: 'black',
                stroke: 'black',
                transform: 'rotate(45deg)'
              } : {
                stroke: 'white',
              }
            }}
          />
        </Button>
      )}
      <UploadProgressPieChart
        size={32}
        key={source?._id}
        uploadId={source?._id}
      >
        {loading && (
          <Box sx={spinner}>
            <CircularProgress sx={{ color: 'white' }} size={24} />
          </Box>
        )}
      </UploadProgressPieChart>
    </Box>
  );
});

function PictureField<FieldValue = any, InputValue = FieldValue>({
  name,
  description,
  ...props
}: PictureFieldProps<FieldValue, InputValue>): JSX.Element {
  const maxFiles = 3
  const nextIndex = useRef(0);
  const form = useForm('PictureField');
  const { input } = useField(name);

  const pictures = input.value || [];
  const placeholders = Array.from(new Array(maxFiles - pictures.length));

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const files = await Promise.all(acceptedFiles.map(async (file, i) => {
      const index = nextIndex.current + i;
      if (index < maxFiles) {
        // Upload file
        const uploadId = await startUpload(file, (_, res) => {
          if (res) {
            // Update input with uploaded image.
            form.change(`${name}.${index}`, res);
          } else {
            // Revert to old value in cache if upload failed.
            form.change(`${name}.${index}`, pictures?.[index] || null);
          }
        });

        return {
          _id: uploadId,
        };
      }
    }));

    // Set local images to input.
    input.onChange(mergePictures(pictures, files, nextIndex.current));
  }, [pictures]);

  const onDragEnd = useCallback((result: any) => {
    if (Number.isInteger(result.source?.index) && Number.isInteger(result.destination?.index)) {
      const items = reorder(
        pictures,
        result.source.index,
        result.destination.index
      );
      input.onChange(items);
    }
  }, [pictures]);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    multiple: true,
    minSize: 5000,
    maxSize: 20000000,
    noKeyboard: true,
    noClick: true,
    useFsAccessApi: false,
    accept: { 'image/*': ['.png', '.jpeg', '.jpg', '.webp', '.heic', '.heif'] },
  });

  const handleClick = useCallback((index?: number | null) => {
    // Save the current index the user clicked on.
    if (index === null) {
      nextIndex.current = pictures.length;
    } else if (typeof index === 'number') {
      nextIndex.current = index;
    }
    // Open file picker.
    open();
    // Return true to start loading indication on picture/placeholder.
    return true;
  }, [open, pictures]);

  const handleClickOptions = useCallback(async (index?: number | null) => {
    if (index === null) {
      return handleClick(index);
    } else if (typeof index === 'number') {
      // Remove picture.
      const picture = pictures?.[index];
      if (picture?._id) {
        await deletePictureUpload(picture._id);
        input.onChange(deleteItemsForIds(pictures, [picture._id]));
      }
    }

    return false;
  }, [pictures, handleClick]);

  const renderPicture = useCallback((picture: PictureSource, index: number) => {
    const key = picture._id || `${index}`;
    return (
      <Draggable
        key={key}
        draggableId={key}
        index={index}
      >
        {(provided: any, snapshot: any) => (
          <Box
            ref={provided.innerRef}
            key={key}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            sx={{
              flex: 1,
              pr: 2,
              ...provided.draggableProps.style,
            }}
          >
            <Picture
              key={key}
              index={index}
              source={picture}
              onClick={handleClick}
              onClickOptions={handleClickOptions}
              sx={{
                transition: 'transform 120ms ease 0s',
                ...snapshot.isDragging && {
                  transform: 'scale(1.1)',
                },
              }}
            />
          </Box>
        )}
      </Draggable>
    );
  }, [handleClick, handleClickOptions]);

  const renderPlaceholder = useCallback((_: any, i: number) => {
    const index = i + pictures.length
    const key = `${index}`;
    return (
      <Box key={key} sx={{ flex: 1, pr: 2, opacity: isDragActive ? 0 : 1 }}>
        <Picture 
          index={index}
          onClick={handleClick} 
          onClickOptions={handleClickOptions}
        />
      </Box>
    );
  }, [pictures, isDragActive, handleClick]);

  return (
    <FormControl variant="filled" margin="normal" sx={{ '&:first-of-type': { mt: 0 }, '&:last-of-type': { mb: 0 } }}>
      <Typography component="label" variant="h6" sx={{ mb: 0.5, fontWeight: 700 }}>{props?.label}</Typography>
      {description && <Typography variant="caption">{description}</Typography>}
      <Box {...getRootProps()} sx={[container, { position: 'relative' }]}>
        <input {...getInputProps()} />
        <DragDropContext onDragEnd={onDragEnd}>
          <StrictModeDroppable droppableId="droppable" direction="horizontal">
            {(provided: any) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={[wrapper, { flex: pictures.length }]}
              >
                {pictures.map(renderPicture)}
                {provided.placeholder}
              </Box>
            )}
          </StrictModeDroppable>
        </DragDropContext>
        {placeholders.length > 0 && (
          <Box sx={[wrapper, { flex: placeholders.length }]}>
            {placeholders.map(renderPlaceholder)}
          </Box>
        )}
        {isDragActive && (
          <Box sx={{
            position: 'absolute',
            width: 'calc(100% - 16px)',
            height: 'calc(100% - 24px)',
            top: 12,
            background: 'rgba(255, 255, 255, 0.09)',
            borderRadius: 2,
            border: 'dashed 2px white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <CloudUploadIcon fontSize="large" />
          </Box>
        )}
      </Box>
    </FormControl>
  );
}

export default PictureField;
