import { useTranslation } from 'react-i18next';
import Body from '../components/Body';
import { Helmet } from 'react-helmet-async';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Image from '../components/Image';
import Section from '../components/Section';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { track } from '../helpers/mixpanel';
import useLocalStorage from '../helpers/useLocalStorage';

function detectMob() {
  return ('ontouchstart' in document.documentElement);
}

export default function TrainingBooking() {
  const { t } = useTranslation();

  const pageTitle = t('training_booking.page_title', 'Hemligheten till kärleken: 4 steg till din drömpartner');
  const title = t('training_booking.title', 'Boka en tid med oss');
  const description = t('training_booking.description', 'Välj en tid som passar bäst för dig för att ha ett samtal med någon i vårt team:');
  
  const features = [
    {
      name: 'SVD',
      width: 132 / 2,
      icon: '/svd.png',
    },
    {
      name: 'Dagens Nyheter',
      width: 115 / 2,
      icon: '/dn.png',
    },
    {
      name: 'Elle',
      width: 138 / 2,
      icon: '/elle.png',
    },
    {
      name: 'Breakit',
      width: 205 / 2,
      icon: '/breakit.png',
    },
    {
      name: 'Financial Times',
      width: 464 / 2,
      icon: '/ft.png',
      fill: true,
    },
    {
      name: 'SVT',
      width: 112 / 2,
      icon: '/svt.png',
    },
    {
      name: 'DI Weekend',
      width: 211 / 2,
      icon: '/diweekend.png',
    },
    {
      name: 'Må Bra',
      width: 211 / 2,
      icon: '/mabra.png',
    },
    {
      name: 'BBC',
      width: 192 / 2,
      icon: '/bbc.png',
    },
    {
      name: 'Sveriges Radio',
      width: 350 / 2,
      icon: '/sr.png',
      fill: true,
    },
  ];

  const [showForm, setShowForm] = useState(false);
  const [formValues] = useLocalStorage('training-form', {});

  useCalendlyEventListener({
    onDateAndTimeSelected: () => {
      track("book_call_time_selected")
      setShowForm(true);
    },
    onEventTypeViewed: () => {
      track("book_call_calendar_viewed");
      setShowForm(false);
    },
    onEventScheduled: async () => {
      track("book_call_scheduled");
    },
  });

  return (
    <Box
      sx={{
        backgroundColor: '#38191e',
        ...showForm && detectMob() && {
          height: '100%',
          overflow: showForm && detectMob() ? 'hidden' : 'visible'
        },
      }}
    >
      <Helmet>
        <title>Relate | {pageTitle}</title>
      </Helmet>
      <Box sx={{
        display: 'flex',
        maxWidth: 800,
        margin: '0 auto',
      }}>        
        <Box
          sx={{
            px: { xs: 3, sm: 6 },
            py: { xs: 4, sm: 6 },
            pb: { xs: 8, sm: 12 },
            textAlign: 'center',
            em: { color: 'white', fontWeight: 'bold' },
          }}
        >
          <Image src="/logo.png" sx={{ width: { xs: 64, sm: 100 }, mb: { xs: 2, sm: 4 } }} />
          <Box sx={{ pb: 2 }}>
            <Typography component="h1" variant="h1" sx={{ mb: 3, textTransform: 'uppercase', fontSize: { md: 80 } }}>{title}</Typography>
            <Typography variant="body1">{description}</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ background: '#9C4452', textAlign: 'center', p: 0.5 }}></Box>
      <Body>
        <Box>
          <Box id="form" sx={{
            borderRadius: { xs: 4, md: 8 },
            mx: { xs: 2, md: 0 },
            mb: { xs: '-64px', sm: '-128px' },
            position: 'relative',
            top: { xs: -64, sm: -128 },
          }}>
            <InlineWidget
              url="https://calendly.com/relate-matchmaking/matchmaking-konsultation"
              pageSettings={{
                hideEventTypeDetails: true,
                hideLandingPageDetails: true,
                hideGdprBanner: true,
              }}
              utm={{
                utmContent: 'none',
                utmMedium: 'quiz',
                utmSource: 'app.relate-matchmaking.com',
              }}
              prefill={{
                email: formValues?.email,
                name: [formValues?.firstName, formValues?.lastName].join(' ').trim(),
                date: new Date(Date.now() + 86400000)
              }}
              styles={{
                width: '100vw',
                height: showForm ? (detectMob() ? 2400 : 1950) : (detectMob() ? 1100 : 700),
                position: 'relative',
                left: '50%',
                transform: 'translate(-50%)',
                marginBottom: -32,
                ...showForm && detectMob() && {
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  height: '100%',
                  width: '100%',
                  transform: null,
                }
              }}
            />
          </Box>
          <Section>
            <Typography component="h6" variant="h6" textAlign="center" textTransform="uppercase" fontStyle="italic" fontFamily="'DM Sans', sans-serif">
              {t('featured_in.title', 'Featured i')}
            </Typography>
            <Box sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
            }}>
              <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', maxWidth: 320, margin: '0 auto' }}>
                {features.slice(0, 5).map((item) => (
                  <Box
                    key={item.name} 
                    sx={{
                      flex: 1,
                      flexBasis: item?.fill ? '100%' : '50%',
                      width: item?.fill ? '100%' : '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 80,
                    }}
                  >
                    <Box
                      component="img"
                      src={item.icon}
                      sx={{
                        width: item.width,
                      }}
                      title={item.name}
                    />
                  </Box>
                ))}
              </Box>
              <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', maxWidth: 320, margin: '0 auto' }}>
                {features.slice(5).map((item) => (
                  <Box
                    key={item.name} 
                    sx={{
                      flex: 1,
                      flexBasis: item?.fill ? '100%' : '50%',
                      width: item?.fill ? '100%' : '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 80,
                    }}
                  >
                    <Box
                      component="img"
                      src={item.icon}
                      sx={{
                        width: item.width,
                      }}
                      title={item.name}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Section>
          <Section sx={{ alignItems: 'center' }}>
            <Image src="/logo.png" sx={{ width: 84 }} />
            <Typography variant="caption">
              {t('slogan')}
            </Typography>
          </Section>
          <Section />
        </Box>
      </Body>
    </Box>
  );
}
