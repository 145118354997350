import { useCallback, useEffect } from "react";

const parse = (value?: string | null) => {
  if (!value) return null;
  try {
    return JSON.parse(value);
  } catch (err) {
    return null;
  }
};

function useLocalStorage<T>(key: string, initialValue: T, saveInitialValue?: boolean) {
  // Retrieve the stored value from local storage or use the provided initial value
  const storedValue = localStorage.getItem(key);
  const parsed = parse(storedValue);
  const initial: T = storedValue && parsed ? parsed : initialValue;

  // Create a function to set the value in local storage
  const setValue = useCallback((newValue: T) => {
    // Update the local storage
    localStorage.setItem(key, JSON.stringify(newValue));
  }, [key]);

  useEffect(() => {
    if (saveInitialValue && !parsed) {
      setValue(initialValue)
    }
  }, [parsed, setValue]);

  // Return the set function and the current value
  return [initial, setValue] as const;
}

export default useLocalStorage;
