import Box from '@mui/material/Box';
import { keyframes } from '@emotion/react';
import { useUploadProgress } from '../helpers/UploadProgress';

const fade = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

type UploadProgressPieChartProps = {
  uploadId?: string | null,
  size?: number,
  children: any,
};

const container = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  pointerEvents: 'none',
  borderRadius: 2,
  opacity: 0,
  animation: `320ms linear 500ms forwards ${fade}`,
};

function UploadProgressPieChart({ uploadId = null, size = 32, children }: UploadProgressPieChartProps) {
  const progress = useUploadProgress(uploadId);

  return progress >= 1 ? children : (
    <Box sx={container}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 50 50"
        fill="none"
        
      >
        <circle
          cx={25}
          cy={25}
          r={12.5}
          stroke="#fffc"
          strokeWidth={25}
          strokeDashoffset={Math.max(0, Math.min(1, progress)) * -25 * Math.PI}
          strokeDasharray={`${(25 * Math.PI)} ${25 * Math.PI}`}
          transform="rotate(-90) translate(-50)"
          fill="none"
          style={{
            transition: 'all 320ms ease 0s',
          }}
        />
      </svg>
    </Box>
  );
}

export default UploadProgressPieChart;
