import i18n, { BackendModule, ReadCallback } from 'i18next';
import { initReactI18next } from "react-i18next";
import intervalPlural from 'i18next-intervalplural-postprocessor';
import dayjs from 'dayjs';

let fs: any = null;
if (process.env.NODE_ENV === 'development') {
  import('vite-plugin-fs/browser').then(devOnlyModule => {
    fs = devOnlyModule.default;
  });
}

async function translate(q: string[], source: string, target: string) {
  const apiKey = 'AIzaSyDwG1uVdUWSZEkZDVig7H-p6lndHuVz6qg'; // Replace with your Google Cloud API Key
  const apiUrl = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`;
  const requestBody = { q, source, target, format: 'text' };
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  };

  try {
    const response = await fetch(apiUrl, requestOptions);
    if (!response.ok) {
      throw new Error('Translation request failed');
    }

    const data = await response.json();
    return data.data.translations.map(({ translatedText }: { translatedText: string }) => translatedText);
  } catch (error) {
    console.error('Translation error:', error);
    return null;
  }
}

export interface FetchOptions {
  enabled: boolean
}

type CreateBatchProps = { batchFn: (queue: any[]) => void, batchTimeout?: number };
const createBatch = ({ batchFn = () => null, batchTimeout = 300 }: CreateBatchProps) => {
  let t: any;
  const queue: any[] = [];

  const exec = async () => {
    const batchQueueItems = queue.splice(0, queue.length);
    batchFn(batchQueueItems);
  };

  return (props: object) => new Promise(resolve => {
    queue.push({ ...props, resolve });
    clearTimeout(t);
    t = setTimeout(exec, batchTimeout);
  });
};

const supportedLngs = ['sv', 'en'];

function merge(obj: any, path: string, value: string) {
  const keys = path.split('.');
  let current = obj;

  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    if (!current[key] || typeof current[key] !== 'object') {
      current[key] = {};
    }
    current = current[key];
  }

  const finalKey = keys[keys.length - 1];
  current[finalKey] = value;

  return obj;
}

class CustomBackend implements BackendModule<FetchOptions> {
  constructor() {
    if (process.env.NODE_ENV === 'development') {
      this.batch = createBatch({
        batchFn: async (queue: any[]) => {
          const groups = queue.reduce((acc, item) => {
            const id = `${item.source}-${item.target}`;
            return {
              ...acc,
              [id]: [
                ...acc?.[id] || [],
                item,
              ],
            };
          }, {});

          Object.keys(groups).forEach(async (key: string) => {
            const input = groups[key].map((item: any) => item.defaultValue);
            const [source, target] = key.split('-');
            const path = `i18n/${target}.json`;
            let cache;
            try {
              const content = await fs?.readFile(path).catch(() => '{}') || '{}';
              cache = JSON.parse(content);
            } finally {
              // Translate using google translate.
              const translations = source !== target ? await translate(input, source, target) : null;
              // Organize translations.
              const data = groups[key].reduce((gacc: any, item: any, i: number) => {
                const value = translations?.[i] || item.defaultValue;
                item.resolve(value);
                return {
                  ...gacc,
                  [item.namespace]: merge(gacc?.[item.namespace] || {}, item.key, value)
                }
              }, cache);
              // Save to file.
              fs?.writeFile(path, JSON.stringify(data, null, 2));
            }
          }, []);
        },
      });
    }
  }

  type = 'backend'

  static type = 'backend'

  async read(language: string, namespace: string, callback: ReadCallback) {
    const result = await fetch(`/i18n/${language}.json`).then(res => res.json()).catch(() => null);
    callback(null, result?.[namespace] || {});
    dayjs.locale(language);
  }

  async create(languages: string[], namespace: string, key: string, defaultValue: string) {
    if (process.env.NODE_ENV === 'development') {
      const source = languages?.[0];
      await Promise.all(supportedLngs.map(async target => {
        this.batch({ source, target, namespace, key, defaultValue });
      }));
    }
  }
}

i18n
  .use(intervalPlural)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(CustomBackend)
  .init({
    // debug: true,
    saveMissing: process.env.NODE_ENV === 'development',
    updateMissing: process.env.NODE_ENV === 'development',

    lng: 'sv',
    fallbackLng: 'sv',
    supportedLngs,
    load: 'languageOnly',

    interpolation: {
      escapeValue: false // react already safes from xss
    },
  });

export default i18n;
