import { useEffect, useState } from 'react';

type PermissionState = 'granted' | 'denied' | 'prompt';

type Permission = {
  loading: boolean
  granted: boolean,
  videoStatus?: PermissionState,
  audioStatus?: PermissionState,
};

const usePermission = (): Permission => {
  const [loading, setLoading] = useState(true);
  const [videoStatus, setVideoStatus] = useState<PermissionState>();
  const [audioStatus, setAudioStatus] = useState<PermissionState>();
  const granted = videoStatus === 'granted' && audioStatus === 'granted';

  useEffect(() => {
    const check = async () => {
      try {
        // Permissions API's query method requires a PermissionDescriptor
        const cameraPermissionStatus = await navigator.permissions.query({ name: 'camera' });
        const audioPermissionStatus = await navigator.permissions.query({ name: 'microphone' });

        // Initial state is set based on the current permission status
        setVideoStatus(cameraPermissionStatus.state);
        setAudioStatus(audioPermissionStatus.state);
        setLoading(false);

        // Handler function to update state when permission changes
        const handleCameraChange = () => {
          setVideoStatus(cameraPermissionStatus.state);
        };
        const handleAudioChange = () => {
          setAudioStatus(audioPermissionStatus.state);
        };

        cameraPermissionStatus.addEventListener('change', handleCameraChange);
        audioPermissionStatus.addEventListener('change', handleAudioChange);

        // Clean up the event listener when the component unmounts
        return () => {
          cameraPermissionStatus.removeEventListener('change', handleCameraChange);
          audioPermissionStatus.removeEventListener('change', handleAudioChange);
        };
      } catch (error) {
        setLoading(false);
        // Error handling can be more specific based on the app's needs
        console.error('Error checking camera/microphone permissions:', error);
      }
    };

    // Execute the permission check
    check();
  }, []);

  return { granted, videoStatus, audioStatus, loading };
};

export default usePermission;
