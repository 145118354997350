import gql from 'graphql-tag';

const FinishPictureUploadMutation = gql`
  mutation FinishPictureUploadMutation($userVideoId: ID!) {
    userVideo {
      finishUpload(userVideoId: $userVideoId) {
        _id
        url
      }
    }
  }
`;

export default FinishPictureUploadMutation;
