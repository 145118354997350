import { useField } from 'react-final-form';
import { FormControl, Slider, SliderValueLabel, Typography, styled } from "@mui/material";
import { ReactNode, useMemo } from 'react';
import Notice from '../Notice';

type RangeFieldProps = {
  name: string;
  label?: string;
  description?: string;
  min?: number;
  max?: number;
  step?: number;
  overlap?: number;
  valueLabelFormat: string | ((value: number, index: number) => ReactNode) | undefined;
  info: (value: number[]) => string | null;
};

function getAriaValueText(value: number): string {
  return `${value}`;
}

const style = {
  height: '24px',
  padding: 0,
  marginTop: '32px',
  width: 'calc(100% - 16px)',
  marginLeft: '8px',
  marginRight: '8px',

  '& .MuiSlider-valueLabel': {
    fontSize: 16,
    fontWeight: 'bold',
    top: 0,
    backgroundColor: 'unset',
    color: 'white',
    '&:before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
    height: 4,
  },
  '& .MuiSlider-rail': {
    opacity: 0.25,
    height: 4,
  },
}

/**
 * Gets the number of decimal places of a number.
 * @param num The number to check.
 * @returns The number of decimal places.
 */
function getDecimalPlaces(num: number): number {
  const parts = num.toString().split(".");
  return parts.length > 1 ? parts[1].length : 0;
}

/**
* Rounds a number to a specified number of decimal places.
* @param num The number to round.
* @param precision The number of decimal places to round to.
* @returns The rounded number.
*/
function roundToPrecision(num: number, precision: number): number {
  const factor = Math.pow(10, precision);
  return Math.round(num * factor) / factor;
}

const ValueLabelComponent = styled(SliderValueLabel)(({ index, ownerState }) => {
  const precision = getDecimalPlaces(ownerState.step);
  const value = roundToPrecision(ownerState.value[index], precision);
  return {
    left: value === ownerState.min ? -10 : '',
    right: value === ownerState.max ? -10 : '',
  };
});

type InputRangeFormat = [number, number];
type OutputRangeFormat = {
  min: number;
  max: number;
}

function RangeField({
  name,
  label,
  description,
  min = 0,
  max = 100,
  step = 1,
  overlap = 0,
  valueLabelFormat,
  info,
}: RangeFieldProps): JSX.Element {
  const parse = ([min, max]: InputRangeFormat): OutputRangeFormat => ({ min, max });
  const format = (output: OutputRangeFormat): InputRangeFormat => output?.min && output?.max ? [output.min, output.max] : [min, max];
  const { input } = useField(name, { parse, format });
  const value = Array.isArray(input.value) ? input.value : [min, max];
  const handleChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (event.type === 'mousedown') {
      return;
    }

    const minDistance = overlap * step;
    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], max - minDistance);
        input.onChange([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], min + minDistance);
        input.onChange([clamped - minDistance, clamped]);
      }
    } else {
      input.onChange(newValue as number[]);
    }
  };

  const infoText = useMemo(() => info?.(value), [info, value]);

  return (
    <FormControl variant="filled" margin="normal" sx={{ '&:first-of-type': { mt: 0 }, '&:last-of-type': { mb: 0 } }}>
      {label && <Typography component="label" variant="h6" sx={{ mb: 0.5, fontWeight: 700 }}>{label}</Typography>}
      {description && <Typography variant="caption">{description}</Typography>}
      <Slider
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="on"
        getAriaValueText={getAriaValueText}
        components={{
          ValueLabel: ValueLabelComponent
        }}
        disableSwap
        sx={[style, {
          ...Boolean(infoText) && {
            mb: 2,
          }
        }]}
        valueLabelFormat={valueLabelFormat}
      />
      <Notice info={infoText} />
    </FormControl>
  );
}

export default RangeField;
