import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import MarkdownText from '../../components/MarkdownText';
import Section from '../../components/Section';
import { fadeIn } from './helpers';

const MatchmakerCheck = ({ user, duration = 3 }) => {
  const { t } = useTranslation();
  return (
    <Section
      highlight
      sx={{
        textAlign: 'center',
        em: { color: 'white', fontWeight: 'bold' },
        ...duration > 0 && {
          opacity: 0,
          animation: `${fadeIn} 500ms forwards linear`,
          animationDelay: `${duration + 1}s`,
        },
      }}
    >
      <Typography component="h2" variant="h2">
        {t('matchmaker_check.title', 'Se om du passar för vår matchmaking')}
      </Typography>
      <MarkdownText>
        {t('matchmaker_check.description', 'Baserat på din profil så har vi många potentiella matchningar för dig.\n\nTa reda på om du är lämplig för vår aktiva matchmaking med en personlig matchmaker. Vi accepterar enbart kandidater som vi verkligen tror att vi kan matcha.')}
      </MarkdownText>
      <Box sx={{ pt: 2 }}>
        <Button
          component={Link}
          type="submit"
          size="large"
          variant="contained"
          sx={{ mt: 1, minWidth: 320 }}
          to={`https://relate-app.typeform.com/to/VqVtNuDx#email=${user?.email}&name=${user?.firstName}`}
        >
          {t('matchmaker_check.action', 'Ta reda på om du passar')}
        </Button>
      </Box>
    </Section>
  );
}

export default MatchmakerCheck;
