import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import MarkdownText from '../../components/MarkdownText';
import Section from '../../components/Section';
import { fadeIn } from './helpers';

const MatchmakingProcess = ({ duration }) => {
  const { t } = useTranslation();
  const process = [
    {
      icon: '/one.svg',
      title: t('matchmaker_process_1.title', 'Upptäck'),
      description: t('matchmaker_process_1.description', 'Under första delen av programmet djupdyker vi in i några av matchmaking-världens bästa verktyg och övningar. Tillsammans utforskar vi dina djupaste relationsbehov och får syn på vad som håller dig tillbaka från att hitta kärleken.\n\nReflektionerna och insikterna som kommer ur denna process är otroligt kraftfulla. Det är så vi förbereder dig för kärlek. Ett sätt att bearbeta det som varit, skapa mening, förstå oss själva och vad du behöver.\n\nDenna del lägger grunden för din framtida kärlek.'),
    },
    {
      icon: '/two.svg',
      title: t('matchmaker_process_2.title', 'Utforska'),
      description: t('matchmaker_process_2.description', 'I andra delen skickas du ut på utforskande dejter! Din matchmaker står redo att handplocka minst 2 spännande personer och planerar in minnesvärda dejter år er.\n\nVarje dejt blir en möjlighet att lära känna dig själv ännu bättre och definiera vad du verkligen söker i en partner. Feedback från dessa dejter blir en värdefull byggsten, där du och din matchmaker skapar en tydlig bild av vem din perfekta match är.'),
    },
    {
      icon: '/three.svg',
      title: t('matchmaker_process_3.title', 'Hitta rätt'),
      description: t('matchmaker_process_3.description', 'Efter att ha fördjupat dig i våra fantastiska övningar & verktyg, utforskat spännande dejter med utvalda personer och samlat på dig nya insikter är det dags för den sista delen av programmet. Här zoomar vi in närmare på vem det är du längtar efter – och att hitta rätt.\n\nDin matchmaker gräver fram dina allra mest kompatibla matchningar och ser till att du har det du behöver för att nå din drömrelation.'),
    },
  ];

  return (
    <Section sx={{
      ...duration && {
        opacity: 0,
        animation: `${fadeIn} 500ms forwards linear`,
        animationDelay: `${duration + 1}s`,
      },
    }}>
      <Typography component="h2" variant="h2" textAlign="center">
        {t('matchmaker.process_title', 'Vårt program')}
      </Typography>
      <Typography variant="body2" textAlign="center">
        {t('matchmaker.process_description', 'Programmet är uppdelat i 3 delar som guidar dig till dina allra mest kompatibla matchningar.')}
      </Typography>
      <Box sx={{ textAlign: 'center' }}>
        {process.map((item) => (
          <Box key={item.icon} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 6 }}>
            <Box
              component="img"
              src={item.icon}
              sx={{
                width: 52, height: 52, minWidth: 52, mb: 2,
                borderRadius: 26,
                backgroundAttachment: 'fixed',
                backgroundImage:  'repeating-radial-gradient(circle at 0 0, #230e11 0, transparent 100px), repeating-linear-gradient(#4a362b88, #4a362b44)',
              }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', my: 2 }}>
              <Typography component="h3" variant="h3" sx={{ mb: 0 }}>{item.title}</Typography>
            </Box>
            <MarkdownText>{item.description}</MarkdownText>
          </Box>
        ))}
      </Box>
    </Section>
  );
}

export default MatchmakingProcess;
