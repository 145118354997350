import Block from '../../components/Block';
import WheelAnimation from './WheelAnimation';

const UserWheel = ({ user, duration }) => {
  return (
    <Block
      sx={{
        p: 0,
        mt: { xs: 4, sm: 8 },
        background: 'none',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <WheelAnimation duration={duration} user={user} />
    </Block>
  );
};

export default UserWheel;
