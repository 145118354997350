import { useTranslation } from 'react-i18next';
import LoginForm from '../forms/LoginForm';
import { useAuth } from '../data/auth/token';
import LoggedInForm from '../forms/LoggedInForm';
import Body from '../components/Body';
import Heading from '../components/Heading';
import { Box, Typography } from '@mui/material';
import Image from '../components/Image';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { track } from '../helpers/mixpanel';

export default function Signup() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const profileExists = useMemo(() => {
    // if profile exists.
    if (user?.firstName && user?.lastName) {
      return true;
    }
    // Show login if not logged in.
    return false;
  }, [user]);
  useEffect(() => {
    if (user?.appliedAt) {
      // Show home if profile is already applied.
      track('already_applied');
      navigate('/');
    }
    // Skip profile selection if none exist already.
    if (user && !user?.firstName && !user?.lastName) {
      // Navigate to application if logged in.
      navigate('/application');
    }
  }, [user]);
  return (
    <Body>
      <Heading
        title={t('signup.title', 'Ansök om medlemskap')}
        description={t('signup.description', 'Ta första steget till dina mest kvalitativa dejter. Våra fantastiska matchmakers jobbar för att du skall slippa svajpa och slippa gå på fler dåliga dejter. Med ett medlemsskap så gör du din profil en gång och sedan gör vi resten av jobbet!')}
      />
      {profileExists ? (
        <LoggedInForm nextPath="/application" />
      ) : (
        <LoginForm terms title={t('signup.form_title', 'Kom igång')} />
      )}
      <Box sx={{ display: 'flex', alignItems: 'center', px: 2, py: 4, mb: 2, mt: 4, flexDirection: 'column', borderTop: 'solid 1px #38191E' }}>
        <Image src="/logo.png" sx={{ width: 84 }} />
        <Typography variant="caption">
          {t('slogan')}
        </Typography>
      </Box>
    </Body>
  );
}
