import { FormApi } from 'final-form';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type CountDownResetFieldProps = {
  timer?: number,
  form: FormApi,
  field: string,
  children?: JSX.Element | string | null,
};

const CountDownResetField = ({ timer, form, field, children }: CountDownResetFieldProps) => {
  const { t } = useTranslation();
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (timer) {
      const duration = timer - +Date.now();
      setCount(Math.floor(duration / 1000));
      clearInterval(interval.current as NodeJS.Timeout);
      if (duration > 0) {
        interval.current = setInterval(() => {
          const delta = timer - +Date.now();
          if (delta > 0) {
            setCount(Math.floor(delta / 1000) + 1);
          } else {
            clearInterval(interval.current as NodeJS.Timeout);
            setCount(0);
            if (form && field) {
              // reset form with same values.
              const values = form.getState()?.values || {};
              form.restart(values);
            }
          }
        }, 1000);
      }
    }
    return () => clearInterval(interval.current as NodeJS.Timeout);
  }, [timer]);

  if (count > 0) {
    return t('common.actions.send_again', { count, defaultValue: 'Skicka igenom om {{count}} sekunder' });
  }
  return children;
};

export default React.memo(CountDownResetField);
