import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import { fadeIn } from './helpers';

export type ActionType = {
  label: string;
  help?: string;
  primary?: boolean;
  onClick: () => void;
  href?: string;
};

const Actions = ({ duration = 3, actions }) => {
  const renderAction = useCallback((action: ActionType) => {
    return (
      <div key={action.label}>
        <Button
          {...action?.href && {
            component: Link,
            to: action.href,
          }}
          type="submit"
          size="large"
          fullWidth
          variant={action?.primary ? 'contained' : (action?.secondary ? 'outlined' : 'text')}
          onClick={action.onClick}
          sx={{ minWidth: 320 }}
        >
          {action.label}
        </Button>
        {action?.help && (
          <Typography sx={{ mt: 1 }} variant="caption" component="div" textAlign="center">
            {action.help}
          </Typography>
        )}
      </div>
    )
  }, []);

  return (
    <Box
      sx={{
        px: 2,
        my: 2,
        gap: 1,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        ...duration > 0 ? {
          opacity: 0,
          animation: `${fadeIn} 500ms forwards linear`,
          animationDelay: `${duration + 1}s`,
        } : {},
      }}
    >
      {(actions || []).map(renderAction)}
    </Box>
  );
}

export default Actions;
