import { gql } from "@apollo/client";
import UserViewerFragment from "../fragments/UserViewerFragment";

const UpdateUserProfileMutation = gql`
  mutation UpdateUserProfileMutation($fields: InputUserProfile!) {
    updateUserProfile(fields: $fields) {
      validationErrors {
        field
        message
      }
      user {
        ...UserViewerFragment
      }
    }
  }
  ${UserViewerFragment}
`;

export default UpdateUserProfileMutation;
