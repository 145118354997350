import {useState} from 'react';
import { useInterval } from './utils';
export const DEFAULT_BLINKING_SPEED = 500;
export const DEFAULT_CURSOR_SYMBOL = '_';

export function Cursor({
  cursorSymbol = DEFAULT_CURSOR_SYMBOL,
  delay = DEFAULT_BLINKING_SPEED,
}) {
  const [visibleFlag, setFlag] = useState(true);
  useInterval(() => setFlag((flag) => !flag), delay);
  return visibleFlag ? cursorSymbol : '';
}
