import { useTranslation } from 'react-i18next';
import Body from '../components/Body';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Typography, keyframes } from '@mui/material';
import MarkdownText from '../components/MarkdownText';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { useCallback, useRef, useState } from 'react';
import Image from '../components/Image';
import LoginDialog from '../forms/LoginDialog';
import { useAuth } from '../data/auth/token';
import Section from '../components/Section';
import Block from '../components/Block';

const question = {
  borderBottom: 'solid 1px #fff2',
  my: 1,
};

const answer = {
  transition: 'all 300ms ease 0s',
  overflow: 'hidden',
  boxSizing: 'border-box',
};

const button = {
  px: 0,
  py: 2,
  fontStyle: 'normal',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  borderRadius: 0,
  textAlign: 'left',

  '.MuiButton-endIcon': {
    flex: 1,
    justifyContent: 'flex-end',
    opacity: 0.5,
  },
};

type QuestionProps = {
  item: {
    title: string;
    description: string;
  };
};

const Question = ({ item }: QuestionProps) => {
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>();

  const handleClick = useCallback(() => {
    if (ref.current?.offsetHeight) {
      setHeight(ref.current.offsetHeight);
    }
    setOpen(state => !state);
  }, []);

  return (
    <Box sx={question}>
      <Typography
        variant="body1"
        sx={button}
        fullWidth
        component={Button}
        onClick={handleClick}
        endIcon={(
          <KeyboardArrowDownIcon sx={{
            transition: 'transform 300ms ease 0s',
            transform: open? 'rotate(180deg)': '',
          }} />
        )}
      >{item.title}</Typography>
      <Box sx={[answer, { maxHeight: open ? height : 0 }]}>
        <Box ref={ref} sx={{ pb: 2 }}>
          <MarkdownText>{item.description}</MarkdownText>
        </Box>
      </Box>
    </Box>
  )
}

const AnimatedTitle = ({ text }) => {
  const words = text.split(' ');

  const fadeIn = keyframes`
    to {
      opacity: 1;
    }
  `;

  return (
    <Typography
      component="h1"
      variant="h1"
      sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
    >
      {words.map((word, index) => (
        <Box
          key={word + index}
          component="span"
          sx={{ 
            opacity: 0,
            ml: 1,
            animation: `${fadeIn} 1s forwards`, 
            animationDelay: `${(0.1 * index) + (index > 0 ? 1 : 0) * 2 }s`
          }}
        >
          {word}
        </Box>
      ))}
    </Typography>
  );
};

const WheelAnimation = ({ type, types }: { type: string, types: string[] }) => {
  const { t} = useTranslation();
  const index = types.indexOf(type);
  const start = ((index + 2) / 4) * 360;

  const totalDots = 40;
  const dots = Array.from(new Array(totalDots));

  const w1 = 256;
  const w2 = 420;

  return (
    <Box>
      <Box sx={{
        width: { xs: `${w1}px`, sm: `${w2}px` },
        height: 0,
        paddingBottom: { xs: `${w1}px`, sm: `${w2}px` },
        margin: { xs: '16px auto 0', sm: '32px auto 0' },
        position: 'relative',
      }}>
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: { xs: `${w1}px`, sm: `${w2}px` },
          height: 0,
          paddingBottom: { xs: `${w1}px`, sm: `${w2}px` },
          margin: '0 auto',
          borderRadius: { xs: `${w1/2}px`, sm: `${w2}px` },
          transformOrigin: 'center',
          backgroundImage: 'url(/slices.svg)',
          backgroundPosition: 'center center',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          transform: `rotateZ(0deg)`,
          animation: `${keyframes`
            0% {
              transform: rotateZ(${start}deg);
            }
            100% {
              transform: rotateZ(${start + 360}deg);
            }
          `} 5s cubic-bezier(0.595, 0.945, 0.650, 0.985) forwards`,
        }}>
          {dots.map((_, i, arr, p = i / arr.length) => (
            <Box key={i} sx={{
              width: `calc(50% - 8px)`,
              height: 4,
              position: 'absolute',
              top: 'calc(50% - 2px)',
              left: '8px',
              transformOrigin: '100% 50%',
              transform: `rotateZ(${start + p * 360}deg)`,
            }}>
              <Box key={i} sx={{
                width: '4px',
                height: '4px',
                background: '#220E11',
                borderRadius: '50%',
              }} />
            </Box>
          ))}
        </Box>
        <Box sx={{
          position: 'absolute',
          bottom: { xs: '-4px', sm: '-8px' },
          left: { xs: `calc(${w1/2}px - 3px)`, sm: `calc(${w2/2}px - 4px)` },
          width: { xs: '6px', sm: '8px' },
          height: '0',
          paddingBottom: { xs: '18px', sm: '24px' },
          margin: '0 auto',
          backgroundImage: 'url(/pointer.svg)',
          backgroundPosition: 'center center',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          transformOrigin: { xs: '3px 15px', sm: '4px 20px' },
          transform: `rotateZ(-64deg)`,
          animation: `${keyframes`
            0% {
              transform: rotateZ(-64deg);
            }
            0% {
              transform: rotateZ(-64deg);
            }
            2.5% {
              transform: rotateZ(-48deg);
            }
            2.4996875% {
              transform: rotateZ(-64deg);
            }
            5% {
              transform: rotateZ(-46.8deg);
            }
            4.999375% {
              transform: rotateZ(-64deg);
            }
            7.5% {
              transform: rotateZ(-45.6deg);
            }
            7.4990625% {
              transform: rotateZ(-64deg);
            }
            10% {
              transform: rotateZ(-44.4deg);
            }
            9.99875% {
              transform: rotateZ(-64deg);
            }
            12.5% {
              transform: rotateZ(-43.2deg);
            }
            12.4984375% {
              transform: rotateZ(-64deg);
            }
            15% {
              transform: rotateZ(-42deg);
            }
            14.998125% {
              transform: rotateZ(-64deg);
            }
            17.5% {
              transform: rotateZ(-40.8deg);
            }
            17.4978125% {
              transform: rotateZ(-64deg);
            }
            20% {
              transform: rotateZ(-39.6deg);
            }
            19.9975% {
              transform: rotateZ(-64deg);
            }
            22.5% {
              transform: rotateZ(-38.4deg);
            }
            22.4971875% {
              transform: rotateZ(-64deg);
            }
            25% {
              transform: rotateZ(-37.2deg);
            }
            24.996875% {
              transform: rotateZ(-64deg);
            }
            27.500000000000004% {
              transform: rotateZ(-36deg);
            }
            27.496562500000003% {
              transform: rotateZ(-64deg);
            }
            30% {
              transform: rotateZ(-34.8deg);
            }
            29.99625% {
              transform: rotateZ(-64deg);
            }
            32.5% {
              transform: rotateZ(-33.6deg);
            }
            32.4959375% {
              transform: rotateZ(-64deg);
            }
            35% {
              transform: rotateZ(-32.4deg);
            }
            34.995625% {
              transform: rotateZ(-64deg);
            }
            37.5% {
              transform: rotateZ(-31.200000000000003deg);
            }
            37.4953125% {
              transform: rotateZ(-64deg);
            }
            40% {
              transform: rotateZ(-30deg);
            }
            39.995% {
              transform: rotateZ(-64deg);
            }
            42.5% {
              transform: rotateZ(-28.799999999999997deg);
            }
            42.4946875% {
              transform: rotateZ(-64deg);
            }
            45% {
              transform: rotateZ(-27.6deg);
            }
            44.994375% {
              transform: rotateZ(-64deg);
            }
            47.5% {
              transform: rotateZ(-26.4deg);
            }
            47.4940625% {
              transform: rotateZ(-64deg);
            }
            50% {
              transform: rotateZ(-25.200000000000003deg);
            }
            49.99375% {
              transform: rotateZ(-64deg);
            }
            52.5% {
              transform: rotateZ(-24deg);
            }
            52.4934375% {
              transform: rotateZ(-64deg);
            }
            57.49999999999999% {
              transform: rotateZ(-21.599999999999998deg);
            }
            57.49281249999999% {
              transform: rotateZ(-64deg);
            }
            62.5% {
              transform: rotateZ(-19.200000000000003deg);
            }
            62.4921875% {
              transform: rotateZ(-64deg);
            }
            67.5% {
              transform: rotateZ(-16.799999999999997deg);
            }
            67.4915625% {
              transform: rotateZ(-64deg);
            }
            72.5% {
              transform: rotateZ(-14.400000000000006deg);
            }
            72.4909375% {
              transform: rotateZ(-64deg);
            }
            77.5% {
              transform: rotateZ(-12deg);
            }
            77.4903125% {
              transform: rotateZ(-64deg);
            }
            94% {
              transform: rotateZ(-64deg);
            }
            94.5% {
              transform: rotateZ(0deg);
            }
            100% {
              transform: rotateZ(0deg);
            }
          `} 5s cubic-bezier(.16,.87,.47,.86) forwards`,
        }} />
      </Box>
      <Box sx={{
        width: '100%',
        height: { xs: '80px', sm: '100px' },
        position: 'relative',
        margin: '8px auto 0',
        transformOrigin: 'center center',
        overflow: 'hidden',
      }}>
        {types.map((type, i) => {
          const p = i / types.length;
          const angle = (p * 360 + 180) % 360;
          return (
            <Box key={type} sx={{
              width: '100%',
              position: 'absolute',
              transformOrigin: 'center',
              transform: {
                xs: `translateY(-${w1/2 + 8}px) rotateZ(${start - angle}deg)`,
                sm: `translateY(-${w2/2 + 8}px) rotateZ(${start - angle}deg)`,
              },
              animation: {
                xs: `${keyframes`
                  0% {
                    transform: translateY(-${w1/2 + 8}px) rotateZ(${start - angle}deg);
                  }
                  100% {
                    transform: translateY(-${w1/2 + 8}px) rotateZ(${start - angle + 1800}deg);
                  }
                `} 5s cubic-bezier(0.595, 0.945, 0.650, 0.985) forwards`,
                sm: `${keyframes`
                  0% {
                    transform: translateY(-${w2/2 + 8}px) rotateZ(${start - angle}deg);
                  }
                  100% {
                    transform: translateY(-${w2/2 + 8}px) rotateZ(${start - angle + 1800}deg);
                  }
                `} 5s cubic-bezier(0.595, 0.945, 0.650, 0.985) forwards`
              },
            }}>
              <Typography sx={{
                color: '#fff',
                position: 'absolute',
                width: '100%',
                textAlign: 'center',
                fontSize: '2.75rem',
                fontFamily: `'DM Serif Display', sans-serif`,
                fontWeight: 300,
                lineHeight: 1.167,
                transform: { xs: `translateY(${w1/2 + 16}px)`, sm: `translateY(${w2/2 + 24}px)` },
              }}>
                {t(`AttachmentTheory.types.${type}`)}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default function AttachmentTheory() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const auth = useAuth();
  const [showLogin, setShowLogin] = useState(false);

  const name = searchParams.get('name');

  const types = [
    'secure',
    'avoidant',
    'anxious',
    'disorganized',
  ];
  const type = types?.[parseInt(searchParams.get('type') || '1') - 1] || types[0];
  const pageTitle = t('AttachmentTheory.page_title', 'Ditt resultat för anknytningstestet');
  const description = t(`AttachmentTheory.result.${type}`);

  const title = name
    ? t('AttachmentTheory.title', '{{name}}, din anknytning verkar vara...', { name })
    : t('AttachmentTheory.plain_title', 'Din anknytning verkar vara...');

  const fadeIn = keyframes`
    to {
      opacity: 1;
    }
  `;

  const benefits = [
    {
      icon: '/hand.svg',
      description: t('membership_benefit_1.description', 'Chans att matchas och introduceras till rätt personer för just dig'),
    },
    {
      icon: '/heart.svg',
      description: t('membership_benefit_2.description', 'Vid en introduktion planerar vi dejten åt er så ni kan fokusera på att lära känna varandra'),
    },
    {
      icon: '/person.svg',
      description: t('membership_benefit_3.description', 'En diskret profil som endast våra matchmakers har tillgång till'),
    },
    {
      icon: '/flash.svg',
      description: t('membership_benefit_4.description', 'Slipp allt svajpande och chattande, gå direkt på bra dejter istället'),
    },
  ];

  const process = [
    {
      icon: '/one.svg',
      title: t('AttachmentTheory_membership_process_1.title', 'Svara på våra frågor'),
      description: t('AttachmentTheory_membership_process_1.description', 'Svara på frågor om vem du är och vem du längtar efter. Det är bästa sättet för våra matchmakers att veta vem som är rätt person för just dig.'),
    },
    {
      icon: '/two.svg',
      title: t('AttachmentTheory_membership_process_2.title', 'Du är med i urvalet'),
      description: t('AttachmentTheory_membership_process_2.description', 'När du har skapat din profil och verifierat dig så är du redo att vara med i urvalet. Våra matchmakers går igenom alla i urvalet och väljer ut dom bästa för nästa steg.'),
    },
    {
      icon: '/three.svg',
      title: t('AttachmentTheory_membership_process_3.title', 'Bli introducerad'),
      description: t('AttachmentTheory_membership_process_3.description', 'När du blir kontaktad så betyder det att du är utvald för att ni är en riktigt bra match och våra matchmakers vill introducera dig.'),
    },
  ];

  const questions = [
    {
      title: t('membership_question_1.title'),
      description: t('membership_question_1.description'),
    },
    {
      title: t('invite_question_2.title', 'Varför kontaktar ni mig?'),
      description: t('invite_question_2.description', 'En av våra matchmakers tror att den har hittat en bra match till dig! När du svarar på profilformuläret får matchmakern all information som behövs för att utforska vidare er matchningspotential. Om ni sedan introduceras för att gå på en dejt så fixar vi allt åt er, så ni bara kan fokusera på att utforska varandras sällskap.'),
    },
    {
      title: t('invite_question_3.title', 'Kostar detta mig något?'),
      description: t('invite_question_3.description', 'Nej. Det är helt kostnadsfritt att svara på frågorna. När du skickat in dina svar så får du en kostnadsfri medlemsprofil i vårt nätverk med chans att bli matchad med fler av våra klienter. Som nätverksmedlem har du dock inga garantier om introduktioner till matchningar.\n\nFör garanterade introduktioner med utvalda personer som matchar just dig så kan du skaffa en egen personlig matchmaker. Du får ett program med bland annat garanterade matchningar, coaching och stöd från din matchmaker för att kunna ha mer kärlek i din framtid. Att vara klient i matchmakingprogrammet kostar.'),
    },
    {
      title: t('invite_question_4.title', 'Hur hanterar ni min information?'),
      description: t('invite_question_4.description', 'När du skickar in dina svar på formuläret till oss så blir du medlem i Relates matchmaking-nätverk. Vi tar dataskydd, diskression och sekretess på största allvar. Vi använder din data endast för matchningsändamål enligt våra användarvillkor och integritetspolicy och säljer aldrig till tredjeparter.'),
    },
    {
      title: t('invite_question_5.title', 'Kan jag skaffa en egen personlig matchmaker?'),
      description: t('invite_question_5.description', 'Ja! Som klient får du ta del av ett matchmaking-program som hjälper dig att hitta relationen som du drömmer om. Du får bland annat garanterade introduktioner, coaching och stöd från din matchmaker för att hitta rätt i ditt kärleksliv. Att vara klient i matchmaking-programmet kostar och [du kan läsa mer om det här.](/matchmaker)'),
    },
    {
      title: t('membership_question_6.title', 'Hur kan jag kontakta Relate?'),
      description: t('membership_question_6.description', 'Du är välkommen att skriva dina frågor och tankar till Relate på [hello@relate-matchmaking.com](mailto:hello@relate-matchmaking.com).'),
    }
  ];

  const handleClick = useCallback(() => {
    if (!auth?.user) {
      // Open login modal.
      setShowLogin(true);
    } else {
      // Start profile form if logged in.
      const isApplied = Boolean(auth?.user?.appliedAt);
      const isProfileComplete = Boolean(auth?.user?.profileUpdatedAt);
      const isPreferenceComplete = Boolean(auth?.user?.preferenceUpdatedAt);
      // Skip profile selection if none exist already.
      if (!isApplied) {
        navigate('/application');
        return;
      }
      if (!isProfileComplete) {
        navigate('/profile');
        return;
      }
      if (!isPreferenceComplete) {
        navigate('/preferences');
        return;
      }
      navigate('/');
    }
  }, [auth]);

  return (
    <Body>
      <Helmet>
        <title>Relate | {pageTitle}</title>
      </Helmet>
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <Image src="/logo.png" sx={{ width: 65 }} />
      </Box>
      <Box>
        <Box>
          <Section
            sx={{
              textAlign: 'center',
              em: { color: 'white', fontWeight: 'bold' },
            }}
          >
            <Box sx={{ mb: 5 }}>
              <AnimatedTitle text={title} />
              <WheelAnimation type={type} types={types} />
              <Box
                sx={{
                  pt: 2,
                  px: { xs: 0, sm: 4 },
                  opacity: 0,
                  animation: `${fadeIn} 500ms forwards linear`,
                  animationDelay: '5s',
                }}
              >
                <MarkdownText>{description}</MarkdownText>
              </Box>
            </Box>
            <Box
              sx={{
                opacity: 0,
                animation: `${fadeIn} 500ms forwards linear`,
                animationDelay: '5s',
              }}
            >
              <Block sx={{ mx: 0, p: { xs: 3, sm: 4 } }}>
                <Typography component="h3" variant="h3" sx={{ mb: 2 }}>
                  {t(`AttachmentTheory.desc_title.${type}`)}
                </Typography>
                <Box sx={{ textAlign: 'left' }}>
                  <MarkdownText>
                    {t(`AttachmentTheory.description.${type}`)}
                  </MarkdownText>
                </Box>
              </Block>
              <Block sx={{ mx: 0, p: { xs: 3, sm: 4 } }}>
                <Typography component="h3" variant="h3" sx={{ mb: 2 }}>
                  {t(`AttachmentTheory.advice_title.${type}`)}
                </Typography>
                <Box sx={{ textAlign: 'left' }}>
                  <MarkdownText>
                    {t(`AttachmentTheory.questions.description.${type}`)}
                  </MarkdownText>
                </Box>
              </Block>
              <Block sx={{ mx: 0, p: { xs: 3, sm: 4 } }}>
                <Typography component="h3" variant="h3" sx={{ mb: 2 }}>
                  {t('AttachmentTheory.summary.title')}
                </Typography>
                <Box sx={{ textAlign: 'left' }}>
                  <MarkdownText>
                    {t(`AttachmentTheory.summary.description.${type}`)}
                  </MarkdownText>
                </Box>
              </Block>
            </Box>
          </Section>
        </Box>
        <Box sx={{ borderTop: 'solid 1px #38191E' }}></Box>
        <Section
          sx={{
            textAlign: 'center',
            em: { color: 'white', fontWeight: 'bold' },
          }}
        >
          <Box sx={{ mb: 4 }}>
            <Typography component="h2" variant="h1" sx={{ mb: 4 }}>
              {name
                ? t('membership.title', '{{name}}, du är inbjuden att gå med i vårt nätverk helt kostnadsfritt ♥️', { name })
                : t('membership.plain_title', 'Du är inbjuden att gå med i vårt nätverk helt kostnadsfritt ♥️')}
            </Typography>
            <Typography variant="body1">
              {t('AttachmentTheory_membership.not_ready_description', 'Som medlem har du tillgång till massor av värdefulla resurser som hjälper dig hitta rätt utifrån din anknytningsstil. Du har också chansen att bli matchad med och introducerad till våra klienter som längtar efter samma saker som du. Du behöver bara svara på våra matchmakers frågor och sedan luta dig tillbaka.')}
            </Typography>
          </Box>
          <Button
            onClick={handleClick}
            fullWidth
            size="large"
            variant="contained"
            sx={{
              mb: 1,
              backgroundAttachment: 'fixed',
              backgroundImage:  'repeating-radial-gradient(circle at 100px -400px, #76451F66 0, transparent 800px), repeating-linear-gradient(#be8a6144, #be8a6122)',
            }}
          >
              {t('membership.action', 'Gå med i vårt nätverk')}
          </Button>
          <Typography variant="caption" component="div" textAlign="center" sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
            {t('membership.help', 'Medlemskap i vårt nätverk är kostnadsfritt.')}
          </Typography>
        </Section>
        <Section highlight sx={{ flexDirection: 'row' }}>
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography component="h2" variant="h2" textAlign="center">
              {t('membership.benefits_title', 'Som medlem får du')}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              {benefits.map((item) => (
                <Box key={item.icon} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', my: 2 }}>
                  <Box
                    component="img"
                    src={item.icon}
                    sx={{
                      width: 52, height: 52, minWidth: 52, mr: 2,
                      borderRadius: 26,
                      backgroundAttachment: 'fixed',
                      backgroundImage:  'repeating-radial-gradient(circle at 0 0, #230e11 0, transparent 200px), repeating-linear-gradient(#4a362b88, #4a362b44)',
                    }}
                  />
                  <Typography variant="body2" sx={{ flex: 1 }}>{item.description}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              width: '40%',
              background: 'url(/membership.png)',
              backgroundPosition: 'left center',
              backgroundSize: 'auto 100%',
              ml: 6,
              mr: -6,
              mt: -6,
              mb: -6,
              borderTopRightRadius: '32px',
              borderBottomRightRadius: '32px',
              display: 'none',
              '@media screen and (min-width: 720px)': { display: 'flex' },
            }}
          />
        </Section>
        <Section>
          <Typography component="h2" variant="h2" textAlign="center">
            {t('AttachmentTheory_membership.process_title', 'Så går det till')}
          </Typography>
          <Typography variant="body2" textAlign="center">
            {t('AttachmentTheory_membership.process_description', 'Detta är hur det går till för dig som inbjuden till nätverket:')}
          </Typography>
          <Box>
            {process.map((item) => (
              <Box key={item.icon} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 6 }}>
                <Box
                  component="img"
                  src={item.icon}
                  sx={{
                    width: 52, height: 52, minWidth: 52, mb: 2,
                    borderRadius: 26,
                    backgroundAttachment: 'fixed',
                    backgroundImage:  'repeating-radial-gradient(circle at 0 0, #230e11 0, transparent 100px), repeating-linear-gradient(#4a362b88, #4a362b44)',
                  }}
                />
                <Box sx={{ display: 'flex', gap: 2, flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography component="h3" variant="h3">{item.title}</Typography>
                  <Typography variant="body2" textAlign="center">{item.description}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Section>
        <Section highlight sx={{ alignItems: 'center', flexDirection: 'row' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body2" textAlign="center" fontStyle="italic" sx={{ mb: 1 }}>
              “{t('membership.quote', 'Mycket roligare att bli kontaktad av en matchmaker och personligen bli inbjuden till en dejt, än att bara få en notifikation från en app. Det kändes verkligen som att man hittade så många stunder där vi båda svarade “Så tänker jag också!” Här stämde så många saker redan från början kändes det som.')}”
            </Typography>
            <Typography component="h5" variant="h5" textAlign="center">
              {t('membership.quote_author', '– Medlem i nätverket')}
            </Typography>
          </Box>
          <Box 
            component="img" 
            src="/erik.png" 
            sx={{
              width: 192, height: 192, ml: 4,
              borderRadius: 96,
              display: 'none',
              '@media screen and (min-width: 720px)': { display: 'flex' },
            }}
          />
        </Section>
        <Section>
          <Box sx={{ pb: 4, borderBottom: 'solid 1px #fff2' }}>
            <Box sx={{ mb: 3, textAlign: 'center' }}>
              <Typography component="h2" variant="h2" textAlign="center">
                {t('AttachmentTheory.selling_title', 'Svara på frågorna idag')}
              </Typography>
              <MarkdownText>
                {t('AttachmentTheory.selling_description', 'Vi söker kontinuerligt efter matchningar för våra klienter, svara på frågorna och gå med i vårt nätverk för att få möjligheten att bli introducerad.\n\nDet kostar inget och du slipper dejtingappar - du behöver bara svara på vår matchmakers frågor.')}
              </MarkdownText>
            </Box>
            <Button
              onClick={handleClick}
              fullWidth
              size="large"
              variant="contained"
              sx={{
                mb: 1,
                backgroundAttachment: 'fixed',
                backgroundImage:  'repeating-radial-gradient(circle at 10px 0, #76451F66 0, transparent 600px), repeating-linear-gradient(#be8a6144, #be8a6122)',
              }}
            >
              {t('AttachmentTheory.action', 'Svara på frågorna idag')}
            </Button>
            <Typography variant="caption" component="div" textAlign="center" sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
              <WatchLaterIcon sx={{ fontSize: 17, mr: '4px' }} />
              {t('membership.help', 'Medlemskap i vårt nätverk är kostnadsfritt.')}
            </Typography>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Box sx={{ pb: 3 }}>
              <Typography component="h2" variant="h2" textAlign="center">
                {t('AttachmentTheory_membership.matchmaking_title', 'Vill du också ha en egen personlig matchmaker?')}
              </Typography>
              <Typography variant="body2" textAlign="center">
                {t('membership.matchmaking_description', 'Med din egna personlig matchmaker går du igenom en noggrann matchmakingprocess där du bland annat får garanterade introduktioner med utvalda matchningar samt tips, stöd och verktyg av din matchmaker.')}
              </Typography>
            </Box>
            <Button
              component={Link}
              to={'/matchmaker' + (name ? `?name=${name}`: '')}
              fullWidth
              size="large"
              variant="outlined"
              sx={{
                mb: 1,
                backgroundAttachment: 'fixed',
                backgroundImage:  'repeating-radial-gradient(circle at 0 0, #230e11 0, transparent 600px), repeating-linear-gradient(#4a362b88, #4a362b44)',
              }}
            >
              {t('membership.matchmaking_action', 'Läs mer om vårt matchmakingprogam')}
            </Button>
          </Box>
        </Section>
        <Section highlight>
          <Typography component="h2" variant="h2">
            {t('AttachmentTheory.questions_title', 'Undrar du något?')}
          </Typography>
          <Box>
            {questions.map((item) => (
              <Question key={item.title} item={item} />
            ))}
          </Box>
        </Section>
        <Section sx={{ alignItems: 'center' }}>
          <Image src="/logo.png" sx={{ width: 84 }} />
          <Typography variant="caption">
            {t('slogan')}
          </Typography>
        </Section>
        <Section />
        {showLogin && (
          <LoginDialog
            open
            handleClose={() => setShowLogin(false)}
            onLoggedIn={(user) => {
              const isApplied = Boolean(user?.appliedAt);
              const isProfileComplete = Boolean(user?.profileUpdatedAt);
              const isPreferenceComplete = Boolean(user?.preferenceUpdatedAt);
              // Skip profile selection if none exist already.
              if (!isApplied) {
                navigate('/application');
                return;
              }
              if (!isProfileComplete) {
                navigate('/profile');
                return;
              }
              if (!isPreferenceComplete) {
                navigate('/preferences');
                return;
              }
              navigate('/');
            }}
          />
        )}
      </Box>
    </Body>
  );
}
