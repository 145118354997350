import { Box } from '@mui/material';
import { useFormState } from 'react-final-form';
import { getValueAtPath } from './SubmitButton';

const container = {
  width: '100%',
  height: '4px',
  backgroundColor: (t: any) => t.palette.mode === 'light' ? '#fff' : '#333',
  overflow: 'hidden',
  borderRadius: '2px',
  display: 'flex',
  marginTop: '-4px',
};
const progress = {
  height: '100%',
  backgroundColor: '#8E603C',
  borderRadius: '2px',
};

const FixedProgress = ({ fixed }: { fixed: number }) => (
  <Box sx={container}>
    <Box sx={progress} style={{ width: `${fixed}%` }} />
  </Box>
);

export const isComplete = (value: any) => {
  if (Array.isArray(value) || typeof value === 'string') {
    return Boolean(value.length);
  }
  if (value instanceof Date) {
    return true;
  }
  if (typeof value === 'number') {
    return true;
  }
  if (value?.min && value?.max) {
    return true;
  }
  if (value?.name && value?.latitude && value?.longitude) {
    return true;
  }
  return false;
}

const FormProgress = ({ fields }: { fields?: any[] }) => {
  const state = useFormState();
  const steps = (fields || []).filter(f => f.name && f.name !== 'extra')?.map(k => k.name);
  const renderStep = (step: string) => {
    const complete = isComplete(getValueAtPath(state?.values, step)) && !getValueAtPath(state?.errors, step);
    return (
      <Box key={step} sx={[{ flex: 1 }, complete ? { backgroundColor: '#8E603C' } : null]} />
    );
  };
  return (
    <Box sx={container}>
      {steps?.map(renderStep)}
    </Box>
  );
}

const ProgressIndicator = ({ fixed, fields }: { fixed?: number, fields?: any[] }) => {
  if (typeof fixed === 'number') {
    return <FixedProgress fixed={fixed} />
  }
  else {
    return <FormProgress fields={fields} />
  }
}

export default ProgressIndicator;
