import { useField } from 'react-final-form';
import { FormControl, Slider, Typography } from "@mui/material";
import { useMemo } from 'react';

type ScaleFieldProps = {
  name: string;
  label?: string;
  description?: string;
  min?: number;
  max?: number;
  step?: number;
};

function getAriaValueText(value: number): string {
  return `${value}`;
}

const style = {
  marginTop: '16px',
  width: 'calc(100% - 24px)',
  marginLeft: '8px',
  marginRight: '8px',

  '& .MuiSlider-rail': {
    opacity: 1,
    height: 4,
    backgroundColor: '#59301e',
    borderRadius: 0,
  },
  '& .MuiSlider-mark': {
    height: 8,
    width: 8,
    borderRadius: 8,
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
  },
  '&.have-value .MuiSlider-mark': {
    backgroundColor: (theme: any) => theme.palette.primary.main,
  },
  '&.have-value .MuiSlider-markActive': {
    backgroundColor: `white`,
  },
  '&.have-value .MuiSlider-markLabel': {
    color: (theme: any) => theme.palette.primary.main,
  },
  '&.have-value .MuiSlider-markLabelActive': {
    opacity: 1,
    color: 'white',
  },
}

function ScaleField({
  name,
  label,
  description,
  min = 0,
  max = 100,
  step = 0,
}: ScaleFieldProps): JSX.Element {
  const { input } = useField(name);
  const value = typeof input.value === 'number' ? input.value : 0;
  const handleChange = (
    _: Event,
    newValue: number | number[],
  ) => {
    if (Array.isArray(newValue)) {
      return;
    }
    
    input.onChange(newValue as number);
  };

  const marks = useMemo(() => {
    return Array.from(new Array((max - min) + 1), (_, i) => ({
      value: Math.max(Math.min(i + 1, max), min),
      label: Math.max(Math.min(i + 1, max), min),
    }));
  }, []);

  return (
    <FormControl variant="filled" margin="normal" sx={{ '&:first-of-type': { mt: 0 }, '&:last-of-type': { mb: 0 } }}>
      {label && <Typography component="label" variant="h6" sx={{ mb: 0.5, fontWeight: 700 }}>{label}</Typography>}
      {description && <Typography variant="caption">{description}</Typography>}
      <Slider
        marks={marks}
        min={min}
        max={max}
        step={step}
        track={false}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="off"
        getAriaValueText={getAriaValueText}
        className={typeof input.value === 'number' ? 'have-value' : 'have-no-value'}
        sx={[style, {
          '& .MuiSlider-thumb': {
            display: typeof input.value === 'number' ? 'flex' : 'none',
          }
        }]}
      />
    </FormControl>
  );
}


export default ScaleField;
