import { useTranslation } from 'react-i18next';
import LoginForm from '../forms/LoginForm';
import { useAuth } from '../data/auth/token';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import Body from '../components/Body';
import Heading from '../components/Heading';
import Image from '../components/Image';
import { useEffect } from 'react';

export default function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  useEffect(() => {
    if (user) {
      // Show home if profile is already applied.
      navigate('/');
    }
  }, [user]);
  return (
    <Body>
      <Heading
        title={t('login.title', 'Inlogg för medlemmar')}
        description={t('login.description', 'Välkommen tillbaka, logga in för att komma in i medlemsportalen där du kan hantera dina uppgifter samt följa hur det går med din ansökan.')}
      />
      <LoginForm terms={false}>
        <Button
          size="large"
          to="/signup"
          component={Link}
          variant="text"
        >
          {t('dont_have_an_account', `Inte medlem? Ansök om medlemsskap idag!`)}
        </Button>
      </LoginForm>
      <Box sx={{ display: 'flex', alignItems: 'center', px: 2, py: 4, mb: 2, mt: 4, flexDirection: 'column', borderTop: 'solid 1px #38191E' }}>
        <Image src="/logo.png" sx={{ width: 84 }} />
        <Typography variant="caption">
          {t('slogan')}
        </Typography>
      </Box>
    </Body>
  );
}
