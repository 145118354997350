import client from "../data/client";
import DeletePictureUploadMutation from '../data/mutations/DeletePictureUploadMutation';

const deletePictureUpload = async (userPictureId: string) => {
  const result = await client.mutate({
    mutation: DeletePictureUploadMutation,
    fetchPolicy: 'no-cache',
    variables: { userPictureId },
  }).catch(() => {
    return null;
  });

  if (result?.data?.userPicture?.deleteUpload) {
    Boolean(result.data.userPicture.deleteUpload);
  } else {
    return false;
  }
}

export default deletePictureUpload;
