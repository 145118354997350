import { Box, Link, Typography } from '@mui/material'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const components = {
  a(props: any) {
    const { node, ...rest } = props;
    return <Link {...rest} />
  },
  p(props: any) {
    const { node, ...rest } = props;
    return <Typography variant="body2" sx={{ mb: 2, '&:last-child': { mb: 0 } }} {...rest} />
  },
  ul(props: any) {
    const { node, ...rest} = props;
    return <Box component="ul" sx={{ pl: 3.5, ml: 0, mt: 1 }}{...rest} />
  },
  li(props: any) {
    const { node, ...rest} = props;
    return <Typography component="li" variant="body2" sx={{ py: 1 }} {...rest} />
  },
  h1(props: any) {
    const { node, ...rest} = props;
    return <Typography component="h1" variant="h1" {...rest} />
  },
  h2(props: any) {
    const { node, ...rest} = props;
    return <Typography component="h2" variant="h2" {...rest} />
  },
  h3(props: any) {
    const { node, ...rest} = props;
    return <Typography component="h3" variant="h3" {...rest} />
  },
  h4(props: any) {
    const { node, ...rest} = props;
    return <Typography component="h4" variant="h4" {...rest} />
  },
  h5(props: any) {
    const { node, ...rest} = props;
    return <Typography component="h5" variant="h5" {...rest} />
  },
  h6(props: any) {
    const { node, ...rest} = props;
    return <Typography component="h6" variant="h6" {...rest} />
  },
  em(props: any) {
    const { node, ...rest} = props;
    return <span style={{ color: '#fff', fontWeight: 700 }} {...rest} />
  },
  strong(props: any) {
    const { node, ...rest} = props;
    return <span style={{ color: '#8E603C' }} {...rest} />
  },
};

export default function MarkdownText({ children }: { children: string }) {
  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      components={components}
    >{children}</Markdown>
  );
};
