import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Block from '../../components/Block';
import Section from '../../components/Section';
import { fadeIn } from "./helpers";
import MarkdownText from '../../components/MarkdownText';
import Image from '../../components/Image';

const ClientProcess = ({ duration = 3 }) => {
  const { t } = useTranslation();
  return (
    <Section
      small
      sx={{
        textAlign: 'center',
        em: { color: 'white', fontWeight: 'bold' },
        background: 'none !important',
        opacity: 0,
        animation: `${fadeIn} 500ms forwards linear`,
        animationDelay: `${duration + 1}s`,
      }}
    >
      <Block
        sx={{
          p: 4,
          mx: 0,
          mb: 4,
          backgroundColor:  (t) =>
            (t.palette.mode === 'light' ? t.palette.background.paper : t.palette.secondary.main) + '88',
        }}
      >
        <MarkdownText>
          {t('client_process.info', 'Kul att du gör din resa mot din drömrelation med oss! Ovan kan du redigera din profil och hålla den uppdaterad åt din matchmaker.')}
        </MarkdownText>
      </Block>
      <Typography component="h2" variant="h2">
        {t('client_process.title', 'Från din matchmaker')}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', p: 2 }}>
        <Box
          component={Image}
          src="/christina.png"
          sx={{
            width: { xs: '184px', sm: '120px' },
            height: { xs: '184px', sm: '120px' },
            borderRadius: { xs: '92px', sm: '60px' },
            zIndex: 12,
            mb: 4,
          }}
        />
        <Typography component="h4" variant="h4" textAlign="center" sx={{ mb: 4 }}>
          Christina Olnils
        </Typography>
        <MarkdownText>
          {t('client_process.description', 'Varmt välkommen till vårt matchmaking-program!\n\nDet gläder mig att du har valt att ta steget mot att finna din drömrelation. Med vårt dedikerade team av matchmakers och vårt innovativa matchningssystem är vi redo att guida dig på din resa mot kärleken. Jag ser fram emot att lära känna och hjälpa dig nå relationen du drömmer om.')}
        </MarkdownText>
      </Box>
    </Section>
  );
}

export default ClientProcess;
