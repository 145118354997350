import { gql } from "@apollo/client";

const UserProfileFieldsQuery = gql`
  query UserProfileFieldsQuery {
    userProfileFields {
      personality
      personality_type
      energy
      life_values
      relationship_values
      society_values
      life_stage
      interest
      typical_sunday
      income
      vacation_home
      home_decoration
      pets
      tobacco
      alcohol
      drugs
      love_language
      partner_type
      childhood
      occupation_role
      education_level
      education_topics
      relationship_status
      political
      religious_upbringing
      religion
      religious_belief
      have_kids
      want_kids
      want_kids_when
      total_relationships
      since_last_relationship
      relationship_length
    }
  }
`;

export default UserProfileFieldsQuery;
