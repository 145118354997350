import { gql } from "@apollo/client";

const UserViewerFragment = gql`
  fragment UserViewerFragment on User {
    _id
    firstName
    lastName
    email
    phone
    birthdate
    height
    sex
    appliedAt
    reviewingAt
    reviewedAt
    approvedAt
    profileUpdatedAt
    preferenceUpdatedAt
    service
    linkedin
    instagram
    about
    future_partner
    locations {
      id
      name
      latitude
      longitude
    }
    sexuality
    picture {
      _id
      uri(size: "thumb", format: "webp")
      square: uri(size: "square", format: "webp")
    }
    profile {
      _id
      uri(size: "gallery", format: "webp")
    }
    settings {
      _id
      preferences {
        sex
        age { min, max }
        height { min, max }
        relationship
        education_level
        # Use old values as default values in preferences.
        drinking
        smoking
        children
        family_plans
        locations {
          id
          name
          latitude
          longitude
        }
      }
    }
  }
`;

export default UserViewerFragment;
