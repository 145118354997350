import gql from 'graphql-tag';
import UserPictureFragment from '../fragments/UserPictureFragment';

const FinishPictureUploadMutation = gql`
  mutation FinishPictureUploadMutation($userPictureId: ID!) {
    userPicture {
      finishUpload(userPictureId: $userPictureId) {
        ...UserPictureFragment
      }
    }
  }
  ${UserPictureFragment}
`;

export default FinishPictureUploadMutation;
