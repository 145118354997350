import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';


export const useEvent = (nativeEvent, customHandler) =>
  useCallback(
    (event) => {
      customHandler();
      if (typeof nativeEvent === 'function') {
        nativeEvent(event);
      }
    },
    [nativeEvent],
  );

export const useFocusState = (onBlur, onFocus) => {
  const [isFocused, setFocusFlag] = useState(false);
  return {
    isFocused,
    onBlur: useEvent(onBlur, () => setFocusFlag(false)),
    onFocus: useEvent(onFocus, () => setFocusFlag(true)),
  };
};


const truncateString = (codeValue, codeLength) =>
  codeValue.substr(0, codeLength).split('');

const emptySymbols = (codeLength) => new Array(codeLength).fill('');

export const getSymbols = (codeValue, codeLength) =>
  new Array()
    .concat(truncateString(codeValue, codeLength))
    .concat(emptySymbols(codeLength))
    .slice(0, codeLength);
    
export const getStyle = (base, custom) => (custom ? [base, custom] : base);

export const isLastFilledCell = ({value, index}) =>
  Boolean(value && value.length - 2 === index);

const creteUseTimer = (clear, runTimer) => (callback, delay) => {
  const timerRef = useRef();
  useEffect(() => {
    const stop = () => clear(timerRef.current);
    stop();
    timerRef.current = runTimer(callback, delay);
    return stop;
  }, [delay]);
};

export const useInterval = creteUseTimer(
  clearInterval,
  setInterval,
);

export const useTimeout = creteUseTimer(
  clearTimeout,
  setTimeout,
);

type Coords = { locationX: number, locationY: number };

const findIndex = ({ locationX, locationY }: Coords, map) => {
  for (const [index, {x, y, xEnd, yEnd}] of Object.entries(map)) {
    if (
      x < locationX &&
      locationX < xEnd &&
      y < locationY &&
      locationY < yEnd
    ) {
      return parseInt(index, 10);
    }
  }
  return -1;
};

export const useClearByFocusCell = (options) => {
  const valueRef = useRef(options);
  const cellsLayouts = useRef({});
  valueRef.current = options;
  const clearCodeByCoords = (coords: Coords) => {
    const index = findIndex(coords, cellsLayouts.current);
    if (index !== -1) {
      const {value, onChange} = valueRef.current;
      const text = (value || '').slice(0, index);
      onChange(text);
    }
  };
  const getCellOnLayoutHandler = (index: number) => ({width, height, x, y}) => {
    cellsLayouts.current[`${index}`] = {
      x,
      xEnd: x + width,
      y,
      yEnd: y + height,
    };
  };
  const onClick = (event: MouseEvent<HTMLInputElement, MouseEvent>) => {
    const locationX = event.pageX;
    const locationY = event.pageY;
    clearCodeByCoords({ locationX, locationY });
  };
  return [
    onClick,
    getCellOnLayoutHandler,
  ];
};
