import { gql } from "@apollo/client";
import UserPreferenceFragment from "../fragments/UserPreferenceFragment";

const UserPreferenceQuery = gql`
  query UserPreferenceQuery {
    viewer {
      user {
        ...UserPreferenceFragment
      }
    }
  }
  ${UserPreferenceFragment}
`;

export default UserPreferenceQuery;
