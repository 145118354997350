import { makeVar, useReactiveVar } from "@apollo/client";
import memoizee from 'memoizee';

export const getUploadProgress = memoizee((_: any, initialValue: number = 1) => makeVar(initialValue), {
  length: 1,
  maxAge: 1000 * 60 * 5, // 5 min cache
});

export const useUploadProgress = (uploadId?: string | null) => {
  const progress = getUploadProgress(uploadId);
  return useReactiveVar(progress);
};
