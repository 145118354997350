import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import VideocamIcon from '@mui/icons-material/Videocam';
import Webcam from "react-webcam";
import { makeVar } from '@apollo/client';
import { useField } from 'react-final-form';
import ClearIcon from '@mui/icons-material/Clear';
import usePermissions from '../../helpers/usePermissions';
import RecordButton from '../RecordButton';
import { useAuth } from '../../data/auth/token';
import UploadProgressPieChart from '../UploadProgressPieChart';
import askCameraPermission from '../../helpers/askCameraPermission';

// Reactive variable to hold the normalized time (0 to 1)
const recording = makeVar(1);

const spinner = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  pointerEvents: 'none',
};

type CameraFieldProps = {
  name: string;
  disabled?: boolean;
  maxLength?: number;
};

const CameraField = ({ name, maxLength = 30, disabled }: CameraFieldProps) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { input } = useField<{ _id?: string, url: string, file: File } | null>(name, { defaultValue: null });
  const webcamRef = useRef<Webcam | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const { granted, videoStatus, audioStatus, loading: loadingPermissions } = usePermissions();
  const [loading, setLoading] = useState(false);
  const [capturing, setCapturing] = useState(false);
  const recordedChunks = useRef([]);
  const recorded = Boolean(input.value?.url?.length);
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);

  const handleDataAvailable = useCallback(({ data }: { data: any }) => {
    if (data.size > 0) {
      recordedChunks.current = recordedChunks.current.concat(data);
    }
  }, [capturing]);

  const handleStopped = useCallback(() => {
    const blob = new Blob(recordedChunks.current, {
      type: "video/webm",
    });
    const file = new File([blob], `${user?._id}.webm`);
    const url = URL.createObjectURL(blob);
    if (input.value?.url?.length) {
      window.URL.revokeObjectURL(input.value.url);
    }
    input.onChange({ file, url });
  }, [input, user]);

  // onUnmount.
  useEffect(() => () => {
    stopRecording();
    recording(1); // Reset the recording to 1
  }, []);

  const stopRecording = useCallback(() => {
    recordedChunks.current = [];
    input.onChange(null);
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    if (interval.current !== null) {
      clearInterval(interval.current as NodeJS.Timeout);
      interval.current  = null;
    }
  }, []);

  const resetRecording = useCallback(async () => {
    setCapturing(false);
    stopRecording();
    recording(1); // Reset the recording to 1
  }, []);

  const startRecording = useCallback(() => {
    // Make sure there's no active recording
    stopRecording();

    if (webcamRef.current?.stream) {
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType: "video/webm",
      });
      mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
      mediaRecorderRef.current.addEventListener('stop', handleStopped);
      mediaRecorderRef.current.start();
    }

    // Start the recording
    const time = Date.now();
    interval.current  = setInterval(() => {
      const elapsedSeconds = (Date.now() - time) / 1000;
      const p = elapsedSeconds / maxLength; // Normalize the time to a 0-1 range
      if (p >= 1) {
        setCapturing(false);
        stopRecording(); // Stop the recording when we reach 1
        recording(1);
      } else {
        recording(p); // Update the reactive variable
      }
    }, 1 / 60); // Run this with 60fps
  }, []);

  const handleEnableWebcam = useCallback(async () => {
    setLoading(true);
    await askCameraPermission();
    setLoading(false);
  }, []);

  const handleClick = useCallback(() => {
    setCapturing(state => {
      if (state) {
        stopRecording();
        return false;
      } else {
        input.onChange(null);
        startRecording();
        return true;
      }
    });
  }, []);

  return (
    <Box sx={{
      paddingBottom: '100%',
      background: '#fff1',
      position: 'relative',
      borderRadius: 4,
      mb: 2,
      video: {
        objectFit: 'cover',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100%',
        width: '100%',
        borderRadius: 4,
      }
    }}>
      {granted && (
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          position: 'absolute',
        }}>
          <CircularProgress sx={{ color: 'white' }} />
        </Box>
      )}
      {input.value?.url && (
        <video controls autoPlay>
          <source src={input.value.url} type="video/webm" />
        </video>
      )}
      {granted && !recorded && (
        <Webcam
          ref={webcamRef}
          muted
          audio={true}
          mirrored={true}
          audioConstraints={{
            echoCancellation: true,
          }}
          videoConstraints={{
            width: { min: 480, max: 480 },
            height: { min: 480, max: 480 },
            aspectRatio: 1,
            facingMode: "user"
          }}
          style={{
            pointerEvents: 'none',
            transition: 'opacity 320ms ease 0s',
            opacity: recorded ? 0 : 1,
          }}
        />
      )}
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        position: 'absolute',
        background: 'url(/profile-outline.svg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'none',
        opacity: capturing || recorded ? 0 : 0.75,
        transition: 'opacity 320ms ease 0s',
        borderRadius: 4,
        pointerEvents: 'none',
      }} />
      {!recorded && (
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
          width: '100%',
          height: '100%',
          position: 'absolute',
          p: 2,
        }}>
          <RecordButton
            capturing={capturing}
            recording={recording}
            onClick={handleClick}
            disabled={disabled || !granted || (granted && loading)}
          />
        </Box>
      )}
      {!disabled && (
        <Button
          variant="contained"
          onClick={resetRecording}
          sx={{
            position: 'absolute',
            top: -8,
            right: -8,
            width: 28,
            minWidth: 28,
            height: 28,
            borderRadius: '50%',
            p: 0,
            color: '#000',
            fontSmooth: 'never',
            zIndex: 1,
            background: '#fff',
            '&:hover': {
              background: '#ddd',   
            },
            opacity: recorded ? 1 : 0,
            pointerEvents: recorded ? 'all' : 'none',
            transition: recorded ? 'opacity 320ms ease 0s' : 'none',
          }}
        >
          <ClearIcon sx={{            
            fontSize: 16,
            fill: 'black',
            stroke: 'black',
            strokeWidth: 2,
          }} />
        </Button>
      )}
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        position: 'absolute',
        flexDirection: 'column',
        pointerEvents: 'none',
      }}>
        {!recorded && !loadingPermissions && !granted && (
          <Button
            onClick={handleEnableWebcam}
            variant="contained"
            disabled={disabled || loading || videoStatus === 'denied' || audioStatus === 'denied'}
            sx={{
              fontSize: '0.85rem',
              pointerEvents: 'all',
            }}
            startIcon={(
              <VideocamIcon sx={{ width: 16, height: 16 }} />
            )}
          >
            {t('verification.enable_video', 'Svara genom att spela in en kort video')}
          </Button>
        )}
        {videoStatus === 'denied' && audioStatus === 'denied' ? (
          <Typography variant="caption" component="div" textAlign="center" sx={{ mt: 1, justifyContent: 'center', alignItems: 'center', display: 'flex', color: 'white' }}>
            {t('verification.permissions_denied', 'OBS! Nekad tillgång till kamera och mikrofon')}
          </Typography>
        ) : (videoStatus === 'denied' ? (
          <Typography variant="caption" component="div" textAlign="center" sx={{ mt: 1, justifyContent: 'center', alignItems: 'center', display: 'flex', color: 'white' }}>
            {t('verification.camera_denied', 'OBS! Nekad tillgång till kamera')}
          </Typography>
        ) : (audioStatus === 'denied' && (
          <Typography variant="caption" component="div" textAlign="center" sx={{ mt: 1, justifyContent: 'center', alignItems: 'center', display: 'flex', color: 'white' }}>
            {t('verification.microphone_denied', 'OBS! Nekad tillgång till mikrofon')}
          </Typography>
        )))}
      </Box>
      <UploadProgressPieChart
        size={32}
        key={input.value?._id}
        uploadId={input.value?._id}
      >
        {loading && (
          <Box sx={spinner}>
            <CircularProgress sx={{ color: 'white' }} size={24} />
          </Box>
        )}
      </UploadProgressPieChart>
    </Box>
  );
};

export default CameraField;
