import MarkdownText from '../../components/MarkdownText';
import Section from '../../components/Section';
import { fadeIn } from './helpers';

const BodySection = ({ content, highlight, duration, small, italic }) => {
  return (
    <Section
      highlight={highlight}
      sx={{
        textAlign: 'center',
        ...italic && { fontStyle: 'italic' },
        ...small && { fontSize: 16 },
        ...duration > 0 && {
          opacity: 0,
          animation: `${fadeIn} 500ms forwards linear`,
          animationDelay: `${duration + 1}s`,
        },
        h3: {
          mb: 4,
        },
        'p + h3': {
          mt: 4,
        },
        'h3 + ul': {
          pt: 0,
          mt: 0,
        },
        ul: {
          textAlign: 'left',
        },
      }}
    >
      <MarkdownText>{content}</MarkdownText>
    </Section>
  )
};

export default BodySection;
