import { Helmet } from 'react-helmet-async';
import { SxProps, Typography } from '@mui/material';
import Box from '@mui/material/Box';

function Heading({ title, description, sx, ...props }: { title: string, description?: string, sx?: SxProps }) {
  return (
    <Box
      sx={{
        p: 2,
        pt: 4,
        ...sx
      }}
      {...props}
    >
      <Helmet>
        <title>Relate | {title}</title>
      </Helmet>
      <Typography component="h2" variant="h2">{title}</Typography>
      {description && <Typography variant="body2">{description}</Typography>}
    </Box>
  );
}

export default Heading;
