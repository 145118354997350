import { Box, SxProps } from '@mui/material';
import { ReactNode, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { trackPageView } from '../helpers/mixpanel';

export default function Body({ children, sx }: { children: ReactNode, sx?: SxProps }) {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const params = Object.fromEntries(searchParams.entries());
    trackPageView(location.pathname, params);
  }, [location, searchParams]);
  
  return (
    <Box sx={{
      minHeight: '100dvh',
      backgroundColor: 'rgb(56,25,30)',
      background: 'radial-gradient(ellipse at center, #220E11 50%, rgba(8,5,3,1) 100%)',
      backgroundSize: '100vw 100vh',
      backgroundAttachment: 'fixed',

      '.virtuoso': {
        marginBottom: 4,
        minHeight: '100vh',
      },
      
      '.virtuoso > div:first-of-type': {
        zIndex: '2 !important',
      },
    
      '.virtuoso [data-test-id="virtuoso-top-item-list"]': {
        background: 'linear-gradient(180deg, #110808, #110808cc, transparent)',
        paddingTop: 0,
        paddingBottom: 2,
        pointerEvents: 'none',
      },
    }}>
      <Box sx={{
        position: 'relative',
        margin: '0 auto',
        maxWidth: '800px',
        '@media screen and (min-width: 640px)': {
          paddingLeft: 2,
          paddingRight: 2,
        },

        ...sx,
      }}>
        {children}
      </Box>
    </Box>
  );
}
