import { useState, useCallback, useEffect, useMemo, useImperativeHandle } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import { useTranslation } from 'react-i18next';

const accessToken = 'pk.eyJ1IjoidGltYnJhbmRpbiIsImEiOiJjbGkwYXV4NzYxbHZhM21wYzIzNnVyejQ2In0.blRrTei9A3kZK4E88rfsvQ';

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}

interface PlaceType {
  id: string,
  name: string;
  longitude: number;
  latitude: number;
  zoom: number;
  structured_formatting: StructuredFormatting;
  toString: () => string;
}

interface MapBoxFeature {
  id: string,
  text: string,
  place_name: string,
  context: [{ text: string }],
  center: [number, number],
  bbox?: [number, number, number, number],
}

function findSubstringMatches(searchInput: string, resultText: string) {
  const matches = [];
  const pattern = searchInput
    .replace(/a|[åä]/gi, '[aåä]')
    .replace(/ö|o/gi, '[oö]');
  const searchRegex = new RegExp(pattern, 'gi');
  let match;

  while ((match = searchRegex.exec(resultText)) !== null) {
    matches.push({
      offset: match.index,
      length: match[0].length
    });
  }

  return matches;
}

function calculateZoom(bbox: [number, number, number, number]): number {
  const [minLongitude, minLatitude, maxLongitude, maxLatitude] = bbox;

  const maxZoom = 12;
  const k = 320; // Set to appropriate to get an appropriate zoom
  const worldWidth = 360; // Width of the world in degrees

  const latDiff = maxLatitude - minLatitude;
  const lngDiff = maxLongitude - minLongitude;

  const latZoom = Math.log2(k / (latDiff * (worldWidth / 360)));
  const lngZoom = Math.log2(k / (lngDiff * (worldWidth / 360)));
  
  return Math.min(latZoom, lngZoom, maxZoom);
}

async function forwardGeocode(searchInput: string, language = 'en'): Promise<PlaceType[]> {
  const uri = `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchInput}.json?` + new URLSearchParams({
    access_token: accessToken,
    language,
    types: 'place,region,country',
    autocomplete: 'true',
  });
  const data = await fetch(uri).then(res => res.json());
  return data.features.map((feature: MapBoxFeature) => {
    return {
      id: feature.id,
      name: feature.text,
      structured_formatting: {
        main_text: feature.text,
        main_text_matched_substrings: findSubstringMatches(searchInput, feature.text),
        secondary_text: (feature.context || []).map(({ text }: { text: string }) => text).join(', '),
      },
      longitude: feature.center[0],
      latitude: feature.center[1],
      zoom: feature?.bbox ? calculateZoom(feature.bbox) : 9,
      toString: () => feature.id,
    };
  });
};

type LocationSearchBoxProps = TextFieldProps & {
  onSelect?: (value: PlaceType | null) => void,
  defaultValue?: PlaceType,
};

export const LocationSearchBox = ({ onSelect, inputRef, onBlur, defaultValue, ...props }: LocationSearchBoxProps = {}) => {
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState<PlaceType | null>(defaultValue || null);
  const [inputValue, setInputValue] = useState(defaultValue?.name || '');
  const [options, setOptions] = useState<readonly PlaceType[]>([]);

  useImperativeHandle(inputRef, () => ({ value }), [value]);

  const onChange = useCallback((_: any, newValue: PlaceType | null) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
    onSelect?.(newValue);
  }, [options]);

  const onInputChange = useCallback((_: any, newInputValue: string) => {
    setInputValue(newInputValue);
  }, []);

  const fetch = useMemo(() => debounce((
    input: string,
    callback: (results?: readonly PlaceType[]) => void,
  ) => {
    forwardGeocode(input, i18n.language).then(callback);
  }, 400), [i18n.language]);

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(inputValue, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [
            ...newOptions,
            ...value ? results.filter(r => r.id !== value?.id) : results,
          ];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const getOptionLabel = useCallback((option: any) => typeof option === 'string'
    ? option : option.name, []);
  const renderInput = useCallback((params: any) => (
    <TextField
      placeholder={t('search_location', 'Lägg till din plats')}
      {...params}
      {...props}
      onBlur={(e) => onBlur?.(e, value)}
      autoComplete="off"
    />
  ), [value]);
  const renderOption = useCallback((props: any, option: PlaceType) => {
    const matches =
      option.structured_formatting.main_text_matched_substrings || [];

    const parts = parse(
      option.structured_formatting.main_text,
      matches.map((match: MainTextMatchedSubstrings) => [match.offset, match.offset + match.length]),
    );

    return (
      <li {...props} key={option.id}>
        <Grid container alignItems="center">
          <Grid item sx={{ wordWrap: 'break-word' }}>
            {parts.map((part: { text: string, highlight: boolean }, index: number) => (
              <Box
                key={index}
                component="span"
                sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
              >
                {part.text}
              </Box>
            ))}
            <Typography variant="body2" color="text.secondary">
              {option.structured_formatting.secondary_text}
            </Typography>
          </Grid>
        </Grid>
      </li>
    );
  }, []);

  const isOptionEqualToValue = useCallback((a: PlaceType, b: PlaceType) => {
    return a.id === b.id;
  }, [])

  return (
    <Autocomplete
      sx={{ flex: 1 }}
      filterOptions={(x) => x}
      id="location-search-box"
      options={options}
      includeInputInList
      autoHighlight
      autoSelect
      value={value}
      isOptionEqualToValue={isOptionEqualToValue}
      noOptionsText={t('no_locations', 'Inga platser')}
      onChange={onChange}
      onInputChange={onInputChange}
      getOptionLabel={getOptionLabel}
      renderInput={renderInput}
      renderOption={renderOption}
    />
  )
};
