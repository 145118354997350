import { useField } from 'react-final-form';
import { FormControl, Slider, SliderValueLabel, Typography, styled } from "@mui/material";
import { ReactNode } from 'react';

type SliderFieldProps = {
  name: string;
  label?: string;
  description?: string;
  min?: number;
  max?: number;
  step?: number;
  overlap?: number;
  valueLabelFormat: string | ((value: number, index: number) => ReactNode) | undefined;
};

function getAriaValueText(value: number): string {
  return `${value}`;
}

const style = {
  height: '24px',
  padding: 0,
  marginTop: '32px',
  width: 'calc(100% - 16px)',
  marginLeft: '8px',
  marginRight: '8px',

  '& .MuiSlider-valueLabel': {
    fontSize: 16,
    fontWeight: 'bold',
    top: 0,
    backgroundColor: 'unset',
    color: 'white',
    '&:before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
    height: 4,
  },
  '& .MuiSlider-rail': {
    opacity: 0.25,
    height: 4,
  },
}

/**
 * Gets the number of decimal places of a number.
 * @param num The number to check.
 * @returns The number of decimal places.
 */
function getDecimalPlaces(num: number): number {
  const parts = num.toString().split(".");
  return parts.length > 1 ? parts[1].length : 0;
}

/**
* Rounds a number to a specified number of decimal places.
* @param num The number to round.
* @param precision The number of decimal places to round to.
* @returns The rounded number.
*/
function roundToPrecision(num: number, precision: number): number {
  const factor = Math.pow(10, precision);
  return Math.round(num * factor) / factor;
}

const ValueLabelComponent = styled(SliderValueLabel)(({ ownerState }) => {
  const precision = getDecimalPlaces(ownerState.step);
  const value = roundToPrecision(ownerState.value, precision);
  return {
    left: value === ownerState.min ? -10 : '',
    right: value === ownerState.max ? -10 : '',
  };
});

function SliderField({
  name,
  label,
  description,
  min = 0,
  max = 100,
  step = 1,
  valueLabelFormat,
}: SliderFieldProps): JSX.Element {
  const { input } = useField<number>(name);

  const value = !Array.isArray(input.value) && typeof input.value === 'number' ? input.value : min;
  const handleChange = (
    _: Event,
    newValue: number | number[],
  ) => {
    if (Array.isArray(newValue)) {
      return;
    }
    input.onChange(newValue as number);
  };

  return (
    <FormControl variant="filled" margin="normal" sx={{ '&:first-of-type': { mt: 0 }, '&:last-of-type': { mb: 0 } }}>
      {label && <Typography component="label" variant="h6" sx={{ mb: 0.5, fontWeight: 700 }}>{label}</Typography>}
      {description && <Typography variant="caption">{description}</Typography>}
      <Slider
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="on"
        getAriaValueText={getAriaValueText}
        components={{
          ValueLabel: ValueLabelComponent
        }}
        sx={style}
        valueLabelFormat={valueLabelFormat}
      />
    </FormControl>
  );
}

export default SliderField;
