import { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Alert, AlertTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PhoneField from '../components/fields/PhoneField';
import { required, validateCode, validatePhone } from '../components/fields/helpers';
import { FORM_ERROR, FormApi } from 'final-form';
import { Form } from 'react-final-form';
import client, { setAuthToken } from '../data/client';
import { sentPhoneTimeouts } from '../data/auth/sentPhoneNumbers';
import CheckboxField from '../components/fields/CheckboxField';
import CodeField from '../components/fields/CodeField';
import CountDownSendAgain from '../components/fields/CountDownSendAgain';
import LoginMutation from '../data/mutations/LoginMutation';
import Block from '../components/Block';
import MarkdownText from '../components/MarkdownText';
import { track } from '../helpers/mixpanel';

const LoginForm = ({ title, description, children, terms }: { title?: string, description?: string, children?: JSX.Element, terms?: boolean }) => {
  const { t } = useTranslation();
  const [showConfirm, setShowConfirm] = useState(false);
  const [showTryAgain, setShowTryAgain] = useState(false);
  const initialValues = useRef<any>();

  const handleReset = () => {
    delete initialValues.current.code;
    setShowConfirm(false);
    setShowTryAgain(false);
    setAuthToken(null);
  };

  const onSubmit = async (values: any, form: FormApi) => {
    const { phone, code } = values;
    initialValues.current = values;
    if (showConfirm) {
      track('submit_login_code');
      delete initialValues.current.code;
      form.reset();

      try {
        const res = await client.mutate({
          mutation: LoginMutation,
          variables: {
            method: 'code',
            input: code,
          },
        });
        if (res?.data?.login?.token) {
          setAuthToken(res.data.login.token);
          client.refetchQueries({ include: 'active' });
          track('submit_login_code_success');
        } else {
          track('submit_login_code_failed');
        }
      } catch (err: any) {
        track('submit_login_code_error');
        if (err.message.includes('login_request_faulty_code')) {
          return { [FORM_ERROR]: t('login_request_faulty_code', 'Fel kod') };
        }
        if (err.message.includes('too_many_login_retries')) {
          setShowTryAgain(true);
          return { [FORM_ERROR]: t('too_many_login_attempts', 'För många försök') };
        }
        return { [FORM_ERROR]: err.message };
      }

    } else {
      track('submit_login_phone');
      // Skip submit request if there is an exisiting timeout
      if (sentPhoneTimeouts.get(phone) - Date.now() > 0) {
        track('submit_login_phone_blocked');
        return { [FORM_ERROR]: t('too_many_login_requests', 'För många inloggningsförsök') };
      }

      // disallow sending multiple request with same phone within 30 seconds.
      sentPhoneTimeouts.add(phone);

      try {
        const res = await client.mutate({
          mutation: LoginMutation,
          variables: {
            method: 'phone',
            input: phone,
          },
        });

        if (res?.data?.login?.token) {
          setAuthToken(res.data.login.token);
          setShowConfirm(true);
          track('submit_login_phone_success');
        } else {
          track('submit_login_phone_failed');
        }
      } catch (err: any) {
        if (err.message.includes('To many login request')) {
          track('submit_login_phone_blocked');
          return { [FORM_ERROR]: t('too_many_login_requests', 'För många inloggningsförsök') };
        }
        track('submit_login_phone_error');
        return { [FORM_ERROR]: err.message };
      }
    }
  }

  return (
    <Form
      key={showConfirm ? 'code' : 'phone'}
      initialValues={initialValues.current}
      onSubmit={onSubmit}
      render={({
        form,
        errors,
        submitError,
        submitting,
        hasValidationErrors,
        hasSubmitErrors,
        submitSucceeded,
        dirty,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Block>
            <Typography component="h2" variant="h2">
              {title || t('login.form_title', 'Logga in')}
            </Typography>
            <Typography variant="body2">
              {description || t('login.form_description', 'Ange ditt telefonnummer och bekräfta via SMS.')}
            </Typography>
            <Box sx={{ mt: 1 }}>
              <PhoneField
                id="phone"
                label={t('phone', 'Telefonnummer')}
                name="phone"
                autoFocus
                config={{ ...!showConfirm && { validate: validatePhone } }}
                disabled={showConfirm || submitting}
              />
              {showConfirm && (
                <CodeField
                  name="code"
                  label={t('code', 'Kod')}
                  type="number"
                  id="code"
                  placeholder={t('enter_code', 'Ange din kod')}
                  helperText={t('required_code', 'Ange din kod som du fick via SMS')}
                  autoFocus
                  config={{ validate: validateCode }}
                  disabled={submitting}
                />
              )}
            </Box>
          </Block>
          <Box sx={{ px: 2 }}>
            {terms && !showConfirm && (
              <CheckboxField
                sx={{ pt: 1, pb: 2 }} 
                name="accepted"
                label={t('accept_terms_and_conditions', `Jag godkänner att ni hanterar mina uppgifter enligt lagen för personuppgifter, samt [villkor](https://relate-matchmaking.com/terms) och [policy](https://relate-matchmaking.com/privacy).`)}
                componentsProps={{
                  typography: {
                    component: MarkdownText,
                  },
                }}
                config={{ validate: required }}
              />
            )}
            {submitError && (
              <Alert severity="error" sx={{ mt: 2, mb: 1, borderRadius: 4 }}>
                <AlertTitle>{t('error_title', 'Något gick fel...')}</AlertTitle>
                {submitError}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              disabled={submitting || hasValidationErrors || ((hasSubmitErrors || submitSucceeded) && !dirty) || showTryAgain}
              sx={{ mt: 1, mb: 2 }}
            >
              <CountDownSendAgain timer={errors?.phone} form={form} field="phone">
                {t('continue', 'Fortsätt')}
              </CountDownSendAgain>
            </Button>
            <Box display="flex" alignItems="center" flexDirection="column">
              {!showConfirm && children}
              {!showTryAgain && showConfirm && (
                <Button
                  size="large"
                  variant="text"
                  onClick={handleReset}
                >
                  {t('didnt_get_a_code', `Jag fick ingen kod`)}
                </Button>
              )}
              {showTryAgain && (
                <Button
                  size="large"
                  variant="text"
                  onClick={handleReset}
                >
                  {t('try_again', `Prova på nytt`)}
                </Button>
              )}
            </Box>
          </Box>
        </form>
      )}
    />
  );
};

export default LoginForm;
