import client from "../data/client";
import StartPictureUploadMutation from '../data/mutations/StartPictureUploadMutation';
import FinishPictureUploadMutation from '../data/mutations/FinishPictureUploadMutation';
import uploadFile from "./uploadFile";
import { getUploadProgress } from "./UploadProgress";

type UserPicture = {
  _id: string;
  uri: string;
};

const startPictureUpload = async (file: File, cb: (err: Error | null, res?: UserPicture) => void) => {
  const { data } = await client.mutate({
    mutation: StartPictureUploadMutation,
    fetchPolicy: 'no-cache',
    variables: {
      type: '',
    },
  });

  if (data?.userPicture?.startUpload) {
    const uploadId = data?.userPicture?.startUpload?._id;
    const url = data?.userPicture?.startUpload?.url;
    const progress = getUploadProgress(uploadId, 0);

    uploadFile(url, file, (value) => {
      progress(value / 100);
    }).then(async () => {
      const result = await client.mutate({
        mutation: FinishPictureUploadMutation,
        fetchPolicy: 'no-cache',
        variables: { userPictureId: uploadId },
      });
      progress(1);
      if (result?.data?.userPicture?.finishUpload) {
        // Callback with resulting upload.
        cb(null, result.data.userPicture.finishUpload);
      } else {
        cb(null);
      }
    }).catch(() => {
      progress(1);
      cb(null);
    });

    return uploadId;
  }
}

export default startPictureUpload;
