import Box from '@mui/material/Box';
import Image from '../../components/Image';
import { fadeIn } from './helpers';

const SectionDivider = ({ duration }) => {
  return (
    <Box
      sx={{
        display: 'flex', justifyContent: 'center', py: 2,
        ...duration > 0 && {
          opacity: 0,
          animation: `${fadeIn} 500ms forwards linear`,
          animationDelay: `${duration + 1}s`,
        },
      }}
    >
      <Image src="/divider.svg" sx={{ width: '50%', maxWidth: 200 }} />
    </Box>
  );
}

export default SectionDivider;
