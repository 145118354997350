import { useCallback, useState, useMemo, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupedVirtuoso, VirtuosoHandle } from 'react-virtuoso'
import { Helmet } from 'react-helmet-async';
import { Form, FormSpy } from 'react-final-form';
import { Box, CircularProgress } from '@mui/material';
import { flatten } from 'flat';
import Body from '../components/Body';
import OptionsField from '../components/fields/OptionsField';
import TextField from '../components/fields/TextField';
import ProgressIndicator from '../components/ProgressIndicator';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useLocalStorage from '../helpers/useLocalStorage';
import client from '../data/client';
import UpdateUserProfileMutation from '../data/mutations/UpdateUserProfileMutation';
import GroupHeading from '../components/GroupHeading';
import GroupItem from '../components/GroupItem';
import { useQuery } from '@apollo/client';
import UserProfileFieldsQuery from '../data/queries/UserProfileFieldsQuery';
import UserProfileQuery from '../data/queries/UserProfileQuery';
import StepIndicator from '../components/StepIndicator';
import FormHeader from '../components/FormHeader';
import SubmitButton from '../components/SubmitButton';
import { track } from '../helpers/mixpanel';

export type GroupItemOptionType = {
  label: string,
  value: string,
}
export type GroupItemType = {
  name?: string,
  component: (_:any) => JSX.Element,
  multiple?: boolean,
  label?: string,
  description?: string,
  placeholder?: string,
  options?: GroupItemOptionType[]
}
export type GroupType = {
  title: string,
  items: GroupItemType[],
}

const spinner = { height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' };

export default function Profile() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const matchId = searchParams.get('match');
  const title = t('profile.title', 'Låt oss lära känna dig');
  const virtuoso = useRef<VirtuosoHandle>(null);
  const [atBottom, setAtBottom] = useState(false);
  const [showWantKidsWhen, setShowWantKidsWhen] = useState(false);
  const { data: profileFieldsData, loading: profileFieldsLoading } = useQuery(UserProfileFieldsQuery, { fetchPolicy: 'cache-and-network' });
  const { data: profileData, loading: profileLoading } = useQuery(UserProfileQuery, { fetchPolicy: 'cache-and-network' });
  const { __typename, ...defaultValues } = profileData?.viewer?.user || {};
  const loading = profileFieldsLoading || profileLoading;
  const [initialValues, setInitialValues] = useLocalStorage('profile', defaultValues);
  const options: { [key: string]: string[] } = profileFieldsData?.userProfileFields || {};

  const onSubmit = useCallback(async (fields: { [key: string]: any }) => {
    track('submit_profile');
    try {
      const response = await client.mutate({
        mutation: UpdateUserProfileMutation,
        variables: {
          fields,
        },
      });

      if (response?.data?.updateUserProfile?.user) {
        track('submit_profile_success');
        if (matchId) {
          navigate('/preferences?match=' + matchId);
        } else {
          navigate('/preferences');
        }
      } else {
        track('submit_profile_failed');
      }
    } catch(err) {
      track('submit_profile_error');
    }
  }, [matchId]);

  const validate = useCallback((values: { [key: string]: string }) => {
    const errors: { [key: string]: string } = {};
    Object.keys(values).forEach(field => {
      const value = values[field];
      switch (field) {
        case 'life_values':
        case 'relationship_values':
        case 'society_values': {
          const count = (value || []).length
          if (![0, 6].includes(count)) {
            const text = t('values.action', {
              postProcess: 'interval', 
              count,
              defaultValue: '(0)[Välj 6];(1)[Välj 5 till];(2)[Välj 4 till];(3)[Välj 3 till];(4)[Välj 2 till];(5)[Välj 1 till];(6)[#];(7)[Välj 1 mindre];(7-inf)[Välj max 6];'
            });
            if (text !== '#') {
              errors[field] = text;
            }
          }
          break;
        }
        case 'love_language': {
          const count = (value || []).length
          if (![0, 6].includes(count)) {
            const text = t('love_language.action', {
              postProcess: 'interval', 
              count,
              defaultValue: '(0)[Välj 2];(1-2)[#];(3)[Välj 1 mindre];(4-inf)[Välj max 2];'
            });
            if (text !== '#') {
              errors[field] = text;
            }
          }
          break;
        }
        default:
          break;
      }
    });
    return errors;
  }, []);

  const fieldGroups = useMemo<GroupType[]>(() => [{
    title: '**1/2:** ' + title,
    items: [{
      component: FormHeader,
      description: t('profile.description', 'I följande frågor får vi en tydlig bild av dig för att hitta din matchning.\n\nSlappna av och svara ärligt – våra matchmakers ser till din helhetsbild. Din data är säker hos oss, endast våra matchmakers har tillgång till den.'),
      videoTitle: t('profile.video_title', 'Elin berättar varför vi vill veta mer om dig'),
      videoSrc: {
        mp4: t('profile.video_mp4_src', '/profile_sv.mp4'),
        webm: t('profile.video_webm_src', '/profile_sv.webm'),
        caption: t('profile.video_caption_src', '/profile_sv.vtt'),
      },
    }],
  }, {
    title: t('personality.title', 'Personlighet'),
    items: [
      {
        component: OptionsField, 
        multiple: true,
        other: true,
        name: 'energy',
        label: t(`energy.label`, 'Vilka av följande beskriver din energi?'),
        description: t(`energy.description`, 'Du kan välja flera alternativ.'),
        options: (options.energy || []).map(key => ({
          value: key,
          label: t(`energy.${key}`),
        })),
      },
      {
        component: OptionsField, 
        multiple: true,
        other: true,
        name: 'personality',
        label: t(`personality.label`, 'Vilka av följande beskriver din personlighet?'),
        description: t(`personality.description`, 'Du kan välja flera alternativ.'),
        options: (options.personality || []).map(key => ({
          value: key,
          label: t(`personality.${key}`),
        })),
      },
      {
        component: OptionsField, 
        name: 'personality_type',
        label: t(`personality_type.label`, 'Vilken är din personlighetstyp (Myers Briggs)?'),
        description: t(`personality_type.description`, 'Välj ett alternativ (valfritt).'),
        options: (options.personality_type || []).map(key => ({
          value: key,
          label: t(`personality_type.${key}`),
        })),
      },
    ]
  },
  {
    title: t('lifestyle_and_interest.title', 'Livsstil och intressen'),
    items: [
      {
        component: OptionsField, 
        multiple: true,
        other: true,
        name: 'life_stage',
        label: t(`life_stage.label`, 'Hur skulle du beskriva det stadie du är i ditt liv just nu?'),
        description: t(`life_stage.description`, 'Du kan välja flera alternativ.'),
        options: (options.life_stage || []).map(key => ({
          value: key,
          label: t(`life_stage.${key}`),
        })),
      },
      {
        component: OptionsField, 
        multiple: true,
        other: true,
        name: 'interest',
        label: t(`interest.label`, 'Vilka är dina intressen/passioner?'),
        description: t(`interest.description`, 'Du kan välja flera alternativ.'),
        options: (options.interest || []).map(key => ({
          value: key,
          label: t(`interest.${key}`),
        })),
      },
      {
        component: OptionsField, 
        multiple: true,
        other: true,
        name: 'typical_sunday',
        label: t(`typical_sunday.label`, 'Hur ser en typisk söndag ut för dig?'),
        description: t(`typical_sunday.description`, 'Du kan välja flera alternativ.'),
        options: (options.typical_sunday || []).map(key => ({
          value: key,
          label: t(`typical_sunday.${key}`),
        })),
      },
      {
        component: OptionsField, 
        multiple: true,
        other: true,
        name: 'vacation_home',
        label: t(`vacation_home.label`, 'Du åker på semester, hur bor du?'),
        description: t(`vacation_home.description`, 'Du kan välja flera alternativ.'),
        options: (options.vacation_home || []).map(key => ({
          value: key,
          label: t(`vacation_home.${key}`),
        })),
      },
      {
        component: OptionsField, 
        multiple: true,
        other: true,
        name: 'home_decoration',
        label: t(`home_decoration.label`, 'Hur inreder du ditt hem?'),
        description: t(`home_decoration.description`, 'Du kan välja flera alternativ.'),
        options: (options.home_decoration || []).map(key => ({
          value: key,
          label: t(`home_decoration.${key}`),
        })),
      },
      {
        component: OptionsField, 
        multiple: true,
        name: 'pets',
        label: t(`pets.label`, 'Har du några husdjur?'),
        options: (options.pets || []).map(key => ({
          value: key,
          label: t(`pets.${key}`),
        })),
      },
      {
        component: OptionsField, 
        name: 'alcohol',
        label: t(`alcohol.label`, 'Dricker du alkohol?'),
        options: (options.alcohol || []).map(key => ({
          value: key,
          label: t(`alcohol.${key}`),
        })),
      },
      {
        component: OptionsField, 
        name: 'tobacco',
        label: t(`tobacco.label`, 'Röker/snusar du?'),
        options: (options.tobacco || []).map(key => ({
          value: key,
          label: t(`tobacco.${key}`),
        })),
      },
      {
        component: OptionsField, 
        name: 'drugs',
        label: t(`drugs.label`, 'Inställning till droger?'),
        options: (options.drugs || []).map(key => ({
          value: key,
          label: t(`drugs.${key}`),
        })),
      },
    ]
  },
  {
    title: t('values.title', 'Värderingar'),
    items: [
      {
        component: OptionsField, 
        multiple: true,
        name: 'life_values',
        label: t(`life_values.label`, 'I mitt liv, så prioriterar jag...'),
        description: t(`life_values.description`, 'Välj 6 värderingar som känns mest viktiga för dig i hur du vill leva ditt liv.'),
        options: (options.life_values || []).map(key => ({
          value: key,
          label: t(`life_values.${key}`),
        })),
      },
      {
        component: OptionsField, 
        multiple: true,
        name: 'relationship_values',
        label: t(`relationship_values.label`, 'I relationen, så värderar jag...'),
        description: t(`relationship_values.description`, 'Välj 6 värderingar som du uppskattar mest i en kärleksrelation.'),
        options: (options.relationship_values || []).map(key => ({
          value: key,
          label: t(`relationship_values.${key}`),
        })),
      },
      {
        component: OptionsField, 
        multiple: true,
        name: 'society_values',
        label: t(`society_values.label`, 'Viktigast i samhället är...'),
        description: t(`society_values.description`, 'Välj 6 värderingar som du tycker vi behöver mer av i samhället.'),
        options: (options.society_values || []).map(key => ({
          value: key,
          label: t(`society_values.${key}`),
        })),
      },
      {
        component: OptionsField, 
        multiple: true,
        other: true,
        name: 'political',
        label: t(`political.label`, 'Vad beskriver bäst dina politiska värderingar idag?'),
        description: t(`political.description`, 'Du kan välja flera alternativ.'),
        options: (options.political || []).map(key => ({
          value: key,
          label: t(`political.${key}`),
        })),
      },
      {
        component: OptionsField, 
        multiple: true,
        other: true,
        name: 'religious_upbringing',
        label: t(`religious_upbringing.label`, 'Vilka religösa värderingar växte du upp med?'),
        description: t(`religious_upbringing.description`, 'Du kan välja flera alternativ.'),
        options: (options.religious_upbringing || []).map(key => ({
          value: key,
          label: t(`religious_upbringing.${key}`),
        })),
      },
      {
        component: OptionsField, 
        multiple: true,
        other: true,
        name: 'religion',
        label: t(`religion.label`, 'Hur skulle du beskriva dina religösa värderingar idag?'),
        description: t(`religion.description`, 'Du kan välja flera alternativ.'),
        options: (options.religion || []).map(key => ({
          value: key,
          label: t(`religion.${key}`),
        })),
      },
      {
        component: OptionsField,
        name: 'religious_belief',
        label: t(`religious_belief.label`, 'Hur troende är du?'),
        options: (options.religious_belief || []).map(key => ({
          value: key,
          label: t(`religious_belief.${key}`),
        })),
      },
      {
        component: OptionsField, 
        multiple: true,
        other: true,
        name: 'childhood',
        label: t(`childhood.label`, 'Vilka beskriver din uppväxt?'),
        description: t(`childhood.description`, 'Du kan välja flera alternativ.'),
        options: (options.childhood || []).map(key => ({
          value: key,
          label: t(`childhood.${key}`),
        })),
      },
    ]
  },
  {
    title: t('relationship_background.title', 'Relationsbakgrund'),
    items: [
      {
        component: OptionsField, 
        name: 'relationship_status',
        label: t(`relationship_status.label`, 'Vilken är din relationsstatus?'),
        options: (options.relationship_status || []).map(key => ({
          value: key,
          label: t(`relationship_status.${key}`),
        })),
      },
      {
        component: OptionsField, 
        name: 'have_kids',
        label: t(`have_kids.label`, 'Har du barn?'),
        options: (options.have_kids || []).map(key => ({
          value: key,
          label: t(`have_kids.${key}`),
        })),
      },
      {
        component: OptionsField, 
        name: 'want_kids',
        label: t(`want_kids.label`, 'Vill du ha barn/fler barn?'),
        options: (options.want_kids || []).map(key => ({
          value: key,
          label: t(`want_kids.${key}`),
        })),
        onChange: (value: string | undefined) => {
          const nextShowWantKidsWhen = value === 'yes';
          if (nextShowWantKidsWhen !== showWantKidsWhen) {
            setShowWantKidsWhen(nextShowWantKidsWhen);
          }
        },
      },
      ...showWantKidsWhen ? [{
        component: OptionsField,
        name: 'want_kids_when',
        label: t(`want_kids_when.label`, 'När kan du tänka dig skaffa barn?'),
        options: (options.want_kids_when || []).map(key => ({
          value: key,
          label: t(`want_kids_when.${key}`),
        })),
      }] : [],
      {
        component: OptionsField, 
        name: 'total_relationships',
        label: t(`total_relationships.label`, 'Hur många relationer har du haft?'),
        options: (options.total_relationships || []).map(key => ({
          value: key,
          label: key,
        })),
      },
      {
        component: OptionsField, 
        name: 'since_last_relationship',
        label: t(`since_last_relationship.label`, 'Hur lång tid sedan tog din senaste relation slut?'),
        options: (options.since_last_relationship || []).map(key => ({
          value: key,
          label: t(`since_last_relationship.${key}`),
        })),
      },
      {
        component: OptionsField, 
        name: 'relationship_length',
        label: t(`relationship_length.label`, 'Hur lång var din längsta relation?'),
        options: (options.relationship_length || []).map(key => ({
          value: key,
          label: t(`relationship_length.${key}`),
        })),
      },
    ]
  },
  {
    title: t('love_language.title', 'Kärleksspråk'),
    items: [
      {
        component: OptionsField, 
        multiple: true,
        name: 'love_language',
        label: t(`love_language.label`, 'Vilka är dina kärleksspråk?'),
        description: t(`love_language.description`, 'Jag känner mig genuint älskad när andra ger mig detta, välj 1-2.'),
        options: (options.love_language || []).map(key => ({
          value: key,
          label: t(`love_language.${key}`),
        })),
      },
      {
        component: OptionsField, 
        multiple: true,
        other: true,
        name: 'partner_type',
        label: t(`partner_type.label`, 'Vem är du som partner?'),
        description: t(`partner_type.description`, 'Du kan välja flera alternativ.'),
        options: (options.partner_type || []).map(key => ({
          value: key,
          label: t(`partner_type.${key}`),
        })),
      },
    ]
  },
  {
    title: t('occupation_and_education.title', 'Yrke och utbildning'),
    items: [
      {
        component: TextField,
        name: 'occupation_title',
        label: t(`occupation_title.label`, 'Jobbtitel'),
        placeholder: t(`occupation_title.placeholder`, 'Ditt svar...'),
        sx: { mt: 0 },
        autoComplete: 'off',
        autoCorrect: 'off',
        spellCheck: 'false',
      },
      {
        component: OptionsField, 
        multiple: true,
        other: true,
        name: 'occupation_role',
        label: t(`occupation_role.label`, 'Vilka beskriver bäst din roll?'),
        options: (options.occupation_role || []).map(key => ({
          value: key,
          label: t(`occupation_role.${key}`),
        })),
      },
      {
        component: OptionsField, 
        name: 'income',
        label: t(`income.label`, 'Din inkomst (SEK/mån)'),
        options: (options.income || []).map(key => ({
          value: key,
          label: t(`income.${key}`),
        })),
      },
      {
        component: OptionsField, 
        name: 'education_level',
        label: t(`education_level.label`, 'Högsta utbildningsnivå'),
        options: (options.education_level || []).map(key => ({
          value: key,
          label: t(`education_level.${key}`),
        })),
      },
      // {
      //   component: TextField,
      //   name: 'school',
      //   label: t(`school.label`, 'Universitet / Skola'),
      //   placeholder: t(`school.placeholder`, 'Ditt universitet...'),
      //   sx: { mt: 0 },
      //   autoComplete: 'off',
      //   autoCorrect: 'off',
      //   spellCheck: 'false',
      // },
      {
        component: OptionsField, 
        multiple: true,
        other: true,
        name: 'education_topics',
        label: t(`education_topics.label`, 'Ämnen du studerat'),
        description: t(`education_topics.description`, 'Du kan välja flera alternativ.'),
        options: (options.education_topics || []).map(key => ({
          value: key,
          label: t(`education_topics.${key}`),
        })),
      },
    ]
  },
  {
    title: t('extra.title', 'Tillägg'),
    items: [
      {
        component: TextField,
        multiline: true,
        name: 'extra',
        label: t(`extra.label`, 'Finns det något som inte kom fram om dig i din profil som du skulle vilja att vi känner till?'),
        placeholder: t(`extra.placeholder`, 'Ditt svar...'),
        sx: { mt: 0 },
      }
    ]
  }], [t, options, loading, showWantKidsWhen]);

  const groupCounts = useMemo(() => { 
    return fieldGroups.map(fieldGroup => fieldGroup.items.length);
  }, [fieldGroups]);

  const fields = useMemo(() => {
    return fieldGroups.flatMap(fieldGroup => fieldGroup.items);
  }, [fieldGroups]);

  const renderGroupContent = useCallback((index: number) => (
    <GroupHeading title={fieldGroups[index].title} />
  ), [fieldGroups]);

  const renderItemContent = useCallback((index: number) => { 
    const { component: Component, ...props } = fields?.[index];
    return (
      <GroupItem name={props.name}>
        <Component key={props.name} {...props} />
      </GroupItem>
    );
  }, [fields]);

  const atBottomStateChange = useCallback((state: boolean) => {
    setAtBottom(state);
  }, [setAtBottom]);

  return (
    <Body>
      <Helmet>
        <title>Relate | {title}</title>
      </Helmet>
      {loading ? (
        <Box sx={spinner}>
          <CircularProgress />
        </Box>
      ) : (
        <Form
          onSubmit={onSubmit}
          subscription={{}}
          validate={validate}
        >
          {({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <StepIndicator>
                <ProgressIndicator fields={fields} />
                <ProgressIndicator fixed={0} />
              </StepIndicator>
              <GroupedVirtuoso
                ref={virtuoso}
                className="virtuoso"
                groupCounts={groupCounts}
                groupContent={renderGroupContent}
                itemContent={renderItemContent}
                useWindowScroll
                atBottomStateChange={atBottomStateChange}
                increaseViewportBy={{
                  top: 10000,
                  bottom: 10000,
                }}
              />
              <FormSpy
                fields={fields}
                virtuoso={virtuoso}
                atBottom={atBottom}
                handleSubmit={handleSubmit}
                component={SubmitButton}
              />
              <FormSpy
                subscription={{ initialValues: true }}
                onChange={() => {
                  // Set initial values so that dirty is true.
                  const values = flatten(initialValues || {}, { safe: true }) as { [key: string]: any };
                  form.batch(() => {
                    Object.keys(values).forEach(name => {
                      form.change(name, values[name]);
                    });
                  });
                }}
              />
              <FormSpy
                subscription={{ values: true }}
                onChange={({ values }) => {
                  // Prevent pets option for being both yes and no at the same time.
                  if (values?.pets) {
                    const options = values.pets;
                    const hasYes = options.includes('yes');
                    const hasNo = options.includes('no');
                    
                    // If both 'yes' and 'no' are selected, we need to remove one of them
                    if (hasYes && hasNo) {
                      // Decide which option to keep. For example, let's keep the first selected
                      const newValue = options[options.length - 1] === 'yes' ? ['yes'] : ['no'];

                      // If 'in_the_future' was also selected, keep it in the array
                      if (options.includes('in_the_future')) {
                        newValue.push('in_the_future');
                      }
                      // Update the form value with the corrected options
                      form.change('pets', newValue);
                      values.pets = newValue;
                    }
                  }
                  // Save to localStorage.
                  setInitialValues(values);
                }}
              />
            </form>
          )}
        </Form>
      )}
    </Body>
  );
}
