import React from "react";

export interface AuthContextType {
  user: any;
  signin: (user: any, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
}

export const AuthContext = React.createContext<AuthContextType>(null!);

/**
 * This represents some generic auth provider API, like Firebase.
 */
export const authProvider = {
  user: null,
  signin(user: any, callback: VoidFunction) {
    authProvider.user = user;
    setTimeout(callback, 100); // fake async
  },
  signout(callback: VoidFunction) {
    authProvider.user = null;
    setTimeout(callback, 100);
  },
};

export function useAuth() {
  return React.useContext(AuthContext);
}

