import { PhoneNumberUtil, PhoneNumberType } from 'google-libphonenumber';
import i18n from '../../data/i18n';
import { sentPhoneTimeouts } from '../../data/auth/sentPhoneNumbers';

const phoneUtil = PhoneNumberUtil.getInstance();

export const required = (value: any) => (value ? undefined : i18n.t('required', 'Krävs för att fortsätta'));

export const validatePhone = (value: string) => {
  if (!value) return i18n.t('required_phone', 'Ange ditt telefonnummer för att fortsätta');
  const phone = value?.trim();
  try {
    const number = phoneUtil.parseAndKeepRawInput(phone);
    if (!phoneUtil.isValidNumber(number)) {
      return i18n.t('invalid_number', 'Numret är ogiltigt');
    }
    
    const allowedTypes = [
      PhoneNumberType.MOBILE,
      PhoneNumberType.FIXED_LINE_OR_MOBILE,
    ];

    const type = phoneUtil.getNumberType(number);
    if (!allowedTypes.includes(type)) {
      return i18n.t('disallowed_type', 'Mobilnummer krävs');
    }
  } catch (error) {
    return i18n.t('invalid_number', 'Numret är ogiltigt');
  }

  const timer: number = sentPhoneTimeouts.get(phone);
  const timers: number[] = sentPhoneTimeouts.values();
  if (timer - Date.now() > 0) {
    // rate limit on a single number.
    return timer;
  } else if (timers.length > 2) {
    // rate limit on total numbers.
    return Math.max(...timers);
  }
  return undefined;
};

export const validateCode = (value: string) => {
  if (!value) {
    return i18n.t('required_code', 'Ange din kod som du fick via SMS');
  }
  if (!/^[0-9]{6}$/.test(value)) {
    return i18n.t('invalid_code', 'Ogiltig kod');
  }
  return undefined;
}
