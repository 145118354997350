import { Box, BoxProps } from '@mui/material';
import { useState } from 'react';

const Image = ({ src, ...props }: BoxProps & { src?: string }) => {
  const [error, setError] = useState(!src);

  const handleError = () => {
    (props as any)?.onError?.();
    setError(true);
  };

  if (error) {
    return <Box component="span" {...props} />
  }

  return <Box component="img" src={src} {...props} onError={handleError} />
}

export default Image;
