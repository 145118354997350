import { gql } from "@apollo/client";
import UserViewerFragment from "../fragments/UserViewerFragment";

const HomeViewerQuery = gql`
  query HomeViewerQuery {
    viewer {
      token
      user {
        ...UserViewerFragment
        potentialClient
        matchmakerSurveyAt
        matchmakerBookedAt
        matchmakerDeclinedAt
        isClient
        clients
      }
    }
  }
  ${UserViewerFragment}
`;

export default HomeViewerQuery;
